export var metricEnum;
(function (metricEnum) {
    metricEnum["si"] = "SI_UNIT";
    metricEnum["imperial"] = "IMPERIAL";
})(metricEnum || (metricEnum = {}));
export const localStorageKey = 'currentSystem';
export const metrics = [
    { label: 'SI units', value: metricEnum.si, labelDesc: 'mm, kg, N/m2' },
    { label: 'Imperial units', value: metricEnum.imperial, labelDesc: 'ft, lb, PSF ' }
];
