import { useState } from 'react';
import apis from '@shared-app/lib/apis';
import { ToastService } from '@shared/lib/services/toast.service';
export function useImage() {
    const [image, setImage] = useState('');
    const [imageLoading, setImageLoading] = useState(false);
    const getImage = (imageUrl) => {
        setImageLoading(true);
        apis.dataset
            .getImage(imageUrl)
            .then(res => {
            const imageURL = URL.createObjectURL(res);
            setImage(imageURL);
            setImageLoading(false);
        })
            .catch(e => ToastService.error(e));
    };
    return { image, imageLoading, getImage };
}
