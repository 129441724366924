import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Paper, Table, TableContainer } from '@mui/material';
import palette from '@theme/palette';
import { BOQTableFooter } from './BOQTableFooter';
import { BOQTableHeader } from './BOQTableHeader';
import { BOQTableBody } from './BOQTableBody';
export function BOQTable({ tableData, isDisabled }) {
    return (_jsxs(_Fragment, { children: [_jsx(TableContainer, { component: Paper, sx: { maxHeight: 'calc((100vh - 500px))', boxShadow: 'none', background: 'transparent' }, children: _jsxs(Table, { stickyHeader: true, sx: {
                        width: '98%',
                        justifyContent: 'flex-end',
                        marginLeft: '2%',
                        background: palette.common.white
                    }, children: [_jsx(BOQTableHeader, {}), _jsx(BOQTableBody, { tableData: tableData, isDisabled: isDisabled })] }) }), _jsx(BOQTableFooter, { tableData: tableData, isDisabled: isDisabled })] }));
}
