import { useMemo, useEffect } from 'react';
import { useAppSelector } from '@app/hooks';
import { calculateBill } from '../utils/calculateBill';
import { useEstimationResultStore } from '../state/useEstimationResultStore';
import { transformBillData, getCSV } from '@shared-app/utils/';
import { saveAs } from 'file-saver';
import { trimEstimationSolutions } from '../utils/trimEstimationSolutions';
import { INITIAL_SOLUTIONS_LENGTH } from '../contstants';
import { useMetricSystem, useIsGerman } from '@shared-app/hooks';
export function useEstimationResult() {
    const estimationData = useAppSelector(state => state.content.estimationData);
    const { selectedSolutionIdx, searchQuery, setSearchQuery, setSelectedSolutionIdx, setLoadMoreEnabled, isLoadMoreEnabled, isNoSolutionModalVisible, toggleNoSolutionModalVisibility } = useEstimationResultStore();
    const isGerman = useIsGerman();
    const billData = useMemo(() => calculateBill(selectedSolutionIdx.toString(), estimationData, isGerman), [selectedSolutionIdx, estimationData, isGerman]);
    const solutions = useMemo(() => trimEstimationSolutions(estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions, isLoadMoreEnabled), [estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions, isLoadMoreEnabled]);
    const { isImperial } = useMetricSystem();
    const handleDownloadCSV = () => {
        var _a;
        const transformedBillData = billData.map(item => transformBillData(item, isImperial));
        const csvContent = getCSV(transformedBillData);
        const selectedFileBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        let selectedfileName = (_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions[selectedSolutionIdx]) === null || _a === void 0 ? void 0 : _a.drawing_file_name.replace('.dwg', '.csv');
        saveAs(selectedFileBlob, selectedfileName);
    };
    const handleSelectSolution = (solutionId) => {
        setSelectedSolutionIdx(Number(solutionId));
    };
    const updateSearchQuery = (value) => {
        setSearchQuery(value);
    };
    useEffect(() => {
        setSearchQuery('');
        setLoadMoreEnabled(((estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions) || []).length > INITIAL_SOLUTIONS_LENGTH);
        setSelectedSolutionIdx(0);
    }, [estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions]);
    return {
        billData,
        estimationData,
        solutions,
        handleSelectSolution,
        selectedSolutionIdx,
        searchQuery,
        updateSearchQuery,
        setSelectedSolutionIdx,
        handleDownloadCSV,
        isLoadMoreEnabled,
        setLoadMoreEnabled,
        isNoSolutionModalVisible,
        toggleNoSolutionModalVisibility
    };
}
