import palette from "./palette";
const components = {
    Input: {
        controlHeight: 46,
        colorPrimary: palette.neutral[10],
        fontSize: 16,
        lineHeight: 1.375 /* 137.5% */,
    },
    InputNumber: {
        controlHeight: 46,
        colorPrimary: palette.neutral[10],
        fontSize: 16,
        lineHeight: 1.375 /* 137.5% */,
        handleVisible: true,
        controlWidth: 139,
    },
    Collapse: {
        colorTextHeading: palette.neutral[50],
    },
    Slider: {
        fontSize: 10,
        railSize: 6,
        handleLineWidth: 3,
        handleLineWidthHover: 3,
        handleSize: 14,
        handleSizeHover: 14,
        handleColor: palette.neutral[10],
        dotSize: 3,
        trackHoverBg: palette.neutral[10],
        trackBg: palette.neutral[10],
        dotBorderColor: palette.neutral[10],
        dotActiveBorderColor: palette.neutral[10],
    },
    Select: {
        borderRadius: 6,
        controlHeight: 46,
        colorPrimary: palette.neutral[10],
        fontFamily: "Inter",
        fontSize: 16,
        lineHeight: 1.375 /* 137.5% */,
    },
    Table: {
        headerBg: "#4c4c4c",
        headerBorderRadius: 0,
        borderColor: "#e0e0e0",
    },
    Tree: {
        fontSize: 18,
    },
};
export default components;
