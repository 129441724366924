import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Box } from '@mui/material';
import { Replay } from '@shared-app/ui/icons';
import palette from '@theme/palette';
import { Button, Text, FilterSearch } from '@shared-app/ui/Common';
import styles from './Header.module.scss';
import { useTranslation } from 'react-i18next';
export function Header({ handleResetAll, query, setQuery }) {
    const { t } = useTranslation();
    return (_jsx(Box, { display: 'flex', width: '98%', ml: '2%', sx: { borderTopLeftRadius: 4, borderTopRightRadius: 4 }, bgcolor: palette.common.white, justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'column', padding: '16px', gap: 2, mb: 0, children: _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', children: [_jsxs(Box, { display: 'flex', gap: '16px', alignItems: 'center', children: [_jsx(Text, { label: t('Bill Of Quantities'), variant: "subtitle1" }), _jsx(Box, { width: 330, display: 'inline-flex', children: _jsx(FilterSearch, { searchQuery: query, handleChange: val => {
                                    setQuery(val);
                                }, placeholder: t('Search by Article Number or Article Description'), className: styles.searchInput, inputHeight: "32px" }) })] }), _jsx(Button, { onClick: handleResetAll, startIcon: _jsx(Replay, {}), variant: "text", children: t('Reset') })] }) }));
}
