import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import palette from '@theme/palette';
import { BOQDownload } from './BOQDownload';
import { Header } from './Header';
import { BOQTable } from './BOQTable/BOQTable';
import { useFilteredData } from '../../../../lib/hooks/useFilteredData';
import { useSolutionStore } from '@entities/solution';
export function BOQTab({ isDisabled, handleResetAll }) {
    const { bom } = useSolutionStore();
    const { filteredTableData, query, setQuery } = useFilteredData(bom);
    return (_jsxs(Box, { flex: 1, bgcolor: palette.grey[100], justifyContent: 'space-between', display: 'flex', flexDirection: 'column', alignItems: 'center', children: [_jsxs(Box, { width: '100%', p: 3, pl: 1, borderRadius: 4, sx: { borderRadius: 4 }, children: [_jsx(Header, { handleResetAll: handleResetAll, query: query, setQuery: setQuery }), _jsx(BOQTable, { tableData: filteredTableData, isDisabled: isDisabled })] }), _jsx(BOQDownload, {})] }));
}
