import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { BoqTableSearch } from '@estimation/features/BoqTableSearch';
import { DownloadBoq } from '@estimation/features/DownloadBoq';
import { BoqTable } from '@estimation/features/BoqTable';
export function BOQ() {
    const { t } = useTranslation();
    return (_jsxs(Box, { display: 'grid', gap: '8px', children: [_jsx(Typography, { fontSize: '22px', fontWeight: 600, children: t('Bill of Quantities (BoQ)') }), _jsxs(Box, { display: 'flex', justifyContent: 'space-between', children: [_jsx(BoqTableSearch, {}), _jsx(DownloadBoq, {})] }), _jsx(BoqTable, {})] }));
}
