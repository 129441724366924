import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEstimationResult } from '@estimation/entities/estimationSolution';
import { MoveToInboxOutlined } from '@shared-app/ui/icons';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { Button } from '@shared-app/ui';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export function DownloadBoq() {
    const [isCVSDownloading, setIsCVSDownloading] = useState(false);
    const { handleDownloadCSV } = useEstimationResult();
    const { t } = useTranslation();
    const handleDownload = () => {
        setIsCVSDownloading(true);
        handleDownloadCSV();
        setIsCVSDownloading(false);
    };
    return (_jsxs(Button, { startIcon: !isCVSDownloading ? _jsx(MoveToInboxOutlined, {}) : _jsx(CircularProgress, { color: "inherit", size: 20 }), disabled: isCVSDownloading, variant: "outlined", onClick: handleDownload, className: styles.downloadButton, children: [' ', t('Download BoQ CSV')] }));
}
