var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Autocomplete, TextField, Box } from '@mui/material';
import apis from '@shared-app/lib/apis';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@shared-app/ui/icons';
import { useIsGerman } from '@shared-app/hooks';
const loadArticles = (inputValue) => __awaiter(void 0, void 0, void 0, function* () {
    const length = inputValue.length;
    if (length >= 3 && length <= 6) {
        try {
            const response = yield apis.projects.getArticleNumbers(inputValue);
            return response.map((item) => ({
                value: item,
                label: item
            }));
        }
        catch (error) {
            console.error('Error fetching articles:', error);
            return [];
        }
    }
    else {
        return [];
    }
});
const loadArticlesDescription = (inputValue) => __awaiter(void 0, void 0, void 0, function* () {
    const length = inputValue.length;
    if (length >= 3) {
        try {
            const response = yield apis.projects.getArticleDescription(inputValue);
            return response.map((item) => ({
                value: item,
                label: item
            }));
        }
        catch (error) {
            console.error('Error fetching articles:', error);
            return [];
        }
    }
    else {
        return [];
    }
});
export const ArticleDataSelection = ({ onSelectionChange, isError, length, height }) => {
    const [articleNumberValue, setArticleNumberValue] = useState(null);
    const [articleDescriptionValue, setArticleDescriptionValue] = useState(null);
    const [articleNumberOptions, setArticleNumberOptions] = useState([]);
    const [articleDescriptionOptions, setArticleDescriptionOptions] = useState([]);
    const isGerman = useIsGerman();
    const { t } = useTranslation();
    const handleNumberChange = (_event, selectedOption) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedOption) {
            try {
                const articleDescription = yield apis.projects.getArticleDetails(selectedOption.value, length, height);
                setArticleNumberValue(selectedOption);
                if (isGerman) {
                    setArticleDescriptionValue({
                        label: articleDescription.DescriptionGer,
                        value: articleDescription.DescriptionGer
                    });
                }
                else {
                    setArticleDescriptionValue({
                        label: articleDescription.DescriptionEng,
                        value: articleDescription.DescriptionEng
                    });
                }
                onSelectionChange(Object.assign({}, articleDescription));
            }
            catch (e) {
                console.log('e', e);
            }
        }
    });
    const handleDescriptionChange = (_event, selectedOption) => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedOption) {
            try {
                const articleDescription = yield apis.projects.getArticleDescriptionDetails(selectedOption.value, length, height);
                setArticleDescriptionValue(selectedOption);
                setArticleNumberValue({
                    label: articleDescription.ArticleNumber,
                    value: articleDescription.ArticleNumber
                });
                onSelectionChange(Object.assign({}, articleDescription));
            }
            catch (e) {
                console.log('e', e);
            }
        }
    });
    return (_jsxs(Box, { flex: 1, gap: 4, display: 'flex', flexDirection: 'row', children: [_jsxs(Box, { flex: 1, style: { position: 'relative' }, children: [_jsx(Autocomplete, { value: articleNumberValue, onChange: handleNumberChange, options: articleNumberOptions, getOptionLabel: option => option.label, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t('Article Number'), variant: "standard", error: isError }))), onInputChange: (event, newInputValue) => __awaiter(void 0, void 0, void 0, function* () {
                            const options = yield loadArticles(newInputValue);
                            setArticleNumberOptions(options);
                        }), popupIcon: _jsx(ArrowDropDown, { className: styles.icon }) }), isError && _jsx("p", { className: styles.errorLabel, children: t('Value already exists') })] }), _jsxs(Box, { flex: 1, style: { position: 'relative' }, children: [_jsx(Autocomplete, { value: articleDescriptionValue, onChange: handleDescriptionChange, options: articleDescriptionOptions, getOptionLabel: option => option.label, renderInput: params => (_jsx(TextField, Object.assign({}, params, { label: t('Article Description'), variant: "standard", error: isError }))), onInputChange: (_, newInputValue) => __awaiter(void 0, void 0, void 0, function* () {
                            const options = yield loadArticlesDescription(newInputValue);
                            setArticleDescriptionOptions(options);
                        }), popupIcon: _jsx(ArrowDropDown, { className: styles.icon }) }), isError && _jsx("p", { className: styles.errorLabel, children: t('Value already exists') })] })] }));
};
