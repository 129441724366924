var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Button as MuiButton } from '@mui/material';
import palette from '@theme/palette';
const baseStyle = {
    height: '42px',
    padding: '8px 22px 8px 22px',
    fontSize: '15px',
    lineHeight: '26px'
};
const containedStyle = {
    background: palette.primary.main,
    color: palette.common.white,
    borderColor: 'transparent'
};
const outlinedStyle = {
    background: palette.common.white,
    color: palette.primary.main,
    borderColor: palette.primary.main,
    border: '1px Solid'
};
const textStyle = {
    background: 'transparent',
    color: palette.primary.main,
    borderColor: 'transparent'
};
export const Button = (_a) => {
    var { variant = 'contained', children, sx, color } = _a, rest = __rest(_a, ["variant", "children", "sx", "color"]);
    let buttonStyle = Object.assign(Object.assign({}, baseStyle), containedStyle);
    switch (variant) {
        case 'contained':
            buttonStyle = Object.assign(Object.assign({}, baseStyle), containedStyle);
            break;
        case 'outlined':
            buttonStyle = Object.assign(Object.assign({}, baseStyle), outlinedStyle);
            break;
        case 'text':
            buttonStyle = Object.assign(Object.assign({}, baseStyle), textStyle);
            break;
        default:
            buttonStyle = Object.assign(Object.assign({}, baseStyle), containedStyle);
            break;
    }
    if (color === 'error') {
        buttonStyle.color = palette.error[1];
        if (variant === 'contained') {
            buttonStyle.background = palette.error[1];
            buttonStyle.color = palette.common.white;
        }
        else if (variant === 'outlined') {
            buttonStyle.color = palette.error[1];
            buttonStyle.borderColor = palette.error[1];
        }
    }
    return (_jsx(MuiButton, Object.assign({ variant: variant, color: color, sx: Object.assign(Object.assign(Object.assign({}, buttonStyle), sx), { color: color }) }, rest, { children: children })));
};
