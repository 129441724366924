import { useAppDispatch } from '@app/hooks';
import { setSelectedFilterOptions } from '@app/slice/contentSlice';
import { useFilterOptions } from '@estimation/entities/estimationSolution/lib/hooks/useFilterOptions';
import { useEffect, useState } from 'react';
export function useFilterModal() {
    const { filterOptions, selectedFilterOptions } = useFilterOptions();
    const [tempCatergoryOptions, setTempCatergoryOptions] = useState((selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.project_type_filters) || []);
    useEffect(() => {
        if (selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.project_type_filters) {
            setTempCatergoryOptions(selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.project_type_filters);
        }
    }, [selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.project_type_filters]);
    const dispatch = useAppDispatch();
    const handleSubmit = () => {
        dispatch(setSelectedFilterOptions({ project_type_filters: tempCatergoryOptions }));
    };
    return {
        tempCatergoryOptions,
        setTempCatergoryOptions,
        projectFilters: (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.project_type_filters) || [],
        handleSubmit,
        selectedFilters: (selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.project_type_filters) || []
    };
}
