import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Stack, Typography } from '@mui/material';
import { solutionColumns, BillQuantitiesColumns, TableKeyEnum } from '@shared-app/lib/constants';
import { useTranslation } from 'react-i18next';
import { useEstimationResult } from '@estimation/entities/estimationSolution/lib/hooks/useEstimationResult';
import { WeightEstimation } from './WeightEstimation';
import { BOQTableHeader } from './BOQTableHeader';
import { StickyHeadTable } from '@shared-app/ui/Table';
export function EstimationResult() {
    var _a, _b, _c, _d, _e;
    const { billData, estimationData, handleSelectSolution, selectedSolutionIdx } = useEstimationResult();
    const { t } = useTranslation();
    return (_jsxs(Stack, { children: [((_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.prediction) === null || _a === void 0 ? void 0 : _a.estimated_weight) && (_jsx(WeightEstimation, { maxWeight: (_b = estimationData === null || estimationData === void 0 ? void 0 : estimationData.prediction) === null || _b === void 0 ? void 0 : _b.estimated_weight_max, minWeight: (_c = estimationData === null || estimationData === void 0 ? void 0 : estimationData.prediction) === null || _c === void 0 ? void 0 : _c.estimated_weight_min, weight: (_d = estimationData === null || estimationData === void 0 ? void 0 : estimationData.prediction) === null || _d === void 0 ? void 0 : _d.estimated_weight, plot_url: estimationData === null || estimationData === void 0 ? void 0 : estimationData.plot_url })), _jsxs(Stack, { children: [((_e = estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions) === null || _e === void 0 ? void 0 : _e.length) > 0 && (_jsxs(Box, { my: 5, children: [_jsx(Typography, { fontSize: 28, fontWeight: 600, children: t('Closest Solutions') }), _jsx(StickyHeadTable, { rows: estimationData.solutions, columns: solutionColumns, onRowClick: (_row, index) => handleSelectSolution(index), tableUniqueKey: TableKeyEnum.estimateSolutions })] })), (billData === null || billData === void 0 ? void 0 : billData.length) > 0 && (_jsxs(_Fragment, { children: [_jsx(BOQTableHeader, { billData: billData, estimationData: estimationData, handleSelectSolution: handleSelectSolution, selectedSolution: selectedSolutionIdx.toString() }), _jsx(Box, { width: '70%', children: _jsx(StickyHeadTable, { rows: billData, columns: BillQuantitiesColumns, tableUniqueKey: TableKeyEnum.estimateBOQ }) })] }))] })] }));
}
