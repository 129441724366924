import { ParametersEnum } from '../constants';
import { getSingleSidedValue } from './getSingleSidedValue';
export const hasParamError = (param, parameters) => {
    if (param.isRequired && param.value === null) {
        return true;
    }
    else if (param.value !== null) {
        if (param.name === ParametersEnum.single_sided) {
            if (param.value !== getSingleSidedValue(parameters)) {
                return true;
            }
        }
        if (param.value < param.min || param.value > param.max) {
            return true;
        }
    }
    return false;
};
