import { BillQuantitiesColumns } from '@shared-app/lib/constants';
import i18n from 'i18next';
const transformNumber = (input) => {
    if (typeof input === 'number')
        return input.toFixed(2); // Fixing the last two digits
    return input;
};
export const transformBillData = (input, isImperial = false) => {
    let fixedObj = {};
    const inputKeys = Object.keys(input);
    BillQuantitiesColumns.forEach(column => {
        if (inputKeys.includes(column.id)) {
            fixedObj = Object.assign(Object.assign({}, fixedObj), { [i18n.t(isImperial ? column.labelImperial || column.label : column.label)]: transformNumber(input[column.id]) });
        }
    });
    return fixedObj;
};
