export var BOQEnum;
(function (BOQEnum) {
    BOQEnum["art_no"] = "art_no";
    BOQEnum["art_descript"] = "art_descript";
    BOQEnum["art_descript_eng"] = "art_descript_eng";
    BOQEnum["units"] = "units";
    BOQEnum["weight"] = "weight";
    BOQEnum["total_weight"] = "total_weight";
    BOQEnum["max_factor"] = "max_factor";
    BOQEnum["count"] = "count";
})(BOQEnum || (BOQEnum = {}));
