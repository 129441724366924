import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Box, Typography, IconButton } from '@mui/material';
import { useEffect } from 'react';
import { ProjectFilters } from './ProjectFIlters';
import { useTranslation } from 'react-i18next';
import { Close } from '@shared-app/ui/icons';
import { Button } from '@shared/ui/Button';
import { useFilterModal } from '@estimation/entities/estimationSolution/lib/hooks/useFilterModal';
import palette from '@theme/palette';
import styles from './styles.module.scss';
export function ProjectFilterModal({ handleClose, visible }) {
    const { t } = useTranslation();
    const { handleSubmit, projectFilters, setTempCatergoryOptions, tempCatergoryOptions, selectedFilters } = useFilterModal();
    useEffect(() => {
        if (visible) {
            setTempCatergoryOptions(selectedFilters);
        }
    }, [visible]);
    return (_jsx(Modal, { onClose: handleClose, open: visible, sx: { width: '80%', top: '25%', left: '10%', height: '60%' }, children: _jsxs(Box, { bgcolor: palette.common.white, p: 2, sx: { borderRadius: 2 }, children: [_jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '24px', children: [_jsx(Typography, { fontSize: 22, fontWeight: 600, color: palette.neutral[10], children: t('Project Categories') }), _jsx(IconButton, { onClick: () => handleClose(), children: _jsx(Close, { sx: { color: palette.primary[50] } }) })] }), projectFilters && (_jsx(ProjectFilters, { data: projectFilters, selectedData: tempCatergoryOptions || [], setSelectedData: setTempCatergoryOptions })), _jsx(Box, { display: 'flex', justifyContent: 'flex-end', children: _jsx(Button, { onClick: () => {
                            handleSubmit();
                            handleClose();
                        }, type: "button", color: "primary", variant: "contained", size: "small", className: styles.applyButton, children: t('Apply') }) })] }) }));
}
