import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import palette from '@theme/palette';
import { Text, Button } from '@shared-app/ui';
import apis from '@shared-app/lib/apis';
import { ROUTES } from '@shared-app/lib/constants';
import { getViewName } from '../../lib/utils/getViewName';
import { SolutionTypes } from '../../lib/types/SolutionTypes';
import { useNavigate } from 'react-router-dom';
import { ToastService } from '@shared/lib/services/toast.service';
import { useTranslation } from 'react-i18next';
import { SolutionStatus } from '@shared-app/lib/types';
export function ResultHeader({ selectedSolutionAndType, projectId, selectedId, handleReloadApiData }) {
    const { t } = useTranslation();
    const { value: selectedSolution, type: selectedType } = selectedSolutionAndType;
    const navigate = useNavigate();
    const handleGoToValidation = () => {
        if ([SolutionTypes.cluster, SolutionTypes.merged, SolutionTypes.assigned].includes(selectedSolutionAndType.type)) {
            navigate(ROUTES.VALIDATION.VALIDATE.replace(':projectId', projectId).replace(':solutionId', selectedId));
        }
    };
    const onDiscard = () => {
        const validatedNewObj = JSON.parse(JSON.stringify(selectedSolutionAndType.value));
        validatedNewObj.status = SolutionStatus.discarded;
        apis.projects
            .updateSolutionDetails(projectId, selectedId, validatedNewObj)
            .then(() => {
            ToastService.success(t('Solution Discarded Successfully'));
            handleReloadApiData();
        })
            .catch(() => {
            ToastService.error(t('Please try again'));
        });
    };
    const isDiscardEnabled = 'status' in selectedSolution && selectedSolution.status === SolutionStatus.discarded;
    return (_jsxs(Box, { bgcolor: palette.common.white, height: 80, justifyContent: 'space-between', alignItems: 'center', display: 'flex', p: 2, children: [_jsx(Stack, { children: _jsx(Text, { label: selectedType === SolutionTypes.cluster
                        ? getViewName(selectedSolution.view, selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.name, t)
                        : selectedType === SolutionTypes.merged || selectedType === SolutionTypes.assigned
                            ? getViewName(selectedType, selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.name, t)
                            : selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.name, variant: "h6" }) }), selectedType === SolutionTypes.cluster ? (_jsxs(Box, { display: "flex", children: [_jsx(Button, { variant: "outlined", color: "error", disabled: isDiscardEnabled, onClick: onDiscard, sx: { marginRight: 2 }, children: t('Discard Solution') }), _jsx(Button, { variant: selectedSolution.status === SolutionStatus.validated ? 'outlined' : 'contained', onClick: handleGoToValidation, children: t('Review') })] })) : selectedType === SolutionTypes.merged || selectedType === SolutionTypes.assigned ? (_jsx(Box, { display: "flex", children: _jsx(Button, { variant: selectedSolution.status === SolutionStatus.validated ? 'outlined' : 'contained', onClick: handleGoToValidation, children: t('Review') }) })) : null] }));
}
