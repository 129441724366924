import { ToastService } from '@shared/lib/services/toast.service';
import i18next from 'i18next';
export const handleDownload = (content, fileName) => {
    const { t } = i18next;
    const link = document.createElement('a');
    link.setAttribute('href', content);
    link.setAttribute('download', fileName);
    link.click();
    ToastService.success(t('Successfully downloaded'));
};
