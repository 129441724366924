import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Grid } from '@mui/material';
import { SolutionTable } from '@estimation/features/SolutionTable';
import { SolutionImage } from '@estimation/features/SolutionImage';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useEstimationResult } from '@estimation/entities/estimationSolution';
import { LoadSolutions } from '@estimation/features/LoadSolutions';
export function ClosestSolution() {
    const { t } = useTranslation();
    const { solutions } = useEstimationResult();
    return (_jsxs("div", { className: styles.container, children: [_jsx(Typography, { fontSize: '22px', fontWeight: 600, children: t('Closest Solutions') }), _jsxs(Grid, { container: true, spacing: '10px', maxWidth: 'calc(100vw - 72px)', children: [_jsxs(Grid, { item: true, xs: 7, children: [_jsx(SolutionTable, { solutions: solutions }), _jsx(LoadSolutions, {})] }), _jsx(Grid, { item: true, xs: 5, children: _jsx(SolutionImage, {}) })] })] }));
}
