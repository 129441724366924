import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormContainer } from '@shared-app/ui';
import { Formik, Form } from 'formik';
import { CircularProgress, Button, Stack } from '@mui/material';
import { AddCircleOutlineOutlined } from '@shared-app/ui/icons';
import { useTranslation } from 'react-i18next';
import { useNewSolution } from '../../../../../Estimation/entities/estimationSolution/lib/hooks/useNewSolution';
import { checkIfValuesNull } from '../../../../../Estimation/entities/estimationSolution/lib/utils/checkIfValuesNull';
import { validationSchema, initialFormValues } from '../../../../../Estimation/entities/estimationSolution/lib/contstants';
import { Filters } from './Filters/Filters';
export function NewSolution() {
    const { filteredCount, handleInputValueChange, inputValues, handleSubmit } = useNewSolution();
    const isButtonDisabled = checkIfValuesNull(inputValues.filter(item => item.isRequired));
    const { t } = useTranslation();
    return (_jsx(FormContainer, { name: t('New Solution'), children: _jsx(Formik, { initialValues: initialFormValues, isInitialValid: false, validationSchema: validationSchema, onSubmit: (values, { setSubmitting }) => {
                handleSubmit(values, setSubmitting);
            }, children: ({ isValid, setFieldValue, isSubmitting, errors, touched }) => {
                return (_jsxs(Form, { children: [_jsx(Filters, { handleInputValueChange: handleInputValueChange, inputValues: inputValues, setFieldValue: setFieldValue, isSubmitting: isSubmitting }), _jsx(Stack, { width: '100%', alignItems: 'center', mt: 5, children: _jsx(Button, { sx: {
                                    width: '70%'
                                }, type: "submit", startIcon: isSubmitting ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : (_jsx(AddCircleOutlineOutlined, {})), disabled: !isValid || isSubmitting || filteredCount < 50 || isButtonDisabled, variant: "contained", children: t(isSubmitting ? 'Processing. Please Wait...' : 'Estimate Solution Weight') }) })] }));
            } }) }));
}
