import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Text } from '../Common';
import palette from '@theme/palette';
import { Box } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
export const MetaDialog = ({ data, metaOpen, setMetaOpen }) => {
    const { t } = useTranslation();
    const metaData = data
        ? [
            {
                name: t('Project name'),
                value: data.project_title
            },
            {
                name: t('Date'),
                value: moment(data.date).format('DD-MM-YYYY')
            },
            {
                name: t('Project ID'),
                value: data.project_id
            },
            {
                name: t('Office location'),
                value: data.office_location
            },
            {
                name: t('Office country'),
                value: data.office_country
            },
            {
                name: t('Customer name'),
                value: data.customer_name
            },
            {
                name: t('Project type'),
                value: data.project_type_1
            }
        ]
        : [];
    const handleClose = () => {
        setMetaOpen(false);
    };
    const rows = [];
    for (let i = 0; i < metaData.length; i += 2) {
        rows.push(metaData.slice(i, i + 2));
    }
    return (_jsxs(Dialog, { open: metaOpen, onClose: handleClose, maxWidth: 'md', fullWidth: true, children: [_jsx(DialogTitle, { children: _jsx(Text, { label: t('Project Metadata'), variant: "h6" }) }), _jsx(DialogContent, { sx: { padding: 4 }, children: rows.map((row, _) => (_jsx(Box, { display: 'flex', height: 52, borderBottom: 1, borderColor: '#0000001F', alignItems: 'center', justifyContent: 'space-between', children: row.map((param) => {
                        return (_jsxs(Box, { justifyContent: 'space-between', width: '45%', display: 'flex', children: [_jsx(Text, { label: `${param.name}:`, variant: "body2", sx: { color: palette.secondary.text } }), _jsx(Text, { label: param.value, variant: "body2", sx: { color: palette.primary[10] } })] }));
                    }) }))) }), _jsx(DialogActions, { children: _jsx(Button, { variant: "text", sx: { marginRight: 2 }, onClick: handleClose, children: t('Close') }) })] }));
};
