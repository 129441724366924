import { jsx as _jsx } from "react/jsx-runtime";
import { FilterDataSelect } from '@shared-app/ui';
import { useFilterOptions } from '@estimation/entities/estimationSolution/lib/hooks/useFilterOptions';
import { useTranslation } from 'react-i18next';
import { UnfoldMore } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
export function YearFilter() {
    const { handleYearChange, years, yearOptions } = useFilterOptions();
    const { t } = useTranslation();
    return (_jsx(FilterDataSelect, { data: (yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values) || [], handleDataChange: values => handleYearChange(values), label: t('Years'), selectedValues: years || [], isDisabled: !(yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values), icon: UnfoldMore, textClassName: styles.selectText }));
}
