import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import * as S from './styles';
import { StickyHeadTable } from '@shared-app/ui/Table';
import { ValColumnEnum, ValidationStatusEnum } from '@shared-app/lib/types';
import { ROUTES, TableKeyEnum } from '@shared-app/lib/constants';
import { HeaderSkelton } from './Header/HeaderSkelton';
import { Header } from './Header/Header';
import { ValidationColumns } from '../lib/constants/ValidationColumns';
import { useFilteredData } from '../lib/hooks/useFilteredData';
export function OverviewPage() {
    const navigate = useNavigate();
    const handleOnRowClick = (row) => {
        if ([ValidationStatusEnum.pending, ValidationStatusEnum.validated].includes(row[ValColumnEnum.status])) {
            return navigate(ROUTES.VALIDATION.HIERARCHY.replace(':id', row.id));
        }
    };
    const { countryArray, customerQuery, datesArray, filteredData, handleCustomerChange, handleDataChange, handleResetAll, isLoading, locationArray, projectCategoryArray, selectedCountry, selectedDate, selectedSystem, selectedLocation, selectedProjectCategory, selectedStatus, statusArray, systemArray } = useFilteredData();
    return (_jsxs(S.Container, { children: [isLoading ? (_jsx(HeaderSkelton, {})) : (_jsx(Header, { countryArray: countryArray, datesArray: datesArray, statusArray: statusArray, customerQuery: customerQuery, locationArray: locationArray, projectCategoryArray: projectCategoryArray, systemArray: systemArray, selectedCountry: selectedCountry, selectedSystem: selectedSystem, selectedDate: selectedDate, selectedStatus: selectedStatus, selectedLocation: selectedLocation, selectedProjectCategory: selectedProjectCategory, handleCustomerChange: handleCustomerChange, handleDataChange: handleDataChange, handleResetAll: handleResetAll })), _jsx(Stack, { height: 'calc((100vh - 280px))', children: _jsx(StickyHeadTable, { isProjectList: true, isLoading: isLoading, rows: filteredData, columns: ValidationColumns, onRowClick: (row, _idx) => handleOnRowClick(row), pagination: true, tableUniqueKey: TableKeyEnum.projectList }) })] }));
}
