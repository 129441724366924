import { ParametersEnum } from '../types';
export const parameters = [
    {
        name: ParametersEnum.length,
        label: 'Wall Length (mm)',
        labelImperial: 'Wall Length (ft)',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.height,
        label: 'Average Height (mm)',
        labelImperial: 'Average Height (ft)',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.thickness,
        label: 'Average Thickness (mm)',
        labelImperial: 'Average Thickness (ft)',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.segments,
        label: 'Number of Segments',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.offsets,
        label: 'Number of Offsets',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.double_sided,
        label: 'Number of Double Sided Segments',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.single_sided,
        label: 'Number of Single Sided Segments',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.wall_crossings,
        label: 'Number of Cross Junctions',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.t_junctions,
        label: 'Number of T Junctions',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.stop_ends,
        label: 'Number of Stopends',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.perpendicular_angles,
        label: 'Number of Perpendicular Corners',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.non_perpendicular_angles,
        label: 'Number of Non-Perpendicular Corners',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.rings,
        label: 'Number of Shafts (Rooms)',
        value: null,
        min: 0,
        max: Number.MAX_SAFE_INTEGER,
        isRequired: true
    },
    {
        name: ParametersEnum.pressure,
        label: 'Concrete Pressure, kN/m2',
        labelImperial: 'Concrete Pressure, PSF',
        value: null,
        min: 25,
        max: 100,
        isRequired: false
    }
];
