import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress } from '@mui/material';
import { useEstimationResult, useSolutionData } from '@estimation/entities/estimationSolution';
import styles from './styles.module.scss';
import { ShowSolutionImage } from './ShowSolutionImage';
import { ShowSolutionDxf } from './ShowSolutionDxf';
export function SolutionImage() {
    const { image, isLoading } = useSolutionData();
    const { isLoadMoreEnabled } = useEstimationResult();
    return (_jsxs("div", { className: styles.container, style: { height: isLoadMoreEnabled ? 450 : 650 }, children: [isLoading ? (_jsx("div", { className: styles.loaderContainer, children: _jsx(CircularProgress, {}) })) : (_jsx(Box, { display: 'flex', justifyContent: 'center', height: '100%', width: '100%', children: _jsx("img", { src: image, className: styles.image }) })), _jsxs("div", { className: styles.actions, children: [_jsx(ShowSolutionDxf, {}), _jsx(ShowSolutionImage, {})] })] }));
}
