import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './store';
import './i18n/config';
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
// <React.StrictMode>
_jsx(Provider, { store: store, children: _jsx(App, {}) })
// </React.StrictMode>
);
