import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FilterHeader } from '@estimation/features/FilterHeader';
import { NewSolution } from '@estimation/features/NewSolution';
import { SelectedFilterHeader } from '@estimation/features/SelectedFilterHeader';
import styles from './styles.module.scss';
import { useState } from 'react';
export function PostProcessFilterHeader() {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    return (_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: `${styles.panel} ${isPanelOpen ? styles.open : ''}`, children: [_jsx(FilterHeader, { handlePanelClose: () => setIsPanelOpen(false) }), _jsx(NewSolution, {})] }), _jsx(SelectedFilterHeader, { isPanelOpen: isPanelOpen, handleToggle: () => {
                    setIsPanelOpen(!isPanelOpen);
                } })] }));
}
