import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { useEstimationResult } from '../hooks/useEstimationResult';
import { useEffect } from 'react';
import { getImage } from '../utils/getImage';
const useStore = create()(devtools(set => ({
    image: undefined,
    fileName: undefined,
    isLoading: false,
    dxfPath: undefined,
    boxСoordinates: undefined,
    solutionId: undefined,
    setImageDetails: (imageUrl, fileName) => {
        return set(() => ({ image: imageUrl, fileName }));
    },
    setLoading: isLoading => {
        return set(() => ({ isLoading }));
    },
    setDxfPath: (dxfPath) => {
        return set(() => ({ dxfPath }));
    },
    setBoxСoordinates: (boxСoordinates) => {
        return set(() => ({ boxСoordinates }));
    },
    setSolutionId: solutionId => {
        return set(() => ({ solutionId }));
    }
})));
export function useSolutionData() {
    const { estimationData, selectedSolutionIdx } = useEstimationResult();
    const { setImageDetails, fileName, image, setLoading, isLoading, dxfPath, setDxfPath, boxСoordinates, setBoxСoordinates, solutionId, setSolutionId } = useStore();
    useEffect(() => {
        var _a;
        const fetchImageData = () => {
            var _a;
            setLoading(true);
            const selectedSolution = (_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions) === null || _a === void 0 ? void 0 : _a.at(selectedSolutionIdx);
            const fileName = selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.drawing_file_name;
            getImage(selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.image).then(imageUrl => {
                setImageDetails(imageUrl, fileName);
                setLoading(false);
            });
            setSolutionId(selectedSolution.uuid);
            setBoxСoordinates(selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.box_coordinates);
            setDxfPath(selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.dxf_path);
        };
        if (((_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            fetchImageData();
        }
    }, [selectedSolutionIdx, estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions]);
    return { fileName, image, isLoading, dxfPath, boxСoordinates, solutionId };
}
