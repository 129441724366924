var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apis from '@shared-app/lib/apis';
import { useEffect } from 'react';
import { setFilterOption, setFilteredDataCount, setSelectedFilterOptions, setEstimationData, setSolutionFieldItems, initialState } from '@app/slice/contentSlice';
import { useDebounce, useMetricSystem } from '@shared-app/hooks';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { ToastService } from '@shared/lib/services/toast.service';
export function useGetFilters() {
    const dispatch = useAppDispatch();
    const selectedFilterOptions = useAppSelector(state => state.content.selectedFilterOptions);
    const debouncedSearchKey = useDebounce(selectedFilterOptions, 1000);
    const { isImperial } = useMetricSystem();
    const fetchData = (searchKey, dispatch) => __awaiter(this, void 0, void 0, function* () {
        try {
            const res = yield apis.dataset.getDataset(searchKey, isImperial);
            dispatch(setFilteredDataCount({
                filteredCount: res.metadata.filtered_dataset_size,
                originalDataCount: res.metadata.original_dataset_size
            }));
        }
        catch (err) {
            ToastService.error(err);
        }
    });
    useEffect(() => {
        try {
            if (JSON.stringify(debouncedSearchKey) !== '{}') {
                fetchData(debouncedSearchKey, dispatch);
            }
        }
        catch (err) {
            ToastService.error(err);
        }
    }, [debouncedSearchKey, dispatch]);
    useEffect(() => {
        apis.dataset
            .getDataset(undefined, isImperial)
            .then(res => {
            dispatch(setSelectedFilterOptions(res.metadata.filters));
            dispatch(setFilterOption(res.metadata.filters));
            dispatch(setEstimationData(initialState.estimationData));
            dispatch(setSolutionFieldItems(initialState.solutionFieldItems));
        })
            .catch(err => ToastService.error(err));
    }, [isImperial]);
}
