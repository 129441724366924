import styled from 'styled-components';
export const Container = styled.div `
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;
export const Preview = styled.div `
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 77vh; 
  min-height: 400px; 
  max-height: 800px
  width: 100%;
  width: -webkit-fill-available;
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 8px;
`;
export const Status = styled.div `
  width: ${props => (props.size === 'large' ? 28 : 16)}px;
  height: ${props => (props.size === 'large' ? 28 : 16)}px;
  border-radius: 100%;
  background: #ffffff;

  background-color: ${props => {
    switch (props.status) {
        case 'Not Validated': {
            return props.theme.palette.primary.main;
        }
        case 'Pending': {
            return 'yellow';
        }
        case 'Activated': {
            return props.theme.palette.info.main;
        }
        case 'Validated': {
            return props.theme.palette.success.main;
        }
    }
}};
`;
