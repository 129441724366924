import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useState, useEffect } from 'react';
import { setSelectedFilterOptions } from '@app/slice/contentSlice';
import { FilterEnum } from '../contstants';
export function useFilterOptions() {
    var _a, _b;
    const { filterOptions, selectedFilterOptions, filteredDataCount } = useAppSelector(state => state.content);
    const filteredCount = (filteredDataCount === null || filteredDataCount === void 0 ? void 0 : filteredDataCount.filteredCount) || 0;
    const yearOptions = (_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.include_filters) === null || _a === void 0 ? void 0 : _a.filter(fil => fil.name === FilterEnum.date)[0];
    const organizationalOptions = (_b = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.include_filters) === null || _b === void 0 ? void 0 : _b.filter(fil => fil.name === FilterEnum.office_country)[0];
    const dispatch = useAppDispatch();
    const [category, setCategory] = useState([]);
    const [years, setYears] = useState([]);
    const [organization, setOrganization] = useState([]);
    const [filterValues, setFilterValues] = useState({});
    const [resetCounter, setResetCounter] = useState(0);
    useEffect(() => {
        var _a;
        setYears(yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values);
        const categoryCopy = (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.project_type_filters) && Array.from(filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.project_type_filters);
        setCategory(categoryCopy);
        setOrganization(organizationalOptions === null || organizationalOptions === void 0 ? void 0 : organizationalOptions.values);
        setFilterValues((_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.min_max_filters) === null || _a === void 0 ? void 0 : _a.reduce((acc, { name, min, max }) => {
            acc[name] = [min, max];
            return acc;
        }, {}));
    }, [filterOptions, yearOptions, organizationalOptions, dispatch]);
    const handleFilterChange = (id) => (values) => {
        setFilterValues(prevFilterValues => (Object.assign(Object.assign({}, prevFilterValues), { [id]: values })));
        const min_max_filters = selectedFilterOptions.min_max_filters.map(item => {
            const matchingFilter = item.name === id;
            if (matchingFilter) {
                return Object.assign(Object.assign({}, item), { min: values[0], max: values[1] });
            }
            else {
                return item;
            }
        });
        dispatch(setSelectedFilterOptions({ min_max_filters: min_max_filters }));
    };
    const handleOrganizationalChange = (selectedOrganization) => {
        setOrganization(selectedOrganization);
        dispatch(setSelectedFilterOptions({
            include_filters: selectedFilterOptions.include_filters.map(filter => {
                if (filter.name === FilterEnum.office_country) {
                    return { name: filter.name, values: selectedOrganization };
                }
                else {
                    return filter;
                }
            })
        }));
    };
    const handleYearChange = (selectedYears) => {
        setYears(selectedYears);
        dispatch(setSelectedFilterOptions({
            include_filters: selectedFilterOptions.include_filters.map(filter => {
                if (filter.name === FilterEnum.date) {
                    return { name: filter.name, values: selectedYears };
                }
                else {
                    return filter;
                }
            })
        }));
    };
    const handleResetFilterOption = () => {
        var _a;
        dispatch(setSelectedFilterOptions({ min_max_filters: filterOptions.min_max_filters }));
        setFilterValues((_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.min_max_filters) === null || _a === void 0 ? void 0 : _a.reduce((acc, { name, min, max }) => {
            acc[name] = [min, max];
            return acc;
        }, {}));
    };
    const handleResetAll = () => {
        var _a;
        setFilterValues((_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.min_max_filters) === null || _a === void 0 ? void 0 : _a.reduce((acc, { name, min, max }) => {
            acc[name] = [min, max];
            return acc;
        }, {}));
        setResetCounter(prev => prev + 1);
        setYears(yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values);
        setOrganization(organizationalOptions === null || organizationalOptions === void 0 ? void 0 : organizationalOptions.values);
        dispatch(setSelectedFilterOptions(filterOptions));
    };
    const handleInputChange = (newValue, id, type) => {
        setFilterValues(prevFilterValues => (Object.assign(Object.assign({}, prevFilterValues), { [id]: [
                type === 'min' ? newValue : prevFilterValues[id][0],
                type === 'max' ? newValue : prevFilterValues[id][1]
            ] })));
        const min_max_filters = selectedFilterOptions.min_max_filters.map(item => {
            if (item.name === id) {
                return Object.assign(Object.assign({}, item), { [type]: newValue });
            }
            else {
                return item;
            }
        });
        dispatch(setSelectedFilterOptions({ min_max_filters: min_max_filters }));
    };
    return {
        category,
        years,
        organization,
        filterValues,
        resetCounter,
        filteredCount,
        handleInputChange,
        handleResetAll,
        handleResetFilterOption,
        handleYearChange,
        handleFilterChange,
        handleOrganizationalChange,
        yearOptions,
        organizationalOptions,
        filterOptions,
        selectedFilterOptions,
        setCategory
    };
}
