export function sliceRows(array, sliceLength = 3) {
    const slicedRows = [];
    if (sliceLength >= array.length) {
        return [array];
    }
    for (let i = 0; i < array.length; i += sliceLength) {
        slicedRows.push(array.slice(i, i + sliceLength));
    }
    return slicedRows;
}
