import { useEffect, useState } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { setProjects } from '@app/slice/contentSlice';
import { ValColumnEnum, ValidationStatusEnum } from '@shared-app/lib/types';
import apis from '@shared-app/lib/apis';
import { db } from '@shared-app/lib/config/db';
import { getDataFromLocalStorage, trimAndLowerText } from '@shared-app/utils';
import { extractData } from '../../utils/extractData';
import { extractDate } from '../../utils/extractDate';
import { ToastService } from '@shared/lib/services/toast.service';
export function useFilteredData() {
    const [selectedDate, setSelectedDate] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState([]);
    const [selectedSystem, setSelectedSystem] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState([]);
    const [selectedProjectCategory, setSelectedProjectCategory] = useState([]);
    const [customerQuery, setCustomerQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState([
        ValidationStatusEnum.pending,
        ValidationStatusEnum.validated
    ]);
    const [isLoading, setIsLoading] = useState(false);
    const projects = useAppSelector(state => state.content.projects);
    const dispatch = useAppDispatch();
    const datesArray = extractDate(projects);
    const systemArray = extractData(projects, ValColumnEnum.system);
    const countryArray = extractData(projects, ValColumnEnum.office_country);
    const locationArray = extractData(projects, ValColumnEnum.office_location);
    const statusArray = extractData(projects, ValColumnEnum.status);
    const projectCategoryArray = extractData(projects, ValColumnEnum.project_type_1);
    useEffect(() => {
        setIsLoading(true);
        apis.projects
            .getProjects()
            .then(res => {
            dispatch(setProjects(res));
            setIsLoading(false);
            setSelectedDate(getDataFromLocalStorage(ValColumnEnum.date, datesArray));
            setSelectedSystem(getDataFromLocalStorage(ValColumnEnum.system, systemArray));
            setSelectedCountry(getDataFromLocalStorage(ValColumnEnum.office_country, countryArray));
            setSelectedLocation(getDataFromLocalStorage(ValColumnEnum.office_location, locationArray));
            setSelectedStatus(getDataFromLocalStorage(ValColumnEnum.status, [
                ValidationStatusEnum.pending,
                ValidationStatusEnum.validated
            ]));
            setCustomerQuery(getDataFromLocalStorage(ValColumnEnum.customer_name, ''));
            setSelectedProjectCategory(getDataFromLocalStorage(ValColumnEnum.project_type_1, projectCategoryArray));
        })
            .catch(err => ToastService.error(err));
        db.table('myObject').clear();
    }, [dispatch]);
    const handleDataChange = (selected, field) => {
        switch (field) {
            case ValColumnEnum.date:
                setSelectedDate(selected);
                break;
            case ValColumnEnum.office_country:
                setSelectedCountry(selected);
                break;
            case ValColumnEnum.system:
                setSelectedSystem(selected);
                break;
            case ValColumnEnum.status:
                setSelectedStatus(selected);
                break;
            case ValColumnEnum.office_location:
                setSelectedLocation(selected);
                break;
            case ValColumnEnum.project_type_1:
                setSelectedProjectCategory(selected);
                break;
            default:
                break;
        }
        localStorage.setItem(field, JSON.stringify(selected));
    };
    const handleCustomerChange = (query) => {
        setCustomerQuery(query);
        localStorage.setItem(ValColumnEnum.customer_name, JSON.stringify(query));
    };
    const handleResetAll = () => {
        setSelectedDate(datesArray);
        setSelectedCountry(countryArray);
        setSelectedSystem(systemArray);
        setSelectedLocation(locationArray);
        setSelectedStatus([ValidationStatusEnum.pending, ValidationStatusEnum.validated]);
        setCustomerQuery('');
        setSelectedProjectCategory(projectCategoryArray);
        localStorage.removeItem(ValColumnEnum.date);
        localStorage.removeItem(ValColumnEnum.system);
        localStorage.removeItem(ValColumnEnum.office_country);
        localStorage.removeItem(ValColumnEnum.office_location);
        localStorage.removeItem(ValColumnEnum.status);
        localStorage.removeItem(ValColumnEnum.project_type_1);
        localStorage.removeItem(ValColumnEnum.customer_name);
    };
    const filterData = () => {
        let filteredData = [...projects];
        if (selectedDate.length > 0) {
            filteredData = filteredData.filter(row => selectedDate.includes(moment(row[ValColumnEnum.date]).format('YYYY')));
        }
        if (selectedStatus.length > 0) {
            filteredData = filteredData.filter(row => selectedStatus.includes(row[ValColumnEnum.status]));
        }
        if (selectedCountry.length > 0) {
            filteredData = filteredData.filter(row => selectedCountry.includes(row[ValColumnEnum.office_country]));
        }
        if (selectedSystem.length > 0) {
            filteredData = filteredData.filter(row => selectedSystem.includes(row[ValColumnEnum.system]));
        }
        if (selectedLocation.length > 0) {
            filteredData = filteredData.filter(row => selectedLocation.includes(row[ValColumnEnum.office_location]));
        }
        if (selectedProjectCategory.length > 0) {
            filteredData = filteredData.filter(row => selectedProjectCategory.includes(row[ValColumnEnum.project_type_1]));
        }
        const trimmedCustomerQuery = trimAndLowerText(customerQuery);
        if (trimmedCustomerQuery.length) {
            filteredData = filteredData.filter(row => trimAndLowerText(row[ValColumnEnum.customer_name]).includes(trimmedCustomerQuery));
        }
        return filteredData;
    };
    const filteredData = filterData();
    return {
        handleDataChange,
        handleCustomerChange,
        filteredData,
        isLoading,
        handleResetAll,
        datesArray,
        countryArray,
        locationArray,
        statusArray,
        projectCategoryArray,
        selectedDate,
        selectedSystem,
        systemArray,
        selectedCountry,
        selectedLocation,
        selectedProjectCategory,
        customerQuery,
        selectedStatus
    };
}
