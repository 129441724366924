var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apis from '@shared-app/lib/apis';
import { ToastService } from '@shared/lib/services/toast.service';
export const getImage = (imageUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const localImageUrl = yield apis.dataset
        .getImage(imageUrl)
        .then(res => {
        const imageURL = URL.createObjectURL(res);
        return imageURL;
    })
        .catch(e => {
        ToastService.error(e);
        return '';
    });
    return localImageUrl;
});
