export var SolutionView;
(function (SolutionView) {
    SolutionView["top"] = "top";
    SolutionView["side"] = "side";
    SolutionView["section"] = "section";
    SolutionView["asssigned"] = "assigned";
    SolutionView["merged"] = "merged";
})(SolutionView || (SolutionView = {}));
export var SolutionStatus;
(function (SolutionStatus) {
    SolutionStatus["pending"] = "pending";
    SolutionStatus["validated"] = "validated";
    SolutionStatus["discarded"] = "discarded";
})(SolutionStatus || (SolutionStatus = {}));
export var FileStatus;
(function (FileStatus) {
    FileStatus["pending"] = "pending";
    FileStatus["validated"] = "validated";
    FileStatus["created"] = "created";
    FileStatus["uploaded"] = "uploaded";
    FileStatus["converted"] = "converted";
    FileStatus["failed"] = "failed";
})(FileStatus || (FileStatus = {}));
