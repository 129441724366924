const palette = {
    primary: {
        // main: "#545F71",
        main: "#2196F3",
        dark: "#E6002D",
        light: "#FEB5C4",
        10: "#001A33",
        50: "#0084FF",
        55: "#1890FF",
        70: "#66B5FF",
        text: "#000000DE",
        95: "#E5F3FF",
        state: {
            selected: "#2196F314"
        }
    },
    secondary: {
        main: "#9BA5B7",
        dark: "#101828",
        light: "#101828",
        text: "#00000099",
        text2: "#00000061"
    },
    info: {
        main: "#00A6F9",
        dark: "#00A6F9",
        light: "#00A6F9",
    },
    warning: {
        main: "#EF6C00",
        dark: "#00A6F9",
        light: "#00A6F9",
    },
    success: {
        main: "#00B955",
        dark: "#00B955",
        light: "#31BC4F",
    },
    grey: {
        10: "#E0E0E0",
        50: "#F9FAFB",
        100: "#F5F5F5",
        200: "#EAECF0",
        300: "#C7CDCB",
        400: "#9CA3AF",
        500: "#667085",
        600: "#1D3557",
        700: "#344054",
        800: "#363434",
        900: "#101828",
    },
    neutral: {
        2: "#334151",
        10: "#191919",
        20: "#333333",
        30: "#4C4C4C",
        40: "#666666",
        50: "#808080",
        60: "#999",
        70: "#B3B3B3",
        80: "#CCCCCC",
        90: "#E6E6E6",
        97: "#F7F7F7",
        98: "#EEEEEE"
    },
    action: {
        disabled: "##0000001F"
    },
    error: {
        1: "#FFDAD6",
    },
    tertiary: {
        main: "#EEF1F4",
    },
    common: {
        black: "#000000",
        white: "#ffffff",
    },
};
export default palette;
