var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { AuthService } from '@shared/lib/services';
const http = axios.create({ baseURL: process.env.REACT_APP_API_SERVER });
const request = (method, url, options) => __awaiter(void 0, void 0, void 0, function* () {
    const { idToken } = yield AuthService.currentSession();
    try {
        const response = yield http.request(Object.assign(Object.assign({}, options), { method,
            url, headers: Object.assign(Object.assign({}, options.headers), { Authorization: `Bearer ${idToken.toString()}` }) }));
        return httpResponseHandler(response);
    }
    catch (err) {
        return httpErrorHandler(err);
    }
});
// @ts-ignore
const httpResponseHandler = (response) => {
    return response.data;
};
// @ts-ignore
const httpErrorHandler = (err) => {
    const response = err === null || err === void 0 ? void 0 : err.response;
    if ((response === null || response === void 0 ? void 0 : response.status) === 401) {
        // eslint-disable-next-line no-throw-literal
        throw {
            msg: 'Please try again'
        };
    }
    if ((response === null || response === void 0 ? void 0 : response.status) === 404) {
        // eslint-disable-next-line no-throw-literal
        throw {
            msg: 'User Not Found'
        };
    }
    const data = response === null || response === void 0 ? void 0 : response.data;
    // eslint-disable-next-line no-throw-literal
    throw {
        msg: (data === null || data === void 0 ? void 0 : data.msg) || 'Network Error!'
    };
};
const Http = {
    // @ts-ignore
    get(url, params = {}, headers = {}, options = {}) {
        return request('GET', url, Object.assign({ params, headers }, options));
    },
    // @ts-ignore
    post(url, body = {}, headers = {}, options = {}) {
        return request('POST', url, Object.assign({ data: body, headers }, options));
    },
    // @ts-ignore
    put(url, body = {}, headers = {}, options = {}) {
        return request('PUT', url, Object.assign({ data: body, headers }, options));
    },
    // @ts-ignore
    patch(url, body = {}, headers = {}, options = {}) {
        return request('PATCH', url, Object.assign({ data: body, headers }, options));
    },
    // @ts-ignore
    delete(url, body = {}, headers = {}, options = {}) {
        return request('DELETE', url, Object.assign({ data: body, headers }, options));
    }
};
export default Http;
