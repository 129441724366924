export const getCSV = (data) => {
    const csvRows = [];
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(','));
    for (const row of data) {
        const values = headers.map(header => {
            let escapedValue = row[header];
            if (typeof escapedValue === 'string') {
                escapedValue = escapedValue.replace(/"/g, '""');
                if (escapedValue.includes(',') || escapedValue.includes('"')) {
                    escapedValue = `"${escapedValue}"`;
                }
            }
            return escapedValue;
        });
        csvRows.push(values.join(','));
    }
    return csvRows.join('\n');
};
