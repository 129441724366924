var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputNumber as InputAntd } from 'antd';
import styles from './styles.module.scss';
export function InputNumber(_a) {
    var { errorText } = _a, restProps = __rest(_a, ["errorText"]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(InputAntd, Object.assign({}, restProps, { status: errorText ? 'error' : restProps.status })), errorText && _jsx("p", { className: styles.error, children: errorText })] }));
}
