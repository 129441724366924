import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useGetFilters } from '@estimation/entities/estimationSolution/lib/hooks/useGetFilters';
import styles from './styles.module.scss';
import { useEstimationResult } from '@estimation/entities/estimationSolution/lib/hooks/useEstimationResult';
import { PreProcessFilterHeader } from '@estimation/widgets/PreProcessFilterHeader';
import { PostProcessFilterHeader } from '@estimation/widgets/PostProcessFilterHeader';
import { ClosestSolution } from '@estimation/widgets/ClosestSolution';
import { BOQ } from '@estimation/widgets/BOQ';
import { FeedbackAlert } from '@estimation/features/FeedbackAlert';
import { EstimationResults } from '@estimation/widgets/EstimationResults';
import { NoSolutionDialogue } from '@estimation/features/NoSolutionDialogue';
export function EstimationUpdatedPage() {
    useGetFilters();
    const { estimationData } = useEstimationResult();
    const isProcessingDone = Boolean(estimationData.solutions.length);
    return (_jsxs("div", { className: styles.container, children: [isProcessingDone ? (_jsxs(_Fragment, { children: [_jsx(PostProcessFilterHeader, {}), _jsx(ClosestSolution, {}), _jsx(BOQ, {}), _jsx(EstimationResults, {})] })) : (_jsx(PreProcessFilterHeader, {})), _jsx(NoSolutionDialogue, {}), !isProcessingDone && _jsx(FeedbackAlert, {})] }));
}
