import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useNewSolution, SOLUTIONS_THRESHOLD } from '@estimation/entities/estimationSolution';
import { Tooltip } from '@shared/ui';
import { Box } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { InfoOutlined } from '@shared-app/ui/icons';
export function FilterDataSetCount() {
    const { filteredCount } = useNewSolution();
    const { t } = useTranslation();
    if (filteredCount < SOLUTIONS_THRESHOLD)
        return (_jsx(Tooltip, { title: t('Try to adjust filters to get more Solutions for Estimation'), children: _jsxs(Box, { display: 'flex', alignItems: 'center', gap: '8px', className: classNames(styles.filteredCount, styles.error), children: [t('{{length}} Solutions found', { length: filteredCount }), _jsx(InfoOutlined, { fontSize: 'small' })] }) }));
    return (_jsx("div", { className: styles.filteredCount, children: t('{{length}} Solutions found', { length: filteredCount }) }));
}
