import Http from './http';
import { appendUrlParams } from '../utils/appendUrlParams';
export const getProjects = (payload) => {
    return Http.get('/projects', payload);
};
export const getProjectsID = (id) => {
    return Http.get(`/projects/${id}`);
};
export const setProjectsID = (id, payload) => {
    return Http.put(`/projects/${id}`, payload);
};
export const getArticleNumbers = (id) => {
    return Http.get(`/articles/${id}`);
};
export const getArticleDetails = (id, length, height) => {
    const baseUrl = `/article/${id}`;
    const params = { length, height };
    const fullUrl = appendUrlParams(baseUrl, params);
    return Http.get(fullUrl);
};
export const getArticleDescriptionDetails = (id, length, height) => {
    const baseUrl = `/article/${id}?name=true`;
    const params = { length, height };
    const fullUrl = appendUrlParams(baseUrl, params);
    return Http.get(fullUrl);
};
export const getArticleDescription = (id) => {
    return Http.get(`/articles/${id}?name=true`);
};
export const mergeSolution = (id, payload) => {
    return Http.post(`/projects/${id}/merge`, payload);
};
export const assignSolution = (id, payload) => {
    return Http.post(`/projects/${id}/assign`, payload);
};
export const getProcessedSolution = (id) => {
    return Http.get(`/projects/${id}/solutions`);
};
export const getSolutionDetails = (id, solutionId) => {
    return Http.get(`/projects/${id}/solutions/${solutionId}`);
};
export const updateSolutionDetails = (id, solutionId, payload) => {
    return Http.put(`/projects/${id}/solutions/${solutionId}`, payload);
};
export const unMerge = (id, solutionId) => {
    return Http.post(`/projects/${id}/unmerge/${solutionId}`);
};
export const unAssign = (id, solutionId) => {
    return Http.post(`/projects/${id}/unassign/${solutionId}`);
};
