import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import { Button, Text } from '../index';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
export const AlertDialog = ({ open, handleClose, details, title, buttonText, onClick, hasSecondaryActionButton = true }) => {
    const { t } = useTranslation();
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, { maxWidth: 'xs', fullWidth: true, open: open, onClose: handleClose, "aria-labelledby": "alert-dialog-title", "aria-describedby": "alert-dialog-description", children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: _jsx(Text, { label: title, variant: "h6" }) }), _jsx(DialogContent, { children: _jsx(DialogContentText, { id: "alert-dialog-description", children: _jsx(Text, { label: details, variant: "body2" }) }) }), _jsxs(DialogActions, { children: [hasSecondaryActionButton && (_jsx(Button, { onClick: handleClose, variant: "outlined", children: t('Cancel') })), _jsx(Button, { onClick: onClick, variant: "contained", autoFocus: true, children: buttonText })] })] }) }));
};
export const ContentDialog = ({ open, setOpen, children, title, buttonText, onClick, rest }) => {
    const handleClose = () => {
        setOpen(false);
    };
    const { t } = useTranslation();
    return (_jsx(React.Fragment, { children: _jsxs(Dialog, Object.assign({ open: open, maxWidth: 'md', fullWidth: true, onClose: handleClose }, rest, { children: [_jsx(DialogTitle, { id: "alert-dialog-title", children: _jsx(Text, { label: title, variant: "h6" }) }), children, _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, variant: "outlined", children: t('Cancel') }), buttonText && (_jsx(Button, { onClick: onClick, variant: "contained", autoFocus: true, children: buttonText }))] })] })) }));
};
