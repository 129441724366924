import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { FilterSearch } from '@shared-app/ui';
import ResetIcon from '@shared-app/assets/icons/reset.svg';
import palette from '@theme/palette';
import { fonts } from '@theme/font';
import { ValColumnEnum } from '@shared-app/lib/types';
import { ColorButton } from '@pages/Estimation/Overview/ui/FilterDataset/ColorButton';
import styles from '../styles.module.scss';
import { FilterSelect } from '@shared-app/ui/FilterSelect';
export function Header({ countryArray, customerQuery, datesArray, handleCustomerChange, handleDataChange, handleResetAll, locationArray, projectCategoryArray, selectedCountry, selectedDate, systemArray, selectedSystem, selectedLocation, selectedProjectCategory, selectedStatus, statusArray }) {
    const { t } = useTranslation();
    return (_jsxs(Stack, { display: 'flex', borderRadius: '6px', pt: '8px', pb: '8px', pl: '10px', my: '24px', bgcolor: palette.neutral[97], alignItems: 'center', flexDirection: 'row', width: '100%', children: [_jsx(Stack, { width: 200, mr: '12px', children: _jsx(FilterSelect, { data: datesArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.date), label: t('Year'), selectedValues: selectedDate }) }), _jsx(Stack, { width: selectedCountry.length === 1 ? 300 : 250, mr: '12px', children: _jsx(FilterSelect, { data: countryArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.office_country), label: t('Office country'), selectedValues: selectedCountry }) }), _jsx(Stack, { width: selectedLocation.length === 1 ? 350 : 250, mr: '12px', children: _jsx(FilterSelect, { data: locationArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.office_location), label: t('Office location'), selectedValues: selectedLocation }) }), _jsx(Stack, { width: selectedLocation.length === 1 ? 350 : 250, mr: '12px', children: _jsx(FilterSelect, { data: systemArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.system), label: t('System'), selectedValues: selectedSystem }) }), _jsx(Stack, { width: 290, mr: '12px', children: _jsx(FilterSelect, { data: projectCategoryArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.project_type_1), label: t('Project Category'), selectedValues: selectedProjectCategory }) }), _jsx(Stack, { width: 200, mr: '12px', children: _jsx(FilterSelect, { data: statusArray, handleDataChange: values => handleDataChange(values, ValColumnEnum.status), label: t('Status'), selectedValues: selectedStatus }) }), _jsx(Stack, { width: 230, mr: '12px', children: _jsx(FilterSearch, { searchQuery: customerQuery, handleChange: val => {
                        handleCustomerChange(val);
                    }, placeholder: t('Search for Customer name'), className: styles.searchInput }) }), _jsx(Stack, { width: 157, children: _jsx(ColorButton, { disableElevation: true, sx: Object.assign({}, fonts.typography.body), variant: "contained", startIcon: _jsx(ResetIcon, {}), onClick: handleResetAll, children: t('Reset All') }) })] }));
}
