import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { UnfoldMoreRounded } from '@shared-app/ui/icons';
import palette from '@theme/palette';
import { useTranslation } from 'react-i18next';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 'auto'
        }
    }
};
export const materialCheckboxStyle = {
    '&.Mui-checked': {
        color: palette.neutral[2]
    },
    '&.MuiCheckbox-indeterminate': {
        color: palette.neutral[2],
        '& .MuiSvgIcon-root': {
            backgroundColor: 'transparent'
        }
    }
};
export const materialMenuStyle = {
    '&.Mui-selected ': {
        background: palette.neutral[98]
    }
};
export const MultipleSelectCheckmarks = ({ values, renderValue, data, handleChange, IconComponent, isDisabled = false }) => {
    const { t } = useTranslation();
    const handleSelectChange = (event) => {
        const { target: { value } } = event;
        if (value.includes('all')) {
            handleChange(data);
        }
        else if (value.includes('deselect')) {
            handleChange([]);
        }
        else {
            handleChange(typeof value === 'string' ? value.split(',') : value);
        }
    };
    const isAllSelected = values.length === data.length;
    return (_jsxs(Select, { multiple: true, value: values, onChange: handleSelectChange, MenuProps: MenuProps, IconComponent: IconComponent ? IconComponent : UnfoldMoreRounded, disabled: isDisabled, input: _jsx(OutlinedInput, { sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                    '&:hover': {
                        borderColor: '#B3B3B3'
                    }
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#B3B3B3'
                },
                '&.Mui-active .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#B3B3B3'
                },
                '& legend': {
                    display: 'none'
                }
            }, size: 'small' }), renderValue: selected => renderValue ? _jsx("div", { style: { paddingBottom: '2px' }, children: renderValue(selected) }) : null, displayEmpty: true, variant: "outlined", sx: {
            width: '100%',
            '& .MuiSelect-icon': {
                color: '#1890FF',
                fontSize: '24px'
            }
        }, children: [!isAllSelected && (_jsxs(MenuItem, { value: 'all', sx: materialMenuStyle, children: [_jsx(Checkbox, { checked: isAllSelected, sx: materialCheckboxStyle }), _jsx(ListItemText, { sx: { textTransform: 'capitalize' }, primary: t('Select All') })] })), isAllSelected && (_jsxs(MenuItem, { value: 'deselect', sx: materialMenuStyle, children: [_jsx(Checkbox, { checked: !isAllSelected, sx: materialCheckboxStyle }), _jsx(ListItemText, { sx: { textTransform: 'capitalize' }, primary: t('Deselect All') })] })), data.map((name) => (_jsxs(MenuItem, { value: name, sx: materialMenuStyle, children: [_jsx(Checkbox, { checked: (values === null || values === void 0 ? void 0 : values.indexOf(name)) > -1, sx: materialCheckboxStyle }), _jsx(ListItemText, { sx: { textTransform: 'capitalize' }, primary: name })] }, name)))] }));
};
