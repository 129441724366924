// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.j3UToLFKcOTY7aacaIgm{
    display: flex;
    justify-content : space-between;
    padding: var(--spacing-10) 18px var(--spacing-10) 18px;
    
}

.utZDwHUJfpPyAwWVAwHV{
    margin-right: 10
  }`, "",{"version":3,"sources":["webpack://./src/pages/Validation/Hierarchy/ui/ControlMenu/ProjectTabs/unProcessedTab.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,+BAA+B;IAC/B,sDAAsD;;AAE1D;;AAEA;IACI;EACF","sourcesContent":[".menuItem{\n    display: flex;\n    justify-content : space-between;\n    padding: var(--spacing-10) 18px var(--spacing-10) 18px;\n    \n}\n\n.assing_solution{\n    margin-right: 10\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItem": `j3UToLFKcOTY7aacaIgm`,
	"assing_solution": `utZDwHUJfpPyAwWVAwHV`
};
export default ___CSS_LOADER_EXPORT___;
