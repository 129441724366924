var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from 'react';
import { DxfViewer, BottomBarPlugin, MeasurementPlugin, ToolbarMenuId, DxfViewerToolbarPlugin, ViewerEvent } from '@pattern-x/gemini-viewer-threejs';
import { IconButton } from '@mui/material';
import * as THREE from 'three';
import { Refresh } from '@shared-app/ui/icons';
import palette from '@theme/palette';
import './MyDxfViewer.module.scss';
const MyDxfViewer = ({ url, box_coordinates }) => {
    const containerRef = useRef(null);
    const [viewer, setViewer] = useState(null);
    useEffect(() => {
        const initializeViewer = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                if (url) {
                    const viewerCfg = {
                        containerId: 'myCanvas',
                        language: 'en',
                        enableSpinner: true,
                        enableLayoutBar: true
                    };
                    const modelCfg = {
                        src: url
                    };
                    const dxfViewer = new DxfViewer(viewerCfg);
                    new BottomBarPlugin(dxfViewer);
                    new MeasurementPlugin(dxfViewer);
                    const menuConfig = {
                        [ToolbarMenuId.HomeView]: { visible: false },
                        [ToolbarMenuId.OrthoMode]: { visible: false },
                        [ToolbarMenuId.Markup]: { visible: false },
                        [ToolbarMenuId.Section]: { visible: false },
                        [ToolbarMenuId.BimTree]: { visible: false },
                        [ToolbarMenuId.Viewpoint]: { visible: false },
                        [ToolbarMenuId.Annotation]: { visible: false },
                        [ToolbarMenuId.Property]: { visible: false },
                        [ToolbarMenuId.Settings]: { visible: false },
                        [ToolbarMenuId.Compared]: { visible: false },
                        [ToolbarMenuId.QuitCompare]: { visible: false },
                        [ToolbarMenuId.Fullscreen]: { visible: false },
                        [ToolbarMenuId.SceneClear]: { visible: false },
                        [ToolbarMenuId.Layers]: { visible: false },
                        [ToolbarMenuId.ZoomToRectangle]: { visible: false },
                        [ToolbarMenuId.Screenshot]: { visible: false },
                        [ToolbarMenuId.Measure]: { visible: true },
                        [ToolbarMenuId.MarkupVisibility]: { visible: false },
                        [ToolbarMenuId.MarkupText]: { visible: false },
                        [ToolbarMenuId.MarkupCloudLine]: { visible: false },
                        [ToolbarMenuId.MarkupCircle]: { visible: false },
                        [ToolbarMenuId.MarkupCloudRect]: { visible: false },
                        [ToolbarMenuId.MarkupRect]: { visible: false },
                        [ToolbarMenuId.MarkupStrokeStyle]: { visible: false },
                        [ToolbarMenuId.MarkupClear]: { visible: false },
                        [ToolbarMenuId.MarkupQuit]: { visible: false }
                    };
                    new DxfViewerToolbarPlugin(dxfViewer, { menuConfig });
                    const fontFile = '/json/simplex.shx';
                    yield dxfViewer.setFont([fontFile]);
                    dxfViewer.enableRender();
                    dxfViewer.enableZoom(true);
                    dxfViewer.enableControl(true);
                    if (!viewer) {
                        dxfViewer.loadModel(modelCfg);
                        if (containerRef.current) {
                            //@ts-ignore
                            dxfViewer.containerId = containerRef.current.id;
                        }
                        dxfViewer.addEventListener(ViewerEvent.ModelLoaded, () => {
                            // After model is loaded, adjust camera position
                            const topLeftX = box_coordinates[0];
                            const topLeftY = box_coordinates[1];
                            const contentWidth = box_coordinates[2];
                            const contentHeight = box_coordinates[3];
                            const minCorner = new THREE.Vector3(topLeftX, topLeftY - contentHeight, 0);
                            const maxCorner = new THREE.Vector3(topLeftX + contentWidth, topLeftY, 0);
                            const bbox = new THREE.Box3(minCorner, maxCorner);
                            dxfViewer.zoomToBBox(bbox);
                        });
                        setViewer(dxfViewer);
                    }
                }
            }
            catch (error) {
                console.error('Error initializing viewer:', error);
            }
        });
        initializeViewer();
    }, [box_coordinates, url, viewer]);
    const handleReset = () => {
        if (viewer) {
            const topLeftX = box_coordinates[0];
            const topLeftY = box_coordinates[1];
            const contentWidth = box_coordinates[2];
            const contentHeight = box_coordinates[3];
            const minCorner = new THREE.Vector3(topLeftX, topLeftY - contentHeight, 0);
            const maxCorner = new THREE.Vector3(topLeftX + contentWidth, topLeftY, 0);
            const bbox = new THREE.Box3(minCorner, maxCorner);
            viewer.zoomToBBox(bbox);
            // viewer.goToHomeView();
        }
    };
    return (_jsxs("div", { style: { height: '100%', width: '100%', position: 'relative' }, children: [_jsx("div", { className: `toolbar`, children: _jsx(IconButton, { sx: {
                        mt: '120px',
                        '&.MuiButtonBase-root:hover': {
                            bgcolor: palette.common.white
                        },
                        boxShadow: '0px 1px 18px 0px #0000001F',
                        background: '#fff',
                        left: '97%',
                        alignItems: 'center',
                        borderRadius: '50%'
                    }, onClick: handleReset, children: _jsx(Refresh, { color: "primary" }) }) }), _jsx("div", { id: "myCanvas", style: { width: '100%', height: '100%', background: 'black', overflow: 'hidden' }, ref: containerRef })] }));
};
export default MyDxfViewer;
