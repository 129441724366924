import { jsx as _jsx } from "react/jsx-runtime";
import { useFilteredBoqTableData } from '@estimation/entities/estimationSolution';
import { BillQuantitiesColumns } from '@shared-app/lib/constants';
import styles from './styles.module.scss';
import { BOQEnum } from '@shared-app/lib/types';
import { Grid } from '@mui/material';
export function TableBody() {
    const { filteredTableData } = useFilteredBoqTableData();
    return filteredTableData.map((billRowItem, idx) => (_jsx(Grid, { container: true, justifyContent: 'space-between', className: styles.bodyContainer, children: BillQuantitiesColumns.map(column => {
            const value = billRowItem[column.id];
            return (_jsx(Grid, { item: true, xs: column.id === BOQEnum.art_descript_eng ? 4 : 2, children: _jsx("div", { style: {
                        display: 'flex',
                        justifyContent: column.justifyItems
                    }, children: _jsx("span", { className: styles.labelItem, children: column.format ? column.format(value, 0) : value }) }) }, column.id));
        }) }, idx)));
}
