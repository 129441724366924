export const filterSelectedItems = (data) => {
    return data
        .map(node => {
        if (node.childrenNodes.length > 0) {
            node.childrenNodes = filterSelectedItems(node.childrenNodes);
        }
        return node.checked || node.indeterminate || node.childrenNodes.length > 0 ? node : null;
    })
        .filter(node => node !== null);
};
