import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@shared/ui/Button';
import { useState } from 'react';
import { ProjectFilterModal } from './ProjectFilterModal';
import { ChevronRight } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
import palette from '@theme/palette';
import { useTranslation } from 'react-i18next';
export function ProjectFilterButton() {
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { color: "primary", onClick: () => {
                    setFilterModalVisible(true);
                }, endIcon: _jsx(ChevronRight, { height: '20px', width: '20px', sx: { color: palette.secondary.main } }), className: styles.filterButton, children: t('Project Category') }), _jsx(ProjectFilterModal, { visible: filterModalVisible, handleClose: () => {
                    setFilterModalVisible(false);
                } })] }));
}
