import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { SolutionView } from '@shared-app/lib/types';
import { parameters, elevationParameter } from '@shared-app/lib/constants';
import { ParametersEnum } from '../constants';
import apis from '@shared-app/lib/apis';
import { getSingleSidedValue } from '../utils/getSingleSidedValue';
import { hasParamError } from '../utils/hasParamError';
import { getDFXUrl } from '../utils/getDFXSUrl';
const getInitialState = () => ({
    projectSolution: undefined,
    bom: [],
    parameters: parameters.map(param => (Object.assign({}, param))),
    projectData: undefined,
    dxfURL: ''
});
export const useSolutionStore = create()(devtools((set, get) => (Object.assign(Object.assign({}, getInitialState()), { reset: () => {
        set(getInitialState());
    }, fetchSolution: (projectId, solutionId) => {
        Promise.all([
            apis.projects.getProjectsID(projectId),
            apis.projects.getSolutionDetails(projectId, solutionId)
        ])
            .then(([projectData, projectSolution]) => {
            const paraState = (projectSolution.solution.view === SolutionView.side ||
                projectSolution.solution.view === SolutionView.section
                ? elevationParameter
                : parameters).map(param => {
                var _a;
                return Object.assign(Object.assign({}, param), { value: (_a = projectSolution.solution) === null || _a === void 0 ? void 0 : _a.parameters[param === null || param === void 0 ? void 0 : param.name] });
            });
            set({
                projectData,
                projectSolution,
                bom: projectSolution.solution.bom,
                parameters: paraState
            });
            if (projectData.dxf) {
                return getDFXUrl(projectId, projectData.dxf);
            }
        })
            .then(dxf => {
            return set({ dxfURL: dxf });
        });
    }, updateSolutionName: (name) => {
        const { projectSolution } = get();
        set({ projectSolution: Object.assign(Object.assign({}, projectSolution), { solution: Object.assign(Object.assign({}, projectSolution.solution), { name }) }) });
    }, updateParamater: (name, newValue) => {
        const { parameters } = get();
        let updatedParameters = parameters.map(param => {
            if (param.name === name) {
                return Object.assign(Object.assign({}, param), { value: newValue });
            }
            return param;
        });
        if ([ParametersEnum.double_sided, ParametersEnum.segments].includes(name)) {
            updatedParameters = updatedParameters.map(param => {
                if (param.name === ParametersEnum.single_sided) {
                    return Object.assign(Object.assign({}, param), { value: getSingleSidedValue(updatedParameters) });
                }
                return param;
            });
        }
        set({ parameters: updatedParameters });
    }, handleParameterReset: () => {
        const { projectSolution } = get();
        const updatedParameters = (projectSolution.solution.view === SolutionView.side ||
            projectSolution.solution.view === SolutionView.section
            ? elevationParameter
            : parameters).map(param => {
            var _a;
            return Object.assign(Object.assign({}, param), { value: (_a = projectSolution.solution) === null || _a === void 0 ? void 0 : _a.parameters[param === null || param === void 0 ? void 0 : param.name] });
        });
        set({ parameters: updatedParameters, bom: projectSolution.solution.bom });
    }, updateBom: (bom) => {
        set({ bom });
    } }))));
export const isSolutionFetched = () => {
    return Boolean(useSolutionStore(state => state.projectData && state.projectSolution));
};
export const isDfxFetched = () => {
    return Boolean(useSolutionStore(state => state.projectData && state.dxfURL));
};
export const areSolutionParmatersValid = () => {
    const parameters = useSolutionStore(state => state.parameters);
    return parameters.every(parameter => !hasParamError(parameter, parameters));
};
export const areChangesMade = () => {
    const { parameters, bom, projectSolution } = useSolutionStore(state => ({
        parameters: state.parameters,
        bom: state.bom,
        projectSolution: state.projectSolution
    }));
    if (projectSolution) {
        const areParametersChanged = parameters.some(param => {
            return param.value !== projectSolution.solution.parameters[param.name];
        });
        const isTableDataChanged = JSON.stringify(bom) !== JSON.stringify(projectSolution.solution.bom);
        return areParametersChanged || isTableDataChanged;
    }
    return false;
};
