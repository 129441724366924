import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ExpandMore } from '@shared-app/ui/icons';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Checkbox, Grid, Stack } from '@mui/material';
import { materialCheckboxStyle } from '@shared-app/ui';
import { removeIsExtraField } from '../lib/utils/removeIsExtraField';
import { filterSelectedItems } from '../lib/utils/filterSelectedItems';
import styles from './styles.module.scss';
import classNames from 'classnames';
const transform = (data, parent) => {
    return data.map(item => {
        const node = {
            name: item.name,
            checked: true,
            indeterminate: false,
            childrenNodes: [],
            parent: parent
        };
        if (item.children.length > 0) {
            node.childrenNodes = transform(item.children, node);
        }
        return node;
    });
};
const updateAncestors = (node) => {
    if (!node.parent) {
        return;
    }
    const parent = node.parent;
    const allChecked = parent.childrenNodes.every(child => child.checked);
    const noneChecked = parent.childrenNodes.every(child => !child.checked && !child.indeterminate);
    const someChecked = parent.childrenNodes.some(child => child.checked || child.indeterminate);
    if (allChecked) {
        parent.checked = true;
        parent.indeterminate = false;
    }
    else if (noneChecked) {
        parent.checked = false;
        parent.indeterminate = false;
    }
    else if (someChecked) {
        parent.checked = false;
        parent.indeterminate = true;
    }
    updateAncestors(parent);
};
const toggleDescendants = (node, checked) => {
    node.checked = checked;
    node.indeterminate = false;
    node.childrenNodes.forEach(child => toggleDescendants(child, checked));
};
const findNode = (nodes, name) => {
    for (const node of nodes) {
        if (node.name === name) {
            return node;
        }
        const found = findNode(node.childrenNodes, name);
        if (found) {
            return found;
        }
    }
    return undefined;
};
const syncNodesWithSelectedData = (nodes, selectedData) => {
    const syncNode = (node, selected) => {
        const newNode = Object.assign(Object.assign({}, node), { checked: !!selected, indeterminate: false, childrenNodes: node.childrenNodes.map(childNode => {
                const selectedChild = selected === null || selected === void 0 ? void 0 : selected.children.find(c => c.name === childNode.name);
                return syncNode(childNode, selectedChild);
            }) });
        // Update parent reference for child nodes
        newNode.childrenNodes.forEach(childNode => {
            childNode.parent = newNode;
        });
        return newNode;
    };
    const updatedNodes = nodes.map(node => {
        const selectedNode = selectedData.find(s => s.name === node.name);
        return syncNode(node, selectedNode);
    });
    // Update ancestors after all nodes have been synced
    const updateAncestorsForAll = (nodes) => {
        nodes.forEach(node => {
            updateAncestors(node);
            updateAncestorsForAll(node.childrenNodes);
        });
    };
    updateAncestorsForAll(updatedNodes);
    return updatedNodes;
};
export const ProjectFilters = ({ data, selectedData, setSelectedData }) => {
    const [nodes, setNodes] = useState([]);
    useEffect(() => {
        if (selectedData.length) {
            const initialNodes = transform(data, null);
            const selectedNodes = syncNodesWithSelectedData(initialNodes, selectedData);
            setNodes(selectedNodes);
        }
    }, [selectedData]);
    const handleBoxChecked = (e) => {
        e.stopPropagation();
        const checked = e.currentTarget.checked;
        const label = e.currentTarget.value;
        const node = findNode(nodes, label);
        if (node) {
            node.checked = checked;
            node.indeterminate = false;
            toggleDescendants(node, checked);
            updateAncestors(node);
            setNodes(cloneDeep(nodes));
            const filteredData = filterSelectedItems(cloneDeep(nodes));
            const cleanedData = removeIsExtraField(filteredData);
            setSelectedData(cleanedData);
        }
    };
    return (_jsx(Grid, { container: true, spacing: '24px', children: nodes.map(node => (_jsxs(Grid, { item: true, xs: 3, children: [_jsxs(Stack, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', className: styles.tileHeader, children: [node.name, _jsx(Checkbox, { sx: materialCheckboxStyle, name: node.name, value: node.name, checked: node.checked, indeterminate: node.indeterminate, onChange: handleBoxChecked })] }), _jsx(NestedCheckboxHelper, { nodes: node.childrenNodes, onBoxChecked: handleBoxChecked })] }))) }));
};
const NodeAccordion = ({ node, onBoxChecked }) => {
    if (node.childrenNodes.length) {
        return (_jsxs(Accordion, { style: { border: 'none', boxShadow: 'none' }, sx: { '&::before': { display: 'none' } }, children: [_jsx(AccordionSummary, { expandIcon: _jsxs(Stack, { flexDirection: 'row', alignItems: 'center', children: [_jsx(Stack, { flexDirection: 'row', alignItems: 'center', onClick: e => e.stopPropagation(), children: node.parent !== null && (_jsx(Checkbox, { sx: materialCheckboxStyle, name: node.name, value: node.name, checked: node.checked, indeterminate: node.indeterminate, onChange: onBoxChecked })) }), node.childrenNodes && node.childrenNodes.length > 0 && (_jsx(ExpandMore, { className: classNames(styles.expandIcon, 'expanded') }))] }), "aria-controls": "panel1a-content", id: "panel1a-header", sx: {
                        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded ': {
                            transform: 'rotate(0deg)',
                            '& .expanded': {
                                transform: 'rotate(180deg)'
                            }
                        },
                        '&.MuiAccordionSummary-root.Mui-expanded': {
                            background: 'transparent',
                            borderBottom: '1px solid #CCC'
                        }
                    }, children: _jsx(Stack, { flexDirection: 'row', alignItems: 'center', className: styles.accordianHeader, children: node.name }) }), _jsx(AccordionDetails, { children: node.childrenNodes.length > 0 && (_jsx(NestedCheckboxHelper, { nodes: node.childrenNodes, onBoxChecked: onBoxChecked })) })] }, node.name));
    }
    else
        return (_jsxs(Stack, { flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', className: styles.childHeader, children: [node.name, _jsx(Checkbox, { sx: materialCheckboxStyle, name: node.name, value: node.name, checked: node.checked, indeterminate: node.indeterminate, onChange: onBoxChecked })] }));
};
const NestedCheckboxHelper = ({ nodes, onBoxChecked }) => {
    return nodes.map(node => {
        return _jsx(NodeAccordion, { node: node, onBoxChecked: onBoxChecked });
    });
};
