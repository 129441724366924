var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@shared-app/ui/icons';
import { useSolutionData } from '@estimation/entities/estimationSolution';
import styles from './styles.module.scss';
import palette from '@theme/palette';
import { useTranslation } from 'react-i18next';
import { Button } from '@shared/ui/Button';
import MyDxfViewer from '@shared-app/ui/DxfViewer/MyDxfViewer';
import { getDFXS3Backend } from '@entities/solution';
export function ShowSolutionDxf() {
    const [dfxUrl, setDfxUrl] = useState(undefined);
    const { fileName, dxfPath, isLoading, boxСoordinates, solutionId } = useSolutionData();
    const { t } = useTranslation();
    const [isSolutionModalOpen, setIsSolutionModalOpen] = useState(false);
    const handleClose = () => {
        setIsSolutionModalOpen(false);
    };
    useEffect(() => {
        const fetchDfxUrl = () => __awaiter(this, void 0, void 0, function* () {
            if (!dxfPath || !solutionId) {
                return;
            }
            const url = yield getDFXS3Backend(dxfPath, solutionId);
            setDfxUrl(url);
        });
        fetchDfxUrl();
        return () => {
            setDfxUrl(undefined);
        };
    }, [dxfPath, solutionId]);
    return (_jsxs(_Fragment, { children: [_jsx(Button, { onClick: () => setIsSolutionModalOpen(true), variant: `outlined`, size: `small`, className: styles.button, disabled: isLoading, children: t('Open Dxf') }), _jsx(Modal, { onClose: handleClose, open: isSolutionModalOpen, sx: {
                    width: '90%',
                    top: '5%',
                    left: '5%',
                    height: 'calc(100vh - 100px)',
                    overflow: 'hidden'
                }, children: _jsxs(Box, { p: 2, bgcolor: palette.common.white, sx: { borderRadius: 2 }, children: [_jsx(Box, { mb: 2, children: _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '24px', children: [_jsxs(Typography, { fontSize: 22, fontWeight: 600, color: palette.neutral[10], children: [t('File Name'), ": ", _jsx("span", { style: { fontWeight: 400 }, children: fileName })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(Close, { sx: { color: palette.primary[50] } }) })] }) }), _jsx("div", { style: { width: '100%', height: 'calc(100vh - 240px)' }, children: _jsx(MyDxfViewer, { url: dfxUrl, box_coordinates: boxСoordinates }) })] }) })] }));
}
