import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Box, Typography, FormControl } from '@mui/material';
import { Field } from 'formik';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
export function InputField({ paramItem, handleChange, isSubmitting }) {
    const { t } = useTranslation();
    const { min, max, label, value } = paramItem;
    return (_jsxs(Box, { display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', children: [_jsxs(Stack, { children: [_jsx(Typography, { fontSize: 14, children: t(label) }), _jsxs(Typography, { fontSize: 12, children: [t('from'), " ", min, " ", t('to'), " ", max] })] }), _jsx(FormControl, { variant: "outlined", children: _jsx(Field, { disabled: isSubmitting, name: name, placeholder: t('not set'), as: InputNumber, type: "number", value: value, min: min, max: max, onChange: (value) => handleChange(value) }) })] }));
}
