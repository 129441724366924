import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useAnchorElement } from '@shared-app/hooks';
import { Box, FormControl, Menu, Typography, MenuItem } from '@mui/material';
import { languages } from './constants/languages';
import { LanguageOutlined } from '@shared-app/ui/icons';
import { useEffect } from 'react';
import styles from './styles.module.scss';
import { LanguagesEnum } from '@shared-app/lib/types';
export function LanguageSwitcher() {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const { anchor, clearAnchor, setAnchor } = useAnchorElement();
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        clearAnchor();
    };
    useEffect(() => {
        const defaultLanguage = languages.map(({ code }) => code).includes(currentLanguage)
            ? currentLanguage
            : LanguagesEnum.EN;
        i18n.changeLanguage(defaultLanguage);
    }, [currentLanguage]);
    return (_jsxs(Box, { className: styles.container, children: [_jsxs(Box, { className: styles.iconContainer, onClick: setAnchor, children: [_jsx(LanguageOutlined, { className: styles.icon }), _jsx(Typography, { className: styles.label, children: currentLanguage })] }), _jsx(FormControl, { children: _jsx(Menu, { anchorEl: anchor, open: Boolean(anchor), onClose: clearAnchor, children: languages.map(({ code, label }) => {
                        return (_jsx(MenuItem, { className: styles.item, onClick: () => changeLanguage(code), children: label }, code));
                    }) }) })] }));
}
