import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useSolutionStore } from '@entities/solution';
import palette from '@theme/palette';
import { Button } from '@shared-app/ui';
import { Download } from '@shared-app/ui/icons';
import { Box } from '@mui/material';
import { handleDownload } from '@shared-app/lib/utils';
import { VisibleItem } from '../../../lib/types/VisibleItem';
export function DownLoadFooter({ solutionImage, visibleItem }) {
    const { t } = useTranslation();
    const { parameters } = useSolutionStore();
    const handleDownloadParameterCSV = () => {
        const csvContent = 'data:text/csv;charset=utf-8,' +
            parameters
                .map(parameter => {
                return `${t(parameter.label)},${parameter.value}`;
            })
                .join('\n');
        const encodedUri = encodeURI(csvContent);
        handleDownload(encodedUri, 'parameters.csv');
    };
    const downloadImage = () => {
        handleDownload(solutionImage, 'image.jpg');
    };
    const handleDownloadContent = () => {
        if (visibleItem == VisibleItem.Image)
            return downloadImage();
        return handleDownloadParameterCSV();
    };
    return (_jsx(Box, { display: 'flex', sx: { border: '1px solid #E0E0E0' }, height: 74, bgcolor: palette.common.white, width: '100%', alignItems: 'center', justifyContent: 'flex-end', children: _jsx(Button, { onClick: handleDownloadContent, variant: "text", startIcon: _jsx(Download, {}), children: t('Download') }) }));
}
