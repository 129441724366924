export const ROUTES = {
    VALIDATION: {
        INDEX: '/validation',
        HIERARCHY: '/validation/projects/:id',
        VALIDATE: '/validation/projects/:projectId/validate/:solutionId'
    },
    ESTIMATION: {
        INDEX: '/',
        LEGACY: '/estimationLegacy'
    }
};
