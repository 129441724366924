var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from 'react';
import apis from '@shared-app/lib/apis';
import { db } from '@shared-app/lib/config/db';
import { getTopViewLayouts } from '../utils/getTopViewLayouts';
export function useProjectData(projectId, dispatch, handleSetDefaultView) {
    const [projectData, setProjectData] = useState();
    const [topViewLayouts, setTopViewLayouts] = useState([]);
    const [reload, setReload] = useState(false);
    const handleReloadApiData = () => {
        setReload(reload => !reload);
    };
    useEffect(() => {
        if (projectId) {
            apis.projects
                .getProjectsID(projectId)
                .then(res => {
                setProjectData(res);
                setTopViewLayouts(getTopViewLayouts(res));
                if (res && res.results && res.results.layouts.length > 0) {
                    handleSetDefaultView(res);
                }
            })
                .catch(e => console.log('ee', e));
        }
    }, [dispatch, projectId, reload]);
    useEffect(() => {
        const getDFXS3 = () => {
            apis.dataset
                .getDFX(projectData.dxf)
                .then((resDxf) => __awaiter(this, void 0, void 0, function* () {
                try {
                    const id = yield db.table('myObject').add({
                        id: projectData.id,
                        data: resDxf
                    });
                    console.log(`Data added to IndexedDB with ID: ${id}`);
                }
                catch (error) {
                    console.error('Error adding file to database', error);
                }
            }))
                .finally(() => { });
        };
        const fetchAllData = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const allData = yield db.table('myObject').toArray();
                if (allData.length === 0) {
                    getDFXS3();
                }
                else if (allData[0].id !== (projectData === null || projectData === void 0 ? void 0 : projectData.id)) {
                    yield db.table('myObject').clear();
                    getDFXS3();
                }
            }
            catch (error) {
                console.error('Error fetching all data:', error);
            }
        });
        if (projectData === null || projectData === void 0 ? void 0 : projectData.dxf) {
            fetchAllData();
        }
    }, [projectData === null || projectData === void 0 ? void 0 : projectData.dxf]);
    return { projectData, handleReloadApiData, topViewLayouts };
}
