import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { BillQuantitiesColumns } from '@shared-app/lib/constants';
import { BOQEnum } from '@shared-app/lib/types';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useMetricSystem } from '@shared-app/hooks';
export function TableHeader() {
    const { t } = useTranslation();
    const { isImperial } = useMetricSystem();
    return (_jsx(Grid, { container: true, justifyContent: 'space-between', className: styles.container, children: BillQuantitiesColumns.map((column, idx) => (_jsx(Grid, { item: true, xs: column.id === BOQEnum.art_descript_eng ? 4 : 2, children: _jsx("div", { style: {
                    display: 'flex',
                    justifyContent: column.justifyItems
                }, children: _jsx("span", { className: styles.labelItem, children: t(isImperial ? column.labelImperial || column.label : column.label) }) }) }, idx))) }));
}
