import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Col, Row } from 'antd';
import { FilterDataset } from './FilterDataset';
import { NewSolution } from './NewSolution';
import { EstimationResult } from './EstimationResult';
import * as S from './styles';
import { useGetFilters } from '../../../../Estimation/entities/estimationSolution/lib/hooks/useGetFilters';
export function OverviewPage() {
    useGetFilters();
    return (_jsxs(S.Container, { children: [_jsxs(Row, { gutter: 20, children: [_jsx(Col, { span: 12, children: _jsx(FilterDataset, {}) }), _jsx(Col, { span: 12, children: _jsx(NewSolution, {}) })] }), _jsx(EstimationResult, {})] }));
}
