import { jsxs as _jsxs } from "react/jsx-runtime";
import { useFilteredBoqTableData } from '@estimation/entities/estimationSolution';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useMetricSystem } from '@shared-app/hooks';
export function TableFooter() {
    const { totalWeight } = useFilteredBoqTableData();
    const { t } = useTranslation();
    const { isImperial } = useMetricSystem();
    return (_jsxs("div", { className: styles.footerContainer, children: [_jsxs("span", { children: [" ", t('Total Solution Weight')] }), _jsxs("span", { children: [' ', totalWeight.toFixed(2), " ", isImperial ? 'lb' : 'kg'] })] }));
}
