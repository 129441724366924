import { Button, Menu } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export const Layout = styled.div `
  height: 100vh;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  z-index: 1;
`;
export const LeftContent = styled.div `
  align-items: center;
  display: flex;
`;
export const RightContent = styled.div `
  align-items: center;
  display: flex;
  gap: var(--spacing-24);
`;
export const BreadCrumParagraph = styled.span `
  color: ${props => props.theme.palette.common.white};
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
  &:hover {
    color: #2196f3;
  }
`;
export const BreadCrumDivider = styled.span `
  color: ${props => props.theme.palette.common.white};
  cursor: pointer;
  font-size: 12px;
  line-height: 22px;
`;
export const BreadCrum = styled.div `
  font-size: 12px;
  background-color: ${props => props.theme.palette.neutral[20]};
  padding: 4px 16px 4px 16px;
  margin-left: 30px;
  line-height: 22px;
  height: 30px;
  border-radius: 6px;
`;
export const Header = styled.div `
  position: sticky;
  top: 0;
  height: 80px;
  padding: 16px 49px 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.palette.neutral[10]};
  z-index: 10;
`;
export const Brand = styled(Link) `
  display: flex;
  align-items: center;
  color: ${props => props.theme.palette.common.white};
  font-family: Poppins, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 200% */
  text-decoration: none;

  svg {
    margin-right: 24px;
    width: 42px;
    height: 41px;
  }
`;
export const Title = styled.span `
  margin-left: 10px;
  color: ${props => props.theme.palette.primary[70]};
`;
export const Profile = styled.div `
  width: 389px;
  margin: -12px;
  padding: 16px 8px;

  .ant-divider {
    width: calc(100% + 24px);
    margin: 23px -12px 17px;
  }
`;
export const Trigger = styled(Button) `
  && {
    display: flex;
    padding: 4px 16px 4px 4px;
    align-items: center;
    gap: 10px;
    border-radius: 50px;
    color: ${props => props.theme.palette.neutral[60]};
    border-color: ${props => props.theme.palette.neutral[60]};
    height: 56px;

    &:not(:disabled):hover {
      color: ${props => props.theme.palette.neutral[60]} !important;
    }

    &:active {
      svg {
        transform: translateY(5px);
      }
    }

    svg {
      transition-duration: 150ms;
    }
  }
`;
export const Avatar = styled.div `
  width: 46px;
  height: 46px;
  border-radius: 100%;
  background-color: white;
  color: ${props => props.theme.palette.common.black};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export const Name = styled.span `
  color: ${props => props.theme.palette.neutral[30]}
  text-align: center;

  /* Subtitle 1 */
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
`;
export const Role = styled(Name) `
  color: ${props => props.theme.palette.neutral[60]};
`;
export const ProfileMenu = styled(Menu) `
  border-inline-end: none !important;

  .ant-menu-item {
    margin: 0;
    padding: 12px 16px;
    display: flex;
    width: unset;
    height: fit-content;

    &.ant-menu-item-selected {
      background-color: transparent;
    }

    &:hover {
      background-color: ${props => props.theme.palette.neutral[97]} !important;
    }

    &:active {
      background-color: ${props => props.theme.palette.tertiary.main} !important;
    }

    &:last-of-type {
      .ant-menu-item-icon {
        color: ${props => props.theme.palette.neutral[70]};
      }
    }

    .ant-menu-item-icon {
      color: ${props => props.theme.palette.primary[55]};
    }

    .ant-menu-title-content {
      color: ${props => props.theme.palette.neutral[2]};
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 133.333% */
    }
  }
`;
export const Content = styled(PerfectScrollbar) `
  background-color: white;
  flex-grow: 1;
  // padding: 77px 48px 50px;
  height: calc((100vh - 70px));
  max-height: calc((100vh - 70px));
  .ps__rail-y {
    left: unset !important;
    right: 0;
  }
`;
