import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { solutionColumns as columns } from '@shared-app/lib/constants';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography, Chip } from '@mui/material';
import palette from '@theme/palette';
import { fonts } from '@theme/font';
import { useTranslation } from 'react-i18next';
import { useEstimationResult, useNewSolution } from '@estimation/entities/estimationSolution';
import styles from './styles.module.scss';
import { useMetricSystem } from '@shared-app/hooks';
import { calculateDeltaPercentage } from '../lib/utils/calculateDeltaPercentage';
import { WeightIcon } from './WeightIcon';
import { isWithinDeltaRange } from '../lib/utils/isWithInDeltaRange';
export function SolutionTable({ solutions }) {
    var _a;
    const { t } = useTranslation();
    const { setSelectedSolutionIdx, selectedSolutionIdx, isLoadMoreEnabled, estimationData } = useEstimationResult();
    const { inputValues } = useNewSolution();
    const { isImperial } = useMetricSystem();
    const totalPredictedWeight = ((_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.prediction) === null || _a === void 0 ? void 0 : _a.estimated_weight) || 0;
    return (_jsx(TableContainer, { className: `${styles.tableContainer} ${!isLoadMoreEnabled ? styles.expanded : ''}`, children: _jsxs(Table, { stickyHeader: true, children: [_jsx(TableHead, { children: _jsx(TableRow, { sx: {
                            '& th': {
                                fontSize: 14,
                                fontWeight: 400,
                                color: palette.primary[10],
                                backgroundColor: palette.neutral[97],
                                padding: '5px'
                            },
                            'th:nth-of-type(1)': {
                                borderRadius: '8px 0 0 0'
                            }
                        }, children: columns.map(column => (_jsx(TableCell, { align: column.align, sx: fonts.typography.th, children: t(isImperial ? column.labelImperial || column.label : column.label) }, column.id))) }) }), _jsx(TableBody, { children: solutions.length > 0 ? (solutions.map((row, index) => {
                        return (_jsx(TableRow, { hover: true, role: "checkbox", tabIndex: -1, onClick: () => {
                                setSelectedSolutionIdx(index);
                            }, sx: { cursor: 'pointer' }, children: columns.map(column => {
                                var _a;
                                const isWeightColumn = column.id === 'weight';
                                const value = row[column.id];
                                const isValueHightLighted = typeof (column === null || column === void 0 ? void 0 : column.delta) === 'number' && inputValues
                                    ? isWithinDeltaRange(value, (_a = inputValues.find(inputItem => inputItem.name === column.id)) === null || _a === void 0 ? void 0 : _a.value, column.delta)
                                    : false;
                                const isFileNameColumn = column.id === 'drawing_file_name' && (row === null || row === void 0 ? void 0 : row.external_url);
                                return (_jsx(TableCell, { style: {
                                        textTransform: 'none',
                                        padding: '12px'
                                    }, sx: Object.assign(Object.assign({}, fonts.typography.th), { backgroundColor: index === selectedSolutionIdx ? palette.primary[95] : '' }), align: column.align, onClick: () => {
                                        if (isFileNameColumn) {
                                            window.open(row.external_url, '_blank');
                                        }
                                    }, className: isFileNameColumn ? styles.underlinedName : '', children: _jsxs("div", { className: styles.tableCell, children: [_jsx(HighLightCell, { toHighlight: isValueHightLighted, value: column.format ? column.format(value, index) : value }), isWeightColumn && (_jsx(WeightCell, { totalWeight: totalPredictedWeight, weight: value }))] }) }, column.id));
                            }) }, index));
                    })) : (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: columns.length, align: "center", children: _jsx(Typography, { children: t('No projects match your criteria') }) }) })) })] }) }));
}
export function WeightCell({ weight, totalWeight }) {
    const delta = calculateDeltaPercentage(weight, totalWeight);
    return (_jsx(Chip, { className: styles.chipContainer, label: _jsxs("div", { className: styles.chip, children: [_jsx(WeightIcon, { delta: delta }), _jsxs("span", { className: styles.chipLabel, children: [Math.abs(delta), "%"] })] }) }));
}
export function HighLightCell({ value, toHighlight }) {
    if (!toHighlight) {
        return value;
    }
    else
        return _jsx(Chip, { className: styles.chipContainer, label: value });
}
