import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import palette from '@theme/palette';
import moment from 'moment';
import { Stack } from '@mui/material';
import { FiberManualRecord } from '@shared-app/ui/icons';
import { ValColumnEnum } from '@shared-app/lib/types';
export const ValidationColumns = [
    {
        label: 'Project title',
        id: ValColumnEnum.project_title,
        align: 'left',
        sortable: true,
        minWidth: 200,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Date',
        id: ValColumnEnum.date,
        align: 'right',
        sortable: true,
        minWidth: 200,
        color: palette.primary.text,
        fontWeight: 500,
        format: value => {
            return moment(value).format('DD-MM-YYYY');
        }
    },
    {
        label: 'Office country',
        id: ValColumnEnum.office_country,
        align: 'left',
        sortable: true,
        minWidth: 200,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Office location',
        id: ValColumnEnum.office_location,
        align: 'left',
        sortable: true,
        minWidth: 250,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'System',
        id: ValColumnEnum.system,
        align: 'left',
        sortable: true,
        minWidth: 200,
        color: palette.primary.text,
        fontWeight: 500,
        format: val => {
            return val.charAt(0).toUpperCase() + val.slice(1);
        }
    },
    {
        label: 'Drawing file name',
        id: ValColumnEnum.drawing_file_name,
        align: 'left',
        sortable: true,
        minWidth: 250,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Drawing number',
        id: ValColumnEnum.drawing_number,
        align: 'right',
        sortable: true,
        minWidth: 200,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Project category',
        id: ValColumnEnum.project_type_1,
        align: 'left',
        sortable: true,
        minWidth: 250,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Customer name',
        id: ValColumnEnum.customer_name,
        align: 'left',
        sortable: true,
        minWidth: 250,
        color: palette.primary.text,
        fontWeight: 500
    },
    {
        label: 'Status',
        id: ValColumnEnum.status,
        align: 'left',
        minWidth: 100,
        sortable: true,
        format: val => {
            return (_jsxs(Stack, { display: 'flex', flexDirection: 'row', alignItems: 'center', children: [_jsx(FiberManualRecord, { style: {
                            color: val === 'pending' ? '#FFD684' : val === 'Ongoing' ? '#E47A18' : 'transparent'
                        } }), ' ', val] }));
        },
        color: palette.primary.text,
        fontWeight: 500
    }
];
