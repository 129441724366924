import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Drawer, Paper } from '@mui/material';
import { SolutionTypes } from '../../lib/types/SolutionTypes';
import apis from '@shared-app/lib/apis';
import palette from '@theme/palette';
import { ProjectTabs } from './ProjectTabs/ProjectTabs';
import { useTranslation } from 'react-i18next';
import { ProjectHeader } from './ProjectHeader';
export function ControlMenu({ data, selectedSolution, getImage, handleSelect, selectedId, setSelectedId, handleToggleMode, isTopOnlyModeChecked, topViewLayouts }) {
    const { t } = useTranslation();
    const onSolutionClick = (val) => {
        handleSelect(val);
        setSelectedId(val.value.uuid);
        getImage(val.value.image);
    };
    const onMenuClick = (content, type) => {
        if (type === 'metaData') {
            apis.projects
                .getSolutionDetails(data.id, content.uuid)
                .then(res => {
                handleSelect({ type: SolutionTypes.cluster, value: res.solution });
                setSelectedId(res.solution.uuid);
                getImage(res.solution.image);
            })
                .catch(e => console.log('e', e));
        }
        else {
            onSolutionClick({ type, value: content });
        }
    };
    return (_jsx(Stack, { width: 480, borderRight: 1, borderColor: palette.neutral[80], children: _jsx(Drawer, { variant: "permanent", sx: {
                '& .MuiPaper-root': {
                    boxShadow: 'none'
                },
                '& .MuiDrawer-paper': {
                    position: 'relative',
                    width: '100%',
                    height: 'calc(100vh - 80px)'
                }
            }, ModalProps: {
                keepMounted: true
            }, children: _jsxs(Paper, { sx: { width: '100%', display: 'flex', flexDirection: 'column', maxHeight: '100vh' }, children: [_jsx(ProjectHeader, { data: data, isTopOnlyModeChecked: isTopOnlyModeChecked, handleToggle: handleToggleMode }), _jsx(ProjectTabs, { layouts: isTopOnlyModeChecked ? topViewLayouts : data.results.layouts, onMenuClick: onMenuClick, selectedId: selectedId, selectedSolution: selectedSolution })] }) }) }));
}
