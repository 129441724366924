import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ImageControls } from './ImageControls';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
export function ImagePreview({ image }) {
    return (_jsxs(TransformWrapper, { children: [_jsx(ImageControls, {}), _jsx(TransformComponent, { contentStyle: { height: '100%', width: '100%', justifyContent: 'center' }, wrapperStyle: {
                    width: '100%',
                    height: 'calc(100vh - 460px)',
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center'
                }, children: _jsx("img", { src: image, alt: 'img', style: {
                        height: '100%',
                        maxHeight: 'calc(100vh - 460px)',
                        width: 'inherit',
                        objectFit: 'contain'
                    } }) })] }));
}
