import { jsx as _jsx } from "react/jsx-runtime";
import { FilterDataSelect } from '@shared-app/ui';
import { useFilterOptions } from '@estimation/entities/estimationSolution';
import { useTranslation } from 'react-i18next';
import { UnfoldMore } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
export function LocationFilter() {
    const { handleOrganizationalChange, organization, organizationalOptions } = useFilterOptions();
    const { t } = useTranslation();
    return (_jsx(FilterDataSelect, { data: (organizationalOptions === null || organizationalOptions === void 0 ? void 0 : organizationalOptions.values) || [], handleDataChange: values => handleOrganizationalChange(values), label: t('Locations'), selectedValues: organization || [], isDisabled: !(organizationalOptions === null || organizationalOptions === void 0 ? void 0 : organizationalOptions.values), icon: UnfoldMore, textClassName: styles.selectText }));
}
