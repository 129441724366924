import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Typography, Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { KeyboardArrowDown, KeyboardArrowUp } from '@shared-app/ui/icons';
import { ExpectedResultContainer } from './ExpectedResultContainer';
export function WeightEstimation({ maxWeight, minWeight, weight, plot_url }) {
    const { t } = useTranslation();
    const [isCollapsed, setIsCollapsedecked] = useState(true);
    const handleCollapse = () => {
        setIsCollapsedecked(prev => !prev);
    };
    return (_jsxs(Stack, { my: 5, bgcolor: '#F7F7F7', alignItems: 'center', p: 10, children: [_jsxs(Box, { display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'center', children: [_jsx(Typography, { fontSize: 28, fontWeight: 600, children: t('Estimation Results') }), _jsxs(Box, { ml: 5, display: 'flex', flexDirection: 'row', alignItems: 'center', children: [_jsx(ExpectedResultContainer, { label: t('Predicted Weigth'), value: `${weight}kg` }), _jsx(ExpectedResultContainer, { label: t('Expected Solution Weight Range'), value: `${minWeight} - ${maxWeight}kg` })] })] }), _jsxs(Box, { display: 'flex', mt: 5, flexDirection: 'row', alignItems: 'center', style: { cursor: 'pointer' }, children: [!isCollapsed ? (_jsx(KeyboardArrowUp, { style: { color: '#339DFF' } })) : (_jsx(KeyboardArrowDown, { style: { color: '#339DFF' } })), _jsx(Typography, { fontSize: 21, color: '#339DFF', fontWeight: 400, onClick: () => handleCollapse(), children: t('Access Prediction Accuracy') })] }), _jsx(Collapse, { in: !isCollapsed, style: { width: '100%' }, children: plot_url && (_jsx(Box, { width: '100%', children: _jsx("iframe", { src: plot_url, title: "Embedded Content", style: {
                            width: '100%',
                            minWidth: '100%',
                            objectFit: 'contain',
                            minHeight: 600,
                            border: 'none'
                        } }) })) })] }));
}
