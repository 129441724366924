import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from '@shared-app/lib/constants';
import { Layout } from '@pages/layout';
import { Estimation, Validation } from '@pages/index';
import { EstimationUpdatedPage } from '@estimation/pages';
const routes = [
    {
        path: '*',
        element: _jsx(Navigate, { to: ROUTES.ESTIMATION.INDEX })
    },
    {
        path: ROUTES.VALIDATION.HIERARCHY,
        element: _jsx(Validation.HierarchyPage, {})
    },
    {
        path: ROUTES.VALIDATION.VALIDATE,
        element: _jsx(Validation.ValidatePage, {})
    },
    {
        element: _jsx(Layout, { title: "Validation", baseUrl: ROUTES.VALIDATION.INDEX }),
        children: [
            {
                path: ROUTES.VALIDATION.INDEX,
                element: _jsx(Validation.OverviewPage, {})
            }
        ]
    },
    {
        element: _jsx(Layout, { title: "Estimation", baseUrl: ROUTES.ESTIMATION.INDEX }),
        children: [
            {
                path: ROUTES.ESTIMATION.INDEX,
                element: _jsx(EstimationUpdatedPage, {})
            },
            {
                path: ROUTES.ESTIMATION.LEGACY,
                element: _jsx(Estimation.OverviewPage, {})
            }
        ]
    }
];
const router = createBrowserRouter(routes);
export default router;
