import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ArrowCircleRightOutlined, ExpandMore } from '@shared-app/ui/icons';
import { useTranslation } from 'react-i18next';
import { Stack, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { NestedMultiSelectAccordion } from '@shared-app/ui/Common/NestedAccordin';
export function ProjectAccordian({ category, filterOptions, resetCounter }) {
    const { t } = useTranslation();
    return (_jsxs(Accordion, { sx: {
            border: 'none',
            boxShadow: 'none',
            '&::before': {
                position: 'fixed'
            }
        }, children: [_jsx(AccordionSummary, { sx: { border: 'none', boxShadow: 'none' }, expandIcon: _jsx(ExpandMore, {}), "aria-controls": "panel1-content", id: "panel1-header", style: { background: '#E5F3FF', borderRadius: 10 }, children: _jsxs(Stack, { alignItems: 'center', flexDirection: 'row', children: [_jsx(ArrowCircleRightOutlined, { style: { color: '#1890FF', marginRight: 10 } }), ' ', t('Project Category')] }) }), _jsx(AccordionDetails, { sx: { border: 'none', boxShadow: 'none' }, children: (filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.project_type_filters) && category && (_jsx(NestedMultiSelectAccordion, { data: category, resetCounter: resetCounter })) })] }));
}
