export const getViewName = (type, name, t) => {
    if (type === 'top') {
        return `${t('Top View')} ${name}`;
    }
    else if (type === 'side') {
        return `${t('Side View')} ${name}`;
    }
    else if (type === 'section') {
        return `${t('Section View')} ${name}`;
    }
    else if (type === 'merged') {
        return `${t('Merged Solution')} ${name}`;
    }
    else if (type === 'assigned') {
        return `${t('Assigned Solution')} ${name}`;
    }
    else {
        return `${t('Top View')} ${name}`;
    }
};
