import styled from 'styled-components';
export const Container = styled.div `
  height: calc((100vh - 120px));
  padding: 0px 48px 50px;
  .ant-table {
    .ant-table-thead {
      .ant-table-cell {
        text-transform: capitalize;
      }
    }
  }
`;
