import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import palette from '@theme/palette';
import { South, North } from '@shared-app/ui/icons';
import { TablePagination, Typography } from '@mui/material';
import { fonts } from '@theme/font';
import { TableRowSkelton } from './TableRowSkelton';
import { useTranslation } from 'react-i18next';
export const StickyHeadTable = ({ rows, columns, onRowClick, pagination = false, isLoading = false, isProjectList = false, tableUniqueKey }) => {
    var _a;
    const [sortColumn, setSortColumn] = React.useState('');
    const [sortOrder, setSortOrder] = React.useState('none'); // Initially set to 'none'
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(() => {
        const localStorageValue = localStorage.getItem(`${tableUniqueKey}rowsPerPage`);
        return localStorageValue ? Number(localStorageValue) : 100;
    });
    const { t } = useTranslation();
    const handleSort = (columnId) => {
        if (columns.some((column) => column.id === columnId && column.sortable)) {
            if (sortColumn === columnId) {
                localStorage.setItem('sortColumn', `${tableUniqueKey}${columnId}`);
                localStorage.setItem('sortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
                setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            }
            else {
                setSortColumn(columnId);
                setSortOrder('asc');
                if (isProjectList) {
                    localStorage.setItem('sortColumn', `${tableUniqueKey}${columnId}`);
                    localStorage.setItem('sortOrder', sortOrder === 'asc' ? 'desc' : 'asc');
                }
            }
        }
    };
    const sortedRows = React.useMemo(() => {
        if (sortColumn && sortOrder) {
            return [...rows].sort((a, b) => {
                const valueA = a[sortColumn];
                const valueB = b[sortColumn];
                if (sortOrder === 'asc') {
                    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                }
                else {
                    return valueB < valueA ? -1 : valueB > valueA ? 1 : 0;
                }
            });
        }
        return rows;
    }, [rows, sortColumn, sortOrder]);
    const handleChangePage = (_event, newPage) => {
        setPage(newPage);
        if (isProjectList) {
            localStorage.setItem('pageNumber', newPage.toString());
        }
    };
    const handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10);
        setRowsPerPage(value);
        setPage(0);
        localStorage.setItem(`${tableUniqueKey}rowsPerPage`, String(value));
        if (isProjectList) {
            const pageNumber = localStorage.getItem('pageNumber');
            pageNumber && setPage(parseInt(pageNumber));
        }
    };
    React.useEffect(() => {
        setPage(0);
        if (isProjectList) {
            const pageNumber = localStorage.getItem('pageNumber');
            pageNumber && setPage(parseInt(pageNumber));
        }
    }, [isProjectList, rows]);
    React.useEffect(() => {
        const columnIdStorage = localStorage.getItem('sortColumn');
        const sortdata = localStorage.getItem('sortOrder');
        if (sortOrder && columnIdStorage) {
            setSortOrder(sortdata);
            handleSort(columnIdStorage.split(tableUniqueKey).at(-1));
        }
    }, []);
    return (_jsxs(Paper, { sx: { width: '100%' }, children: [_jsx(TableContainer, { component: Paper, sx: { maxHeight: 'calc((100vh - 250px))', boxShadow: 'none', background: 'transparent' }, children: _jsxs(Table, { stickyHeader: true, sx: { alignItems: 'center' }, children: [_jsx(TableHead, { children: _jsx(TableRow, { sx: {
                                    '& th': {
                                        fontSize: 14,
                                        fontWeight: 400,
                                        color: palette.primary[10],
                                        backgroundColor: palette.neutral[97]
                                    }
                                }, children: columns.map((column, index) => (_jsxs(TableCell, { align: column.align, sx: fonts.typography.th, style: {
                                        position: 'sticky',
                                        background: '#fff',
                                        left: index === 0 ? 0 : 'none',
                                        right: index === columns.length - 1 && isProjectList ? 0 : 'none',
                                        zIndex: index === 0 || (index === columns.length - 1 && isProjectList) ? 2 : 1,
                                        minWidth: column.minWidth ? column.minWidth : 150,
                                        width: column.width ? column.width : 20,
                                        fontWeight: column.fontWeight ? column.fontWeight : 400,
                                        color: column.color ? column.color : palette.primary[10]
                                    }, children: [t(column.label), column.sortable && (_jsx(_Fragment, { children: sortOrder === 'none' || sortColumn !== column.id ? (_jsx(North, { sx: { fontSize: 12, ml: '10px', cursor: 'pointer' }, onClick: () => handleSort(column.id) })) : sortOrder === 'asc' && sortColumn === column.id ? (_jsx(South, { sx: { fontSize: 12, ml: '10px', cursor: 'pointer' }, onClick: () => handleSort(column.id) })) : (_jsx(North, { onClick: () => handleSort(column.id), sx: { fontSize: 12, ml: '10px', cursor: 'pointer' } })) }))] }, column.id))) }) }), isLoading ? (_jsx(TableRowSkelton, { columnCount: columns.length || 10, rowCount: 20 })) : (_jsx(TableBody, { children: rows.length > 0 ? ((_a = (rowsPerPage > 0
                                ? sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : sortedRows)) === null || _a === void 0 ? void 0 : _a.map((row, index) => {
                                return (_jsx(TableRow, { hover: true, role: "checkbox", tabIndex: -1, onClick: () => {
                                        if (onRowClick) {
                                            onRowClick(row, index);
                                        }
                                    }, sx: { cursor: 'pointer' }, children: columns.map((column, indexJ) => {
                                        const value = row[column.id];
                                        return (_jsx(TableCell, { style: {
                                                position: indexJ === 0 || (indexJ === columns.length - 1 && isProjectList)
                                                    ? 'sticky'
                                                    : 'initial',
                                                background: '#fff',
                                                left: indexJ === 0 ? 0 : 'none',
                                                right: indexJ === columns.length - 1 ? 0 : 'none',
                                                zIndex: 1,
                                                textTransform: 'none'
                                            }, sx: fonts.typography.body, align: column.align, children: column.format ? column.format(value, index) : value }, column.id));
                                    }) }, row.code));
                            })) : (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: columns.length, align: "center", children: _jsx(Typography, { children: t('No projects match your criteria') }) }) })) }))] }) }), pagination && (_jsx(TablePagination, { sx: {
                    backgroundColor: palette.neutral[97],
                    '& .MuiTablePagination-select': {
                        fontWeight: 600,
                        color: palette.neutral[50]
                    },
                    '& .MuiTablePagination-selectLabel': {
                        fontWeight: 600,
                        color: palette.neutral[50],
                        marginBottom: 0
                    },
                    '& .MuiTablePagination-displayedRows': {
                        fontWeight: 600,
                        color: palette.neutral[50],
                        marginBottom: 0
                    }
                }, rowsPerPageOptions: [10, 50, 100, { label: t('All'), value: -1 }], count: rows.length, rowsPerPage: rowsPerPage, page: page, component: "div", slotProps: {
                    select: {
                        inputProps: {
                            'aria-label': 'rows per page'
                        },
                        native: true
                    }
                }, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: t('Rows Per Page') }))] }));
};
