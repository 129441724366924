var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { fonts } from '@theme/font';
export const Text = (_a) => {
    var { label, variant = 'subtitle1', sx } = _a, rest = __rest(_a, ["label", "variant", "sx"]);
    let textStyle = fonts.typography.subtitle1;
    switch (variant) {
        case 'body2':
            textStyle = fonts.typography.body2;
            break;
        case 'h6':
            textStyle = fonts.typography.h6;
            break;
        case 'subtitle1':
            textStyle = fonts.typography.subtitle1;
            break;
        case 'subtitle2':
            textStyle = fonts.typography.subtitle2;
            break;
        case 'body1':
            textStyle = fonts.typography.body1;
            break;
        case 'th':
            textStyle = fonts.typography.th;
            break;
        case 'body':
            textStyle = fonts.typography.body;
            break;
        default:
            textStyle = fonts.typography.subtitle1;
            break;
    }
    return (_jsx(Typography, Object.assign({ sx: Object.assign(Object.assign({}, textStyle), sx) }, rest, { children: label })));
};
