import { jsx as _jsx } from "react/jsx-runtime";
import { useEstimationResult } from '@estimation/entities/estimationSolution';
import { PlotTile } from './PlotTile';
import { useTranslation } from 'react-i18next';
import { useMetricSystem } from '@shared-app/hooks';
export function EstimationWeightTile() {
    const { estimationData } = useEstimationResult();
    const { t } = useTranslation();
    const { isImperial } = useMetricSystem();
    return (_jsx(PlotTile, { color: "secondary", title: t('Estimation range'), value: `${estimationData.prediction.estimated_weight_min.toString()} - ${estimationData.prediction.estimated_weight_max.toString()} ${isImperial ? 'lb' : 'kg'}` }));
}
