import { ParametersEnum } from '../types';
export const solutionColumns = [
    {
        label: 'File Name',
        id: 'drawing_file_name',
        align: 'center',
        delta: 'na'
    },
    {
        label: 'Weight (kg)',
        labelImperial: 'Weight (lb)',
        id: 'weight',
        align: 'center',
        delta: 'na'
    },
    {
        label: 'Wall Length (mm)',
        labelImperial: 'Wall Length (ft)',
        id: ParametersEnum.length,
        align: 'center',
        delta: 0.05
    },
    {
        label: 'Average Height (mm)',
        labelImperial: 'Average Height (ft)',
        id: ParametersEnum.height,
        align: 'center',
        delta: 0.05
    },
    {
        label: 'Average Thickness (mm)',
        labelImperial: 'Average Thickness (ft)',
        id: ParametersEnum.thickness,
        align: 'center',
        delta: 0.05
    },
    {
        label: 'Number of Segments',
        id: ParametersEnum.segments,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Offsets',
        id: ParametersEnum.offsets,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Double Sided Segments',
        id: ParametersEnum.double_sided,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Single Sided Segments',
        id: ParametersEnum.single_sided,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Cross Junctions',
        id: ParametersEnum.wall_crossings,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of T Junctions',
        id: ParametersEnum.t_junctions,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Stopends',
        id: ParametersEnum.stop_ends,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Non Perpendicular Angles',
        id: ParametersEnum.non_perpendicular_angles,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Perpendicular Angles',
        id: ParametersEnum.perpendicular_angles,
        align: 'center',
        delta: 0
    },
    {
        label: 'Number of Shafts (Rooms)',
        id: ParametersEnum.rings,
        align: 'center',
        delta: 0
    },
    {
        label: 'Concrete Pressure, kN/m2',
        labelImperial: 'Concrete Pressure, PSF',
        id: ParametersEnum.pressure,
        align: 'center',
        delta: 0
    }
];
export const BillQuantitiesColumns = [
    {
        label: 'Article Number',
        id: 'art_no',
        align: 'center',
        sortable: true,
        justifyItems: ''
    },
    {
        label: 'Article Description',
        id: 'art_descript_eng',
        align: 'center',
        sortable: true,
        justifyItems: ''
    },
    {
        label: 'Quantity',
        id: 'units',
        align: 'center',
        sortable: true,
        justifyItems: 'flex-end'
    },
    {
        label: 'Unit Weight (kg)',
        labelImperial: 'Unit Weight (lb)',
        id: 'weight',
        align: 'center',
        format: (value, _) => {
            return (value || 0).toFixed(2);
        },
        sortable: true,
        justifyItems: 'flex-end'
    },
    {
        label: 'Total Weight (kg)',
        labelImperial: 'Total Weight (lb)',
        id: 'total_weight',
        align: 'center',
        format: (value, _) => {
            return (value || 0).toFixed(2);
        },
        sortable: true,
        justifyItems: 'flex-end'
    }
];
