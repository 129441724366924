import { useState } from 'react';
import { BOQEnum } from '@shared-app/lib/types/BOQ';
import { trimAndLowerText } from '@shared-app/utils';
import { useIsGerman } from '@shared-app/hooks';
export function useFilteredData(tableData) {
    const [query, setQuery] = useState('');
    const isGerman = useIsGerman();
    const filterData = () => {
        let filteredData = [...tableData];
        const trimmedQuery = trimAndLowerText(query);
        if (trimmedQuery.length) {
            filteredData = filteredData.filter(row => trimAndLowerText(row[BOQEnum.art_no]).includes(trimmedQuery) ||
                trimAndLowerText(isGerman ? row[BOQEnum.art_descript] : row[BOQEnum.art_descript_eng]).includes(trimmedQuery));
        }
        return filteredData;
    };
    const filteredTableData = filterData();
    return { query, setQuery, filteredTableData };
}
