import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
export const useEstimationResultStore = create()(devtools(set => ({
    selectedSolutionIdx: 0,
    searchQuery: '',
    isLoadMoreEnabled: true,
    isNoSolutionModalVisible: false,
    setLoadMoreEnabled: (value) => {
        return set(() => ({ isLoadMoreEnabled: value }));
    },
    setSelectedSolutionIdx: (selectedSolutionIdx) => {
        return set(() => ({ selectedSolutionIdx }));
    },
    setSearchQuery: (value) => {
        return set(() => ({ searchQuery: value }));
    },
    toggleNoSolutionModalVisibility: (value) => {
        return set(() => ({ isNoSolutionModalVisible: value }));
    }
})));
