import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { FilterSearch } from '@shared-app/ui';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { useEstimationResult } from '@estimation/entities/estimationSolution';
export function BoqTableSearch() {
    const { t } = useTranslation();
    const { updateSearchQuery, searchQuery } = useEstimationResult();
    return (_jsx(Box, { width: 330, display: 'inline-flex', children: _jsx(FilterSearch, { searchQuery: searchQuery, handleChange: updateSearchQuery, placeholder: t('Search by Article Number or Article Description'), className: styles.searchInput, inputHeight: "32px" }) }));
}
