import palette from '@theme/palette';
import styled from 'styled-components';
export const Container = styled.div `
  padding: 77px 48px 50px;
`;
export const RadioColor = {
    '&.Mui-checked': {
        color: palette.neutral[10]
    }
};
