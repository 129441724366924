import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { saveAs } from 'file-saver';
import { Box, CircularProgress } from '@mui/material';
import { Download } from '@shared-app/ui/icons';
import palette from '@theme/palette';
import { Button } from '@shared-app/ui/Common';
import { ToastService } from '@shared/lib/services/toast.service';
import { useTranslation } from 'react-i18next';
import { useSolutionStore } from '@entities/solution';
import { transformBillData, getCSV } from '@shared-app/utils/';
export function BOQDownload() {
    const { bom } = useSolutionStore();
    const [isCVSDownloading, setIsCVSDownloading] = React.useState(false);
    const { t } = useTranslation();
    // Function to handle CSV download
    const handleDownloadCSV = () => {
        setIsCVSDownloading(true);
        const transformedBillData = bom.map(item => transformBillData(item, false));
        const csvContent = getCSV(transformedBillData);
        const selectedFileBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        saveAs(selectedFileBlob, 'BoQ.csv');
        setIsCVSDownloading(false);
        ToastService.success(t('Successfully downloaded'));
    };
    return (_jsx(Box, { display: 'flex', height: 74, bgcolor: palette.common.white, width: '100%', alignItems: 'center', justifyContent: 'flex-end', children: _jsx(Button, { onClick: handleDownloadCSV, startIcon: isCVSDownloading ? _jsx(CircularProgress, {}) : _jsx(Download, {}), disabled: isCVSDownloading, variant: "text", children: t('Download') }) }));
}
