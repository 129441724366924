import { jsx as _jsx } from "react/jsx-runtime";
import { checkIfValuesNull, useNewSolution, SOLUTIONS_THRESHOLD } from '@estimation/entities/estimationSolution';
import { Button, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ArrowCircleRightOutlined } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
export function EstimationButton({ handlePanelClose }) {
    const { filteredCount, inputValues, handleSubmit, isSubmitting } = useNewSolution();
    const isButtonDisabled = checkIfValuesNull(inputValues.filter(item => item.isRequired)) ||
        isSubmitting ||
        filteredCount < SOLUTIONS_THRESHOLD;
    const { t } = useTranslation();
    return (_jsx(Button, { startIcon: isSubmitting ? _jsx(CircularProgress, { color: "inherit", size: 20 }) : _jsx(ArrowCircleRightOutlined, {}), className: styles.submitButton, disabled: isButtonDisabled, variant: "contained", onClick: () => handleSubmit(inputValues.reduce((prev, current) => (Object.assign(Object.assign({}, prev), { [current.name]: current.value })), {}), handlePanelClose ? handlePanelClose : () => { }), children: t(isSubmitting ? 'Processing. Please Wait...' : 'Estimate Solution Weight') }));
}
