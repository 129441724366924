import { useAppSelector, useAppDispatch } from '@app/hooks';
import { setEstimationData, setSolutionFieldItems } from '@app/slice/contentSlice';
import apis from '@shared-app/lib/apis';
import { useEffect, useState } from 'react';
import { isAutoFillParameter } from '../utils/isAutoFillParameter';
import { useEstimationResultStore } from '../state/useEstimationResultStore';
import { useMetricSystem } from '@shared-app/hooks';
export function useNewSolution() {
    const selectedFilterOptions = useAppSelector(state => state.content.selectedFilterOptions);
    const solutionFieldItems = useAppSelector(state => state.content.solutionFieldItems);
    const { toggleNoSolutionModalVisibility } = useEstimationResultStore();
    const dispatch = useAppDispatch();
    const [inputValues, setInputValues] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const filteredDataCount = useAppSelector(state => state.content.filteredDataCount);
    const filteredCount = (filteredDataCount === null || filteredDataCount === void 0 ? void 0 : filteredDataCount.filteredCount) || 0;
    const { isImperial } = useMetricSystem();
    useEffect(() => {
        const filters = selectedFilterOptions === null || selectedFilterOptions === void 0 ? void 0 : selectedFilterOptions.min_max_filters;
        if (!filters) {
            return;
        }
        setInputValues(solutionFieldItems.map(sol => {
            const matchingFilter = filters.find(item => sol.name === item.name);
            if (matchingFilter) {
                return Object.assign(Object.assign({}, sol), { min: matchingFilter.min, max: matchingFilter.max, value: sol.value || (isAutoFillParameter(sol.name) ? matchingFilter.min : sol.value) });
            }
            else {
                return sol; // Return the original item if no matching filter is found
            }
        }));
    }, [selectedFilterOptions, solutionFieldItems]);
    const handleInputValueChange = (index, name, newValue) => {
        const updatedValues = [...inputValues];
        updatedValues[index] = Object.assign(Object.assign({}, updatedValues[index]), { value: newValue });
        dispatch(setSolutionFieldItems(solutionFieldItems.map(item => {
            const matchingFiled = item.name === name;
            if (matchingFiled) {
                return Object.assign(Object.assign({}, item), { value: newValue });
            }
            else {
                return item;
            }
        })));
    };
    const handleSubmit = (values, onSuccess) => {
        setIsSubmitting(true);
        let cleanedValues = {};
        Object.entries(values).forEach(([key, value]) => {
            if (typeof value === 'number' && value > -1) {
                cleanedValues = Object.assign(Object.assign({}, cleanedValues), { [key]: value });
            }
            else {
                cleanedValues = Object.assign(Object.assign({}, cleanedValues), { [key]: null });
            }
        });
        apis.dataset
            .getEstimator({
            input_variables: Object.assign({}, cleanedValues),
            filters: Object.assign({}, selectedFilterOptions)
        }, isImperial)
            .then(res => {
            dispatch(setEstimationData(res));
            toggleNoSolutionModalVisibility(!(res.solutions || []).length);
            onSuccess();
            setIsSubmitting(false);
        })
            .catch(e => {
            setIsSubmitting(false);
        });
    };
    return {
        inputValues,
        filteredCount,
        handleInputValueChange,
        handleSubmit,
        isSubmitting
    };
}
