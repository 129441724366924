export var ValColumnEnum;
(function (ValColumnEnum) {
    ValColumnEnum["id"] = "id";
    ValColumnEnum["created_at"] = "created_at";
    ValColumnEnum["edited_at"] = "edited_at";
    ValColumnEnum["last_edited_by"] = "last_edited_by";
    ValColumnEnum["status"] = "status";
    ValColumnEnum["file_id"] = "file_id";
    ValColumnEnum["date"] = "date";
    ValColumnEnum["system"] = "system";
    ValColumnEnum["organizational_id"] = "organizational_id";
    ValColumnEnum["drawing_number"] = "drawing_number";
    ValColumnEnum["drawing_revision"] = "drawing_revision";
    ValColumnEnum["drawing_category"] = "drawing_category";
    ValColumnEnum["drawing_file_name"] = "drawing_file_name";
    ValColumnEnum["file_information_id"] = "file_information_id";
    ValColumnEnum["centrio_id"] = "centrio_id";
    ValColumnEnum["is_reference"] = "is_reference";
    ValColumnEnum["project_id"] = "project_id";
    ValColumnEnum["customer_name"] = "customer_name";
    ValColumnEnum["customer_id"] = "customer_id";
    ValColumnEnum["project_title"] = "project_title";
    ValColumnEnum["object_id"] = "object_id";
    ValColumnEnum["office_location"] = "office_location";
    ValColumnEnum["project_type_1"] = "project_type_1";
    ValColumnEnum["project_type_2"] = "project_type_2";
    ValColumnEnum["project_type_3"] = "project_type_3";
    ValColumnEnum["office_country"] = "office_country";
})(ValColumnEnum || (ValColumnEnum = {}));
export var ValidationStatusEnum;
(function (ValidationStatusEnum) {
    ValidationStatusEnum["uploaded"] = "uploaded";
    ValidationStatusEnum["converted"] = "converted";
    ValidationStatusEnum["failed"] = "failed";
    ValidationStatusEnum["pending"] = "pending";
    ValidationStatusEnum["validated"] = "validated";
})(ValidationStatusEnum || (ValidationStatusEnum = {}));
