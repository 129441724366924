import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { TotalWeightTile, EstimationWeightTile, PlotGraph } from '@estimation/features/plots';
import palette from '@theme/palette';
export function EstimationResults() {
    const { t } = useTranslation();
    return (_jsxs("div", { className: styles.container, children: [_jsx(Typography, { fontSize: '22px', fontWeight: 600, color: palette.neutral[10], children: t('Estimation Results') }), _jsxs("div", { className: styles.gridContainer, children: [_jsxs("div", { className: styles.plots, children: [_jsx(TotalWeightTile, {}), _jsx(EstimationWeightTile, {})] }), _jsxs("div", { style: { width: '100%' }, children: [' ', _jsx(PlotGraph, {})] })] })] }));
}
