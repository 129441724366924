import { useMemo } from 'react';
import { BOQEnum } from '@shared-app/lib/types/BOQ';
import { trimAndLowerText } from '@shared-app/utils';
import { useEstimationResult } from './useEstimationResult';
export function useFilteredBoqTableData() {
    const { searchQuery, billData } = useEstimationResult();
    const filterData = () => {
        let filteredData = [...billData];
        const trimmedQuery = trimAndLowerText(searchQuery);
        if (trimmedQuery.length) {
            filteredData = filteredData.filter(row => trimAndLowerText(row[BOQEnum.art_no]).includes(trimmedQuery) ||
                trimAndLowerText(row[BOQEnum.art_descript_eng]).includes(trimmedQuery));
        }
        return filteredData;
    };
    const filteredTableData = useMemo(() => filterData(), [billData, searchQuery]);
    const totalWeight = useMemo(() => {
        return filteredTableData.reduce((prev, current) => prev + current[BOQEnum.total_weight], 0);
    }, [billData, searchQuery]);
    return { filteredTableData, totalWeight };
}
