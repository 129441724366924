import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared-app/lib/constants';
import apis from '@shared-app/lib/apis';
import { VisibleItem } from '../lib/types/VisibleItem';
import { SolutionStatus, SolutionView } from '@shared-app/lib/types';
import { ToastService } from '@shared/lib/services/toast.service';
import styles from './styles.module.scss';
import { Button, Text, Input } from '@shared-app/ui';
import { TrimTextToolTip } from '@shared/ui';
import { Check, Edit } from '@shared-app/ui/icons';
import { getViewName } from '@pages/Validation/Hierarchy/lib/utils/getViewName';
import { useTranslation } from 'react-i18next';
import * as S from '../styles';
import { useSolutionStore, areSolutionParmatersValid } from '@entities/solution/lib/state/useSolutionStore';
import { convertSolutionDataToObject } from '@entities/solution';
export function Header({ visibleItem, projectId, solutionId, changesMade }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { fetchSolution, reset, projectSolution, parameters, bom: tableData, updateSolutionName } = useSolutionStore();
    const { solution: solutionData, metadata } = projectSolution;
    const onValidate = () => {
        const solutionObj = convertSolutionDataToObject(solutionData);
        const parametersObject = parameters.reduce((obj, param) => {
            obj[param.name] = param.value !== undefined ? param.value : null;
            return obj;
        }, {});
        solutionObj.parameters = Object.assign(Object.assign({}, solutionObj.parameters), parametersObject);
        solutionObj.status = SolutionStatus.validated;
        solutionObj.bom = tableData;
        // Delete the linked_elevation key
        delete solutionObj.linked_elevation;
        apis.projects
            .updateSolutionDetails(projectId, solutionId, solutionObj)
            .then(() => {
            ToastService.success(t('Solution Validated Successfully'));
            navigate(ROUTES.VALIDATION.HIERARCHY.replace(':id', projectId));
        })
            .catch(() => {
            ToastService.error(t('Please try again'));
        });
    };
    const onDiscard = () => {
        const solutionObj = convertSolutionDataToObject(solutionData);
        const parametersObject = parameters.reduce((obj, param) => {
            obj[param.name] = param.value !== undefined ? param.value : null;
            return obj;
        }, {});
        solutionObj.parameters = Object.assign(Object.assign({}, solutionObj.parameters), parametersObject);
        solutionObj.status = SolutionStatus.discarded;
        // Delete the linked_elevation key
        delete solutionObj.linked_elevation;
        apis.projects
            .updateSolutionDetails(projectId, solutionId, solutionObj)
            .then(() => {
            ToastService.success(t('Solution Discarded Successfully'));
            navigate(ROUTES.VALIDATION.HIERARCHY.replace(':id', projectId));
        })
            .catch(() => {
            ToastService.error(t('Please try again'));
        });
    };
    const onSaveChanges = () => {
        const solutionObj = convertSolutionDataToObject(solutionData);
        const parametersObject = parameters.reduce((obj, param) => {
            obj[param.name] = param.value !== undefined ? param.value : null;
            return obj;
        }, {});
        solutionObj.parameters = Object.assign(Object.assign({}, solutionObj.parameters), parametersObject);
        solutionObj.bom = tableData;
        apis.projects
            .updateSolutionDetails(projectId, solutionId, solutionObj)
            .then(() => {
            ToastService.success(t('Saved Changes'));
            setIsEditClicked(false);
            reset();
            fetchSolution(projectId, solutionId);
        })
            .catch(() => {
            ToastService.error(t('Please try again'));
        });
    };
    const areParametersValid = areSolutionParmatersValid();
    const [isEditClicked, setIsEditClicked] = useState(false);
    const isSaveChangeEnabled = Boolean(solutionData.name.trim()) && (changesMade || isEditClicked) && areParametersValid;
    const isDiscardEnabled = (solutionData && solutionData.status === SolutionStatus.discarded) ||
        (metadata && (metadata === null || metadata === void 0 ? void 0 : metadata.type) === SolutionView.merged) ||
        (metadata && (metadata === null || metadata === void 0 ? void 0 : metadata.type) === SolutionView.asssigned);
    const isValidateDisabled = solutionData && solutionData.status === SolutionStatus.validated;
    return (_jsxs(S.HeaderContainer, { children: [_jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.textContainer, children: [_jsx(Text, { label: _jsx(TrimTextToolTip, { title: getViewName(solutionData.view, isEditClicked ? '' : solutionData.name, t), maxMessageLength: 60, placement: "top" }), variant: "h6" }), !isEditClicked && (_jsx(Edit, { onClick: () => setIsEditClicked(true), fontSize: "small", className: styles.icon }))] }), isEditClicked && (_jsx(Input, { value: solutionData.name, onChange: e => updateSolutionName(e.target.value), className: styles.inputContainer, variant: "standard", InputProps: { disableUnderline: true } }))] }), _jsxs(S.FlexRow, { children: [visibleItem !== VisibleItem.Image && (_jsx(Button, { variant: "outlined", disabled: !isSaveChangeEnabled, sx: { marginRight: 2 }, onClick: onSaveChanges, children: t('Save Changes') })), _jsx(Button, { variant: "outlined", sx: { marginRight: 2 }, color: "error", disabled: isDiscardEnabled, onClick: onDiscard, children: t('Discard Solution') }), !isValidateDisabled && (_jsx(Button, { variant: "contained", startIcon: _jsx(Check, {}), onClick: onValidate, disabled: !areParametersValid, children: t('Validate') }))] })] }));
}
