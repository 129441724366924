import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Accordion, AccordionSummary, Box, Stack, AccordionDetails } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Slider, InputNumber } from 'antd';
import { ColorButton } from './ColorButton';
import ResetIcon from '@shared-app/assets/icons/reset.svg';
import { ExpandLess, ExpandMore } from '@shared-app/ui/icons';
import * as S from './styles';
import { minMaxFilterOptions } from '../../../../../Estimation/entities/estimationSolution/lib/contstants';
export function AdditionalFilters({ handleResetFilterOption, filterOptions, filterValues, handleFilterChange, handleInputChange }) {
    var _a;
    const [isExpanded, setIsExpanded] = useState(false);
    const { t } = useTranslation();
    return (_jsxs(Accordion, { sx: {
            border: 'none',
            boxShadow: 'none',
            '&::before': {
                position: 'fixed'
            }
        }, onChange: (_, isExpanded) => setIsExpanded(isExpanded), children: [_jsx(AccordionSummary, { expandIcon: _jsx(ColorButton, { disableElevation: true, variant: "contained", startIcon: _jsx(ResetIcon, {}), onClick: e => {
                        e.stopPropagation();
                        handleResetFilterOption();
                    }, children: t('Reset Additional Filters') }), "aria-controls": "panel1a-content", id: "panel1a-header", sx: {
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(0deg)'
                    }
                }, children: _jsxs(Box, { display: "flex", alignItems: 'center', children: [' ', isExpanded ? _jsx(ExpandLess, {}) : _jsx(ExpandMore, {}), _jsx(S.FilterItemLabel, { children: t('Additional Filters') })] }) }), _jsx(AccordionDetails, { children: _jsx(Stack, { width: '100%', borderTop: 1, mt: 2, pt: 2, children: filterValues &&
                        ((_a = filterOptions === null || filterOptions === void 0 ? void 0 : filterOptions.min_max_filters) === null || _a === void 0 ? void 0 : _a.map(({ name, min, max }) => {
                            let filterLabel;
                            minMaxFilterOptions.map(({ value, label }) => {
                                if (value === name) {
                                    filterLabel = label;
                                }
                            });
                            return (_jsxs(Stack, { display: 'flex', width: '100%', alignItems: 'center', mt: 2, mb: 2, justifyContent: 'space-between', flexDirection: 'row', children: [_jsx(Stack, { justifyContent: 'center', width: '40%', borderLeft: 3, borderColor: 'lightgray', children: _jsx(S.FilterItemLabel, { children: t(filterLabel) }) }), _jsx(Stack, { children: _jsx(InputNumber, { min: min, max: max, value: filterValues[name] && filterValues[name][0], onChange: value => handleInputChange(value, name, 'min') }) }), _jsx(Stack, { justifyContent: 'center', width: '20%', mx: 3, children: _jsx(Slider, { range: true, onChange: handleFilterChange(name), min: min, max: max, value: filterValues[name] }) }), _jsx(Stack, { children: _jsx(InputNumber, { min: min, max: max, value: filterValues[name] && filterValues[name][1], onChange: e => handleInputChange(e, name, 'max') }) }), _jsx(Stack, { justifyContent: 'center', mx: 3 })] }, name));
                        })) }) })] }));
}
