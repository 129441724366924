import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import { Close, OpenInFull } from '@shared-app/ui/icons';
import { useSolutionData } from '@estimation/entities/estimationSolution';
import styles from './styles.module.scss';
import palette from '@theme/palette';
import { useTranslation } from 'react-i18next';
import { ImagePreview } from '@shared-app/ui';
export function ShowSolutionImage() {
    const { fileName, image, isLoading } = useSolutionData();
    const { t } = useTranslation();
    const [isSolutionModalOpen, setIsSolutionModalOpen] = useState(false);
    const handleClose = () => {
        setIsSolutionModalOpen(false);
    };
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { className: styles.button, disabled: isLoading, onClick: () => setIsSolutionModalOpen(true), children: _jsx(OpenInFull, { className: styles.icon }) }), _jsx(Modal, { onClose: handleClose, open: isSolutionModalOpen, sx: { width: '80%', top: '15%', left: '10%', height: '60%' }, children: _jsxs(Box, { p: 2, bgcolor: palette.common.white, sx: { borderRadius: 2 }, children: [_jsx(Box, { mb: 2, children: _jsxs(Box, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: '24px', children: [_jsxs(Typography, { fontSize: 22, fontWeight: 600, color: palette.neutral[10], children: [t('File Name'), ": ", _jsx("span", { style: { fontWeight: 400 }, children: fileName })] }), _jsx(IconButton, { onClick: handleClose, children: _jsx(Close, { sx: { color: palette.primary[50] } }) })] }) }), _jsx(ImagePreview, { image: image })] }) })] }));
}
