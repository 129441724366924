import styled from 'styled-components';
export const Form = styled.div `
  display: flex;
  padding: 24px 24px 76px 24px;
  flex-direction: column;
  gap: 24px;
  border-radius: 16px;
  border: 1px solid var(--CONXAI-Neutral-Neutral-N-80, #ccc);
  background: var(--CONXAI-Error-Error-E-100, #fff);
  position: relative;
  width: 100%;
`;
export const Name = styled.span `
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  left: 24px;
  top: -24px;
  color: ${props => props.theme.palette.primary.main};
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.22px;
  background: ${props => props.theme.palette.common.white};
`;
