import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { sumBy } from 'lodash';
import palette from '@theme/palette';
import { Text } from '@shared-app/ui/Common';
import { AddRow } from './AddRow/AddRow';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export function BOQTableFooter({ tableData, isDisabled }) {
    const totalWeightSum = sumBy(tableData, 'total_weight');
    const { t } = useTranslation();
    return (_jsxs(Box, { width: '98%', ml: '2%', bgcolor: palette.common.white, className: styles.footerContainer, children: [_jsx(AddRow, { isDisabled: isDisabled }), _jsxs(Box, { display: 'flex', justifyContent: 'flex-end', children: [_jsx(Text, { label: t('Total') + ': ', variant: "body2", className: styles.footerHeader }), _jsx("span", { className: styles.emptySpace, children: "\u00A0" }), _jsx(Text, { label: totalWeightSum === null || totalWeightSum === void 0 ? void 0 : totalWeightSum.toFixed(2), variant: "body2", sx: { color: palette.primary.text }, className: styles.footerDesc })] })] }));
}
