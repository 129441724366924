import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../styles';
import { ROUTES } from '@shared-app/lib/constants';
import { VisibleItem } from '../lib/types/VisibleItem';
import { Header } from './Header';
import { ValidatePageSkeleton } from './ValidatePageSkeleton';
import { ContentBox } from './ContentBox/ContentBox';
import { Layout } from '@pages/layout';
import { useSolutionStore, isSolutionFetched, areChangesMade } from '@entities/solution';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
export function ValidatePage() {
    const [visibleItem, setVisibleItem] = useState(VisibleItem.Parameter);
    const { projectId, solutionId } = useParams();
    const navigate = useNavigate();
    const { fetchSolution, projectData, projectSolution, reset } = useSolutionStore();
    const changesMade = areChangesMade();
    useEffect(() => {
        reset();
        fetchSolution(projectId, solutionId);
    }, [projectId, solutionId]);
    //Add Soluton Image
    const handleBreadcrum = () => {
        navigate(ROUTES.VALIDATION.HIERARCHY.replace(':id', projectData.id));
    };
    const breadCrumSolution = projectData && projectSolution.solution.name;
    const breadCrumProject = projectData && (projectData === null || projectData === void 0 ? void 0 : projectData.drawing_file_name);
    const isLoading = !isSolutionFetched();
    return (_jsx(Layout, { title: "Validation", breadCrumProject: breadCrumProject, breadCrumSolution: breadCrumSolution, baseUrl: ROUTES.VALIDATION.INDEX, onBreadClick: () => handleBreadcrum(), children: _jsx(S.Container, { children: isLoading ? (_jsx(ValidatePageSkeleton, {})) : (projectSolution === null || projectSolution === void 0 ? void 0 : projectSolution.solution) === null ? null : (_jsxs(S.Content, { children: [_jsx(Header, { changesMade: changesMade, visibleItem: visibleItem, projectId: projectId, solutionId: solutionId }), _jsx(ContentBox, { visibleItem: visibleItem, setVisibleItem: setVisibleItem })] })) }) }));
}
