export const calculateBill = (solutionId, estimationData, isGerman = false) => {
    var _a;
    if (!estimationData.boms) {
        return [];
    }
    return (_a = estimationData.boms[parseInt(solutionId)]) === null || _a === void 0 ? void 0 : _a.map(obj => {
        if (obj.art_descript_eng === 'nan' || isGerman) {
            return Object.assign(Object.assign({}, obj), { art_descript_eng: obj.art_descript || obj.art_descript_eng //art_descript can be empty
             });
        }
        return obj;
    });
};
