import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { IconButton, TableBody, TableCell, TableRow } from '@mui/material';
import { InputNumber } from '@shared-app/ui';
import { AlertDialog, Text } from '@shared-app/ui/Common';
import { ToastService } from '@shared/lib/services/toast.service';
import { BOQEnum } from '@shared-app/lib/types/BOQ';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DragIndicator, RemoveCircleOutlined } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
import { useSolutionStore } from '@entities/solution';
import { useIsGerman } from '@shared-app/hooks';
export function BOQTableBody({ tableData, isDisabled }) {
    const [hoveredRow, setHoveredRow] = useState();
    const [showDeleteRow, setShowDeleteRow] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState();
    const isGerman = useIsGerman();
    const { t } = useTranslation();
    const { bom, updateBom } = useSolutionStore();
    const handleEditSave = (row, e) => {
        if (e === null) {
            e = 0;
        }
        updateBom(bom.map(d => (d.art_no === row.art_no ? Object.assign(Object.assign({}, d), { units: e, total_weight: e * d.weight }) : d)));
    };
    const deleteRow = (row) => {
        setShowDeleteRow(true);
        setDeleteRowData(row);
    };
    const handleMouseEnter = (rowId) => {
        setHoveredRow(rowId);
    };
    const handleMouseLeave = () => {
        setHoveredRow(undefined);
    };
    const handleDeteleRow = () => {
        updateBom(bom.filter(d => d.art_no !== deleteRowData.art_no));
        setShowDeleteRow(false);
        ToastService.success(t('Successfully Removed Row'));
    };
    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        let movedItems = reorder(tableData, result.source.index, result.destination.index);
        updateBom(movedItems);
    };
    const getItemStyle = (isDragging, draggableStyle) => (Object.assign({ background: isDragging ? 'rgba(0, 0, 0, 0.08)' : 'white' }, draggableStyle));
    return (_jsxs(_Fragment, { children: [_jsx(DragDropContext, { onDragEnd: onDragEnd, children: _jsx(Droppable, { droppableId: "droppable", children: provided => (_jsx(TableBody, Object.assign({}, provided.droppableProps, { ref: provided.innerRef, children: tableData.map((row, index) => {
                            return (_jsx(Draggable, { draggableId: 'q-' + row.art_no, index: index, children: (provided, snapshot) => {
                                    var _a, _b;
                                    return (_jsxs(TableRow, Object.assign({ onMouseEnter: () => handleMouseEnter(row.art_no), onMouseLeave: handleMouseLeave, ref: provided.innerRef }, provided.draggableProps, provided.dragHandleProps, { style: getItemStyle(snapshot.isDragging, provided.draggableProps.style), children: [_jsxs(TableCell, { sx: { position: 'relative', textAlign: 'end' }, className: styles.tableCell, children: [hoveredRow === row.art_no && (_jsx(IconButton, { onClick: () => (isDisabled ? null : deleteRow(row)), className: styles.minusButton, children: _jsx(RemoveCircleOutlined, {}) })), _jsxs("div", { className: styles.flex, children: [_jsx(DragIndicator, { className: hoveredRow !== row.art_no ? styles.dragIcon : styles.dragIconHovered }), _jsx(Text, { label: row.art_no, variant: "body2", style: { marginLeft: 10 } })] })] }), _jsx(TableCell, { className: styles.tableCell, children: _jsx(Text, { label: isGerman ? row[BOQEnum.art_descript] : row.art_descript_eng, variant: "body2" }) }), _jsx(TableCell, { sx: { textAlign: 'center' }, className: styles.tableCell, children: _jsx(Text, { label: row.count, variant: "body2" }) }), _jsx(TableCell, { sx: { alignItems: 'center' }, className: styles.tableCell, children: isDisabled ? (_jsx(Text, { label: row.units, variant: "body2" })) : (_jsx(InputNumber, { min: 0, width: 80, value: row.units, onChange: (value) => handleEditSave(row, value), onKeyPress: event => {
                                                        if (!/[0-9]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    } })) }), _jsx(TableCell, { sx: { textAlign: 'end' }, className: styles.tableCell, children: _jsx(Text, { label: (_a = row.weight) === null || _a === void 0 ? void 0 : _a.toFixed(2), variant: "body2" }) }), _jsx(TableCell, { sx: { textAlign: 'end' }, className: styles.tableCell, children: _jsx(Text, { label: (_b = row.total_weight) === null || _b === void 0 ? void 0 : _b.toFixed(2), variant: "body2" }) })] })));
                                } }, row.art_no));
                        }) }))) }) }), _jsx(AlertDialog, { open: showDeleteRow, handleClose: () => setShowDeleteRow(false), setOpen: setShowDeleteRow, title: t('Delete'), details: t('Are you sure you want to delete this row?'), buttonText: t('Delete'), onClick: handleDeteleRow })] }));
}
function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}
