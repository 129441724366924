import { Collapse, Input, Row } from 'antd';
import styled from 'styled-components';
export const ProjectCategory = styled(Collapse) `
  && {
    .ant-collapse-item {
      .ant-collapse-header {
        display: flex;
        padding: 14px 8px 14px 16px;
        align-items: center;
        gap: 23px;
        border-radius: 8px;
        color: ${props => props.theme.palette.primary.main};
        background-color: ${props => props.theme.palette.tertiary.main};
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px; /* 137.5% */
        letter-spacing: -0.32px;
        width: fit-content;

        .ant-collapse-expand-icon {
          display: none;
        }

        .ant-collapse-header-text {
          svg:last-of-type {
            color: ${props => props.theme.palette.secondary.main};
          }
        }
      }

      .ant-collapse-content-box {
        padding: 0 32px 0 24px;
        width: 311px;

        .ant-menu {
          border-inline-end: none;

          .ant-menu-submenu-title {
            margin: 0;
            padding: 14px 16px;
            display: flex;
            align-items: center;
            height: 50px;

            &:active,
            &:hover {
              background-color: ${props => props.theme.palette.tertiary.main};
            }

            .ant-menu-title-content {
              color: ${props => props.theme.palette.primary.main};
              font-size: 16px;
              font-style: normal;
              font-weight: 500;
              line-height: 22px; /* 137.5% */
              letter-spacing: -0.32px;
            }

            .ant-menu-submenu-expand-icon {
              width: 20px;
              height: 20px;
              color: ${props => props.theme.palette.secondary.main};
            }
          }
        }
      }
    }
  }
`;
export const FilterItem = styled(Row) `
  align-items: center;
  border-left: 3px solid ${props => props.theme.palette.neutral[70]};
`;
export const FilterItemLabel = styled.span `
  color: ${props => props.theme.palette.neutral[10]};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19px; /* 135.714% */
  letter-spacing: -0.28px;
  margin-left: 5.5px;
`;
export const FilterItemValue = styled(Input) `
  text-align: right;
`;
export const SolutionAmount = styled.div `
  display: flex;
  width: 100%;
  padding: 16px 24px 16px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  border-radius: 0 0 16px 16px;
  background: ${props => props.theme.palette.neutral[97]};
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${props => props.theme.palette.primary.main};
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 105.556% */
  letter-spacing: -0.36px;
`;
export const SolutionErrorAmount = styled.div `
  display: flex;
  width: 100%;
  padding: 16px 24px 16px 10px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 0 0 16px 16px;
  background: ${props => props.theme.palette.error[1]};
  position: absolute;
  bottom: 0;
  left: 0;
  color: ${props => props.theme.palette.primary.main};
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px; /* 105.556% */
  letter-spacing: -0.36px;
`;
