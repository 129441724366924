import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styles from './styles.module.scss';
import { FilterChip } from './FilterChip/FilterChip';
import { useNewSolution } from '@estimation/entities/estimationSolution/lib/hooks/useNewSolution';
import { dominantEstimationFilters } from '@estimation/entities/estimationSolution/lib/contstants';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@shared-app/ui/icons';
import { useMetricSystem } from '@shared-app/hooks';
export function SelectedFilterHeader({ handleToggle, isPanelOpen }) {
    const { inputValues } = useNewSolution();
    const dominantInputValues = inputValues.filter(({ name }) => dominantEstimationFilters.includes(name));
    const { t } = useTranslation();
    const { isImperial } = useMetricSystem();
    return (_jsx("div", { className: styles.container, children: !isPanelOpen ? (_jsxs(_Fragment, { children: [_jsx(Button, { startIcon: _jsx(KeyboardArrowDown, {}), className: styles.accordionButton, variant: "text", onClick: handleToggle, children: t('Open Inputs') }), _jsx("div", { className: styles.filters, children: dominantInputValues.map(({ label, value, labelImperial }) => (_jsx(FilterChip, { label: t(isImperial ? labelImperial || label : label), value: (value || 0).toString() }, label))) })] })) : (_jsx(Button, { startIcon: _jsx(KeyboardArrowUp, {}), className: styles.accordionButton, variant: "text", onClick: handleToggle, children: t('Close Inputs') })) }));
}
