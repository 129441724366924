export const appendUrlParams = (baseUrl, params) => {
    const [path, existingQuery] = baseUrl.split('?');
    const searchParams = new URLSearchParams(existingQuery);
    Object.entries(params).forEach(([key, value]) => {
        if (value) {
            searchParams.append(key, value.toString());
        }
    });
    const queryString = searchParams.toString();
    return queryString ? `${path}?${queryString}` : path;
};
