import { jsx as _jsx } from "react/jsx-runtime";
import { MultipleSelectCheckmarks, Text } from '@shared-app/ui';
import { KeyboardArrowDown } from '@shared-app/ui/icons';
import { getTrimmedText } from '@shared/utils';
import { useTranslation } from 'react-i18next';
export function FilterDataSelect({ data, handleDataChange, label, selectedValues, isDisabled = false, icon, textClassName }) {
    const { t } = useTranslation();
    return (_jsx(MultipleSelectCheckmarks, { values: selectedValues, data: data, isDisabled: isDisabled, renderValue: selected => {
            if (selected.length === data.length) {
                return _jsx(Text, { label: `${label}: ${t('All')}`, variant: "body", className: textClassName });
            }
            if (selected.length >= 2) {
                return (_jsx(Text, { label: t('{{label}}: {{length}} Values', { label, length: selected.length }), className: textClassName, variant: "body" }));
            }
            else {
                return (_jsx(Text, { label: `${label}:  ${getTrimmedText(selected.toString(), 20)} `, className: textClassName, variant: "body" }));
            }
        }, IconComponent: icon ? icon : KeyboardArrowDown, handleChange: handleDataChange, label: label }));
}
