import palette from './palette';
//styleName: typography/h6;
export const fonts = {
    typography: {
        h6: {
            fontSize: '20px',
            fontWeight: 500,
            lineHeight: '32px',
            letterSpacing: ' 0.15000000596046448px',
            color: '#000000DE'
        },
        subtitle2: {
            fontSize: '14px',
            fontWeight: 500,
            lineHeight: '21.98px',
            letterSpacing: ' 0.15000000596046448px',
            color: '#000000DE'
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '28px',
            letterSpacing: ' 0.17px'
        },
        body2: {
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '20.02px',
            letterSpacing: ' 0.17px',
            color: '#00000099'
        },
        body1: {
            fontSize: '14px',
            fontWeight: 600,
            lineHeight: '20.02px',
            letterSpacing: ' 0.17px'
        },
        body: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: ' 0.17px',
            textTransform: 'capitalize',
            color: palette.primary[10]
        },
        th: {
            fontSize: '#191919',
            fontWeight: 400,
            lineHeight: '24px',
            color: '#000000DE',
            letterSpacing: '-0.32px'
        }
    }
};
