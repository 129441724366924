import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Box } from '@mui/material';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { ColorButton } from './ColorButton';
import ResetIcon from '@shared-app/assets/icons/reset.svg';
import { fonts } from '@theme/font';
import { FilterSelect } from '@shared-app/ui/FilterSelect';
export function Header({ yearOptions, years, handleOrganizationalChange, handleResetAll, handleYearChange, organization, organizationalOptions }) {
    const { t } = useTranslation();
    return (_jsx(Form, { name: "Filter dataset", layout: "vertical", style: { width: '100%' }, children: _jsxs(Stack, { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: "100%", flexDirection: 'row', children: [_jsx(Box, { width: "30%", children: _jsx(FilterSelect, { data: (yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values) || [], handleDataChange: values => handleYearChange(values), label: t('Year'), selectedValues: years || [], isDisabled: !(yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values) }) }), _jsx(Box, { width: "30%", children: _jsx(FilterSelect, { data: (organizationalOptions === null || organizationalOptions === void 0 ? void 0 : organizationalOptions.values) || [], handleDataChange: values => handleOrganizationalChange(values), label: t('Location'), selectedValues: organization || [], isDisabled: !(yearOptions === null || yearOptions === void 0 ? void 0 : yearOptions.values) }) }), _jsx(Box, { width: "20%", children: _jsx(ColorButton, { fullWidth: true, disableElevation: true, sx: Object.assign({}, fonts.typography.body), variant: "contained", startIcon: _jsx(ResetIcon, {}), onClick: handleResetAll, children: t('Reset All') }) })] }) }));
}
