import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@shared-app/ui';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useEstimationResult } from '@estimation/entities/estimationSolution';
export function LoadSolutions() {
    const { t } = useTranslation();
    const { isLoadMoreEnabled, setLoadMoreEnabled } = useEstimationResult();
    return (_jsxs("div", { className: styles.container, children: [' ', _jsx(Button, { className: styles.button, variant: "text", onClick: () => setLoadMoreEnabled(false), disabled: !isLoadMoreEnabled, children: t('Load More Solutions') })] }));
}
