import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import palette from '@theme/palette';
import { Box } from '@mui/material';
import { Button, Text, InputNumber } from '@shared-app/ui';
import { Replay } from '@shared-app/ui/icons';
import { useTranslation } from 'react-i18next';
import { ParametersEnum, useSolutionStore } from '@entities/solution';
import { sliceRows } from '@shared-app/utils';
import { hasParamError } from '@entities/solution/lib/utils/hasParamError';
export function Parameters({ handleResetAll, isFieldDisabled }) {
    const { t } = useTranslation();
    const { parameters, updateParamater } = useSolutionStore();
    const rows = sliceRows(parameters, 2);
    return (_jsxs(Box, { m: 3, bgcolor: palette.common.white, borderRadius: '4px', children: [_jsxs(Box, { bgcolor: palette.common.white, display: 'flex', width: '100%', justifyContent: 'space-between', p: '16px', sx: { borderTopLeftRadius: 4, borderTopRightRadius: 4 }, children: [_jsx(Text, { label: "Parameter", variant: "h6" }), _jsxs(Button, { onClick: handleResetAll, variant: "text", startIcon: _jsx(Replay, {}), children: [' ', t('Reset'), ' '] })] }), _jsx(Box, { pl: '16px', pr: '16px', sx: { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }, children: rows.map((row, index) => (_jsx(Box, { display: 'flex', height: 80, alignItems: 'center', justifyContent: 'space-between', children: row.map((param, index) => {
                        return (_jsxs(Box, { justifyContent: 'space-between', alignItems: 'center', width: '45%', display: 'flex', children: [_jsxs(Box, { width: '60%', children: [' ', _jsx(Text, { label: `${t(param.label)}:`, variant: "body2", sx: { color: palette.secondary.text } })] }), isFieldDisabled ? (_jsx(Text, { label: param.value, variant: "body2", sx: { color: palette.primary[10] } })) : (_jsx(Box, { width: '30%', children: _jsx(InputNumber, { width: '100%', style: { width: '100%' }, disabled: param.name === ParametersEnum.single_sided, onKeyPress: event => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }, value: param.value, onChange: (newValue) => updateParamater(param.name, newValue), errorText: hasParamError(param, parameters) ? t('Invalid Value') : '' }) }))] }, index));
                    }) }, index))) })] }));
}
