import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAnchorElement, useMetricSystem } from '@shared-app/hooks';
import { Box, FormControl, Menu, Typography, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import { EditSettings, Check } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
import { metrics } from '@shared-app/lib/constants';
import { useState } from 'react';
import { ConfirmationModal } from './ConfirmationModal';
export function MetricSwitcher() {
    const { changeMetricSystem, currentMetricSystem } = useMetricSystem();
    const [confirmModalProps, setConfirmModalProps] = useState({ visible: false });
    const handleConfirmLanguageChange = () => {
        changeMetricSystem(confirmModalProps.system);
        setConfirmModalProps({ system: undefined, visible: false });
    };
    const handleModalClose = () => {
        setConfirmModalProps({ system: undefined, visible: false });
    };
    const { anchor, clearAnchor, setAnchor } = useAnchorElement();
    return (_jsxs(Box, { className: styles.container, children: [_jsxs(Box, { className: styles.iconContainer, onClick: setAnchor, children: [_jsx(EditSettings, { className: styles.icon }), _jsx(Typography, { className: styles.label, children: "Unit Settings" })] }), _jsx(FormControl, { children: _jsx(Menu, { anchorEl: anchor, open: Boolean(anchor), onClose: clearAnchor, children: metrics.map(({ label, value, labelDesc }) => {
                        return (_jsxs(MenuItem, { className: styles.item, onClick: () => {
                                setConfirmModalProps({ visible: true, system: value });
                                clearAnchor();
                            }, children: [_jsx(ListItemIcon, { children: currentMetricSystem === value && _jsx(Check, { fontSize: "small" }) }), _jsx(ListItemText, { children: label }), _jsx(Typography, { className: styles.labelDesc, children: labelDesc })] }, value));
                    }) }) }), _jsx(ConfirmationModal, { handleClose: handleModalClose, handleSubmit: handleConfirmLanguageChange, isVisible: confirmModalProps.visible })] }));
}
