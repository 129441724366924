import { ParametersEnum } from '../constants';
export const getSingleSidedValue = (parameters) => {
    return parameters.reduce((prev, current) => {
        let currentValue = 0;
        switch (current.name) {
            case ParametersEnum.segments:
                currentValue = current.value;
                break;
            case ParametersEnum.double_sided:
                currentValue = -current.value;
                break;
            default:
                break;
        }
        return currentValue + prev;
    }, 0);
};
