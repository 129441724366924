export var ParametersEnum;
(function (ParametersEnum) {
    ParametersEnum["length"] = "length";
    ParametersEnum["height"] = "height";
    ParametersEnum["thickness"] = "thickness";
    ParametersEnum["segments"] = "segments";
    ParametersEnum["offsets"] = "offsets";
    ParametersEnum["double_sided"] = "double_sided";
    ParametersEnum["wall_crossings"] = "wall_crossings";
    ParametersEnum["single_sided"] = "single_sided";
    ParametersEnum["t_junctions"] = "t_junctions";
    ParametersEnum["stop_ends"] = "stop_ends";
    ParametersEnum["perpendicular_angles"] = "perpendicular_angles";
    ParametersEnum["non_perpendicular_angles"] = "non_perpendicular_angles";
    ParametersEnum["rings"] = "rings";
    ParametersEnum["pressure"] = "pressure";
})(ParametersEnum || (ParametersEnum = {}));
