import { parameters } from '@shared-app/lib/constants';
import * as Yup from 'yup';
export const minMaxFilterOptions = parameters.map(parameter => ({
    label: parameter.label,
    value: parameter.name
}));
export var FilterEnum;
(function (FilterEnum) {
    FilterEnum["office_country"] = "office_country";
    FilterEnum["date"] = "date";
})(FilterEnum || (FilterEnum = {}));
export const initialFormValues = parameters.reduce((prev, current) => {
    return Object.assign(Object.assign({}, prev), { [current.name]: '' });
}, {});
export const validationSchema = Yup.object().shape(parameters.reduce((prev, current) => (Object.assign(Object.assign({}, prev), { [current.name]: current.isRequired ? Yup.number().required() : undefined })), {}));
export const dominantEstimationFilters = ['length', 'height', 'thickness', 'segments', 'offsets'];
export const INITIAL_SOLUTIONS_LENGTH = 5;
export const SOLUTIONS_THRESHOLD = 50;
