import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LocationFilter } from './LocationFilter';
import { YearFilter } from './YearFilter';
import { EstimationButton } from './EstimationButton';
import { ProjectFilterButton } from './ProjectFilterButton/ui/ProjectFilterButton';
import { Box, Typography } from '@mui/material';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { FilterDataSetCount } from './FilterDataSetCount';
export function FilterHeader({ handlePanelClose }) {
    const { t } = useTranslation();
    return (_jsxs(Box, { className: styles.container, children: [_jsx(Typography, { className: styles.headerText, children: t('Filters Dataset') }), _jsxs("div", { className: styles.filterContainer, children: [_jsxs("div", { className: styles.filters, children: [_jsx(Box, { className: styles.filterOption, children: _jsx(LocationFilter, {}) }), _jsx(Box, { className: styles.filterOption, children: _jsx(YearFilter, {}) }), _jsx(ProjectFilterButton, {})] }), _jsxs(Box, { display: 'flex', alignItems: 'center', gap: '16px', children: [_jsx(FilterDataSetCount, {}), _jsx(EstimationButton, { handlePanelClose: handlePanelClose })] })] })] }));
}
