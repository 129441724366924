import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Button, styled } from '@mui/material';
import palette from '@theme/palette';
const StyledButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(palette.neutral[97]),
    backgroundColor: palette.neutral[97],
    padding: '1rem',
    '&:hover': {
        backgroundColor: palette.neutral[97]
    }
}));
export function ColorButton(props) {
    return _jsx(StyledButton, Object.assign({}, props));
}
