var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { AuthService } from '@shared/lib/services';
export function useAuth() {
    const [user, setUser] = useState();
    const handleNavigate = () => {
        const toNavigate = sessionStorage.getItem('preSignInUrl');
        if (toNavigate) {
            sessionStorage.removeItem('preSignInUrl');
            window.location.replace(toNavigate);
        }
    };
    const getUser = () => __awaiter(this, void 0, void 0, function* () {
        const user = yield AuthService.currentUser();
        setUser(user);
        handleNavigate();
    });
    useEffect(() => {
        getUser();
    }, []);
    return {
        user,
        logout: AuthService.logout
    };
}
