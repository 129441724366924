import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../styles';
import { Box, Skeleton } from '@mui/material';
import palette from '@theme/palette';
import { Text } from '@shared-app/ui';
export function ValidatePageSkeleton() {
    return (_jsxs(S.Content, { children: [_jsxs(S.HeaderContainer, { children: [_jsx(Skeleton, { variant: "rounded", width: 134, height: 32 }), _jsxs(S.FlexRow, { children: [_jsx(Skeleton, { variant: "rounded", sx: { marginRight: '16px' }, width: 160, height: 42 }), _jsx(Skeleton, { variant: "rounded", sx: { marginRight: '16px' }, width: 111, height: 42 }), _jsx(Skeleton, { variant: "rounded", width: 111, height: 42 })] })] }), _jsxs(S.ContentContainer, { children: [_jsx(S.DXFContent, { children: _jsx(Skeleton, { variant: "rounded", width: 111, height: 42 }) }), _jsxs(S.Content, { children: [_jsx(S.ToggleContainer, { children: _jsx(Skeleton, { variant: "rounded", width: 367, height: 52 }) }), _jsx(S.RightContent, { children: _jsx(Box, { display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', children: _jsxs(Box, { bgcolor: palette.grey[100], width: '100%', height: '100%', justifyContent: 'space-between', display: 'flex', flexDirection: 'column', children: [_jsxs(Box, { m: 3, bgcolor: palette.common.white, borderRadius: '4px', children: [_jsxs(Box, { bgcolor: palette.common.white, display: 'flex', width: '100%', justifyContent: 'space-between', p: '16px', sx: { borderTopLeftRadius: 4, borderTopRightRadius: 4 }, children: [_jsx(Text, { label: "Parameter", variant: "h6" }), _jsx(Skeleton, { variant: "rounded", width: 114, height: 42 })] }), _jsx(Box, { pl: '16px', pr: '16px', sx: { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }, children: [80, 150, 110, 90, 80, 100, 80].map((width, index) => (_jsx(Box, { display: 'flex', height: 68, borderBottom: 1, borderColor: '#0000001F', alignItems: 'center', justifyContent: 'space-between', children: [1, 1].map((param, index) => {
                                                                return (_jsxs(Box, { justifyContent: 'space-between', alignItems: 'center', width: '45%', display: 'flex', children: [_jsxs(Box, { width: '60%', children: [' ', _jsx(Skeleton, { variant: "rounded", width: width, height: 20 })] }), _jsx(Box, { width: '30%', children: _jsx(Skeleton, { variant: "rounded", width: 104, height: 44 }) })] }, index));
                                                            }) }, index))) })] }), _jsx(Box, { display: 'flex', sx: { border: '1px solid #E0E0E0' }, p: 2, height: 74, bgcolor: palette.common.white, width: '100%', alignItems: 'center', justifyContent: 'flex-end', children: _jsx(Skeleton, { variant: "rounded", width: 153, height: 42 }) })] }) }) })] })] })] }));
}
