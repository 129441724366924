import { jsx as _jsx } from "react/jsx-runtime";
import { Input } from '@shared-app/ui';
import { Search } from '@shared-app/ui/icons';
import InputAdornment from '@mui/material/InputAdornment';
import styles from './styles.module.scss';
import classNames from 'classnames';
export function FilterSearch({ searchQuery, placeholder, handleChange, className, inputHeight }) {
    return (_jsx(Input, { value: searchQuery, onChange: e => handleChange(e.target.value), placeholder: placeholder, variant: "outlined", size: "small", className: classNames(className, styles.input), InputProps: {
            style: { height: inputHeight },
            startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(Search, { sx: { height: '24px', width: '24px' } }) }))
        } }));
}
