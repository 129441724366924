import { ParametersEnum } from '../types';
export const elevationParameter = [
    {
        name: ParametersEnum.height,
        label: 'Average Height (mm)',
        value: null,
        isRequired: true,
        min: 0,
        max: Number.MAX_SAFE_INTEGER
    }
];
