import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useAppDispatch } from '@app/hooks';
import { ROUTES } from '@shared-app/lib/constants';
import { Layout } from '../../../layout';
import { useProjectData } from '../lib/hooks/useProjectData';
import { useImage } from '../lib/hooks/useGetImage';
import { useGetSolution } from '../lib/hooks/useGetSolution';
import { ControlMenu } from './ControlMenu';
import { Result } from './Result';
export function HierarchyPage() {
    const [selectedId, setSelectedId] = useState('');
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isTopOnlyModeChecked, setTopOnyModeChecked] = useState(false);
    const handleToggleMode = () => {
        setTopOnyModeChecked(!isTopOnlyModeChecked);
    };
    const handleBreadcrum = () => {
        navigate(ROUTES.VALIDATION.HIERARCHY.replace(':id', projectData.id));
    };
    const handleChangeSelectedId = (id) => {
        setSelectedId(id);
    };
    const { getImage, image, imageLoading } = useImage();
    const { handleSelect, handleSetDefaultView, selectedSolutionAndType } = useGetSolution({
        getImage,
        handleChangeSelectedId
    });
    const { projectData, handleReloadApiData, topViewLayouts } = useProjectData(id, dispatch, handleSetDefaultView);
    const { value: selectedSolution, type: _ } = selectedSolutionAndType;
    const breadCrumProject = projectData && (projectData === null || projectData === void 0 ? void 0 : projectData.drawing_file_name);
    useEffect(() => {
        if (projectData) {
            handleSetDefaultView(projectData);
        }
    }, [projectData, isTopOnlyModeChecked]);
    if (!projectData) {
        return null;
    }
    return (_jsx(Layout, { title: "Validation", baseUrl: ROUTES.VALIDATION.INDEX, breadCrumProject: breadCrumProject, onBreadClick: () => handleBreadcrum(), children: _jsx(Stack, { width: '100%', children: _jsxs(Stack, { display: 'flex', flexDirection: 'row', width: '100%', children: [_jsx(ControlMenu, { data: projectData, getImage: getImage, handleSelect: handleSelect, selectedId: selectedId, selectedSolution: selectedSolution, setSelectedId: setSelectedId, handleToggleMode: handleToggleMode, isTopOnlyModeChecked: isTopOnlyModeChecked, topViewLayouts: topViewLayouts }), _jsx(Result, { handleReloadApiData: handleReloadApiData, image: image, imageLoading: imageLoading, projectId: projectData.id, selectedId: selectedId, selectedSolutionAndType: selectedSolutionAndType })] }) }) }));
}
