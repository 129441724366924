import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { SolutionStatus } from '@shared-app/lib/types';
import { MenuItem, Box, Stack } from '@mui/material';
import { SolutionTypes } from '@pages/Validation/Hierarchy/lib/types/SolutionTypes';
import palette from '@theme/palette';
import { Text } from '@shared-app/ui';
import { getViewName } from '@pages/Validation/Hierarchy/lib/utils/getViewName';
import { FiberManualRecord, ArrowForwardIos } from '@shared-app/ui/icons';
import { useTranslation } from 'react-i18next';
import { PhotoOutlined } from '@shared/ui/icons/';
import styles from './unProcessedTab.module.css';
export function UnProcessedTab({ layouts, onMenuClick, selectedId, selectedSolution }) {
    const { t } = useTranslation();
    const allContent = layouts.map(layout => {
        return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.menuItem, children: [_jsx(MenuItem, { onClick: () => {
                                onMenuClick(layout, SolutionTypes.layout);
                            }, sx: {
                                '&:hover': { backgroundColor: palette.primary.state.selected },
                                background: selectedId === layout.uuid ? palette.primary.state.selected : 'transparent'
                            }, children: _jsx(Text, { label: layout.name, variant: "subtitle2" }) }), _jsx(PhotoOutlined, { fontSize: "small" })] }), layout.viewports.map(viewport => (_jsxs(_Fragment, { children: [_jsx("div", { children: _jsxs(MenuItem, { onClick: () => {
                                    onMenuClick(viewport, SolutionTypes.viewport);
                                }, sx: {
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    padding: '10px 18px 10px 16px',
                                    '&:hover': { backgroundColor: palette.primary.state.selected },
                                    background: selectedId === viewport.uuid ? palette.primary.state.selected : 'transparent'
                                }, children: [_jsx(Text, { label: viewport.name, variant: "subtitle2", sx: {
                                            color: palette.secondary.text
                                        } }), _jsx(PhotoOutlined, { fontSize: "small" })] }) }), viewport.solutions_metadata.map((cluster, kIndex) => (_jsx(MenuItem, { onClick: () => {
                                onMenuClick(cluster, 'metaData');
                            }, sx: {
                                padding: '0px 0px 0px 24px',
                                '&:hover': { backgroundColor: palette.primary.state.selected },
                                background: (selectedSolution === null || selectedSolution === void 0 ? void 0 : selectedSolution.uuid) === cluster.uuid
                                    ? palette.primary.state.selected
                                    : 'transparent'
                            }, children: _jsxs(Box, { p: '4px 16px 4px 16px', height: 48, display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between', children: [_jsx(Box, { display: 'flex', children: _jsx(Stack, { children: _jsx(Text, { variant: "subtitle1", label: getViewName(cluster.type, cluster.name, t), sx: { color: palette.primary.text } }) }) }), _jsxs(Box, { display: 'flex', alignItems: 'center', children: [_jsx(FiberManualRecord, { sx: {
                                                    color: cluster.status === SolutionStatus.pending ? '#EF6C00' : 'transparent',
                                                    fontSize: 14,
                                                    mr: 1
                                                } }), _jsx(Text, { label: t(cluster.status === SolutionStatus.pending
                                                    ? 'Pending validation'
                                                    : cluster.status), variant: "body2", sx: {
                                                    textTransform: 'capitalize',
                                                    color: cluster.status === SolutionStatus.discarded
                                                        ? palette.secondary.text2
                                                        : palette.secondary.text
                                                } }), _jsx(ArrowForwardIos, { sx: { fontSize: 10, ml: 2 } })] })] }) }, kIndex)))] })))] }));
    });
    return _jsx(Box, { children: allContent });
}
