var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import apis from '@shared-app/lib/apis';
import { db } from '@shared-app/lib/config/db';
export const getDFXS3Backend = (dxf, projectId) => __awaiter(void 0, void 0, void 0, function* () {
    const dfxUrl = yield apis.dataset
        .getDFX(dxf)
        .then((resDxf) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const id = yield db.table('myObject').add({
                id: projectId,
                data: resDxf
            });
            console.log(`Data added to IndexedDB with ID: ${id}`);
        }
        catch (error) {
            console.error('Error adding file to database', error);
            return URL.createObjectURL(resDxf);
        }
        const url = URL.createObjectURL(resDxf);
        return url;
    }))
        .catch(err => {
        console.log('err', err);
        return '';
    });
    return dfxUrl;
});
export const getDFXUrl = (projectId, dxf) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const allData = yield db.table('myObject').toArray();
        if (allData.length === 0) {
            const dfxUrl = yield getDFXS3Backend(dxf, projectId);
            return dfxUrl;
        }
        else if (allData[0].id === projectId) {
            const url = URL.createObjectURL(allData[0].data);
            return url;
        }
        else if (allData[0].id !== projectId) {
            db.table('myObject').clear();
            const dfxUrl = yield getDFXS3Backend(dxf, projectId);
            return dfxUrl;
        }
    }
    catch (error) {
        console.error('Error fetching all data:', error);
    }
});
