import { ParametersEnum } from '@entities/solution';
export const isAutoFillParameter = (paramName) => {
    return ![
        ParametersEnum.height,
        ParametersEnum.length,
        ParametersEnum.thickness,
        ParametersEnum.segments,
        ParametersEnum.pressure
    ].includes(paramName);
};
