import { jsx as _jsx } from "react/jsx-runtime";
import { useControls } from 'react-zoom-pan-pinch';
import * as S from '../styles';
import { IconButton } from '@mui/material';
import palette from '@theme/palette';
import { Refresh } from '@shared-app/ui/icons';
export function ImageControls() {
    const { resetTransform } = useControls();
    return (_jsx(S.Controls, { children: _jsx(IconButton, { sx: {
                background: palette.common.white,
                mb: '12px',
                '&.MuiButtonBase-root:hover': {
                    bgcolor: palette.common.white
                },
                boxShadow: '0px 1px 18px 0px #0000001F'
            }, onClick: () => resetTransform(), children: _jsx(Refresh, { color: "primary", sx: { color: palette.grey[900] } }) }) }));
}
