import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { RouterProvider } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import './config/Amplify';
import '../shared-app/assets/styles/font.css';
import router from '@app/router';
import theme from '../theme';
import { Toaster } from '@shared/ui/Toaster';
import '@shared/scss/main.scss';
const App = () => {
    return (_jsx(_Fragment, { children: _jsx(ThemeProvider, { theme: theme, children: _jsxs(ConfigProvider, { theme: {
                    token: {
                        fontFamily: 'Inter',
                        colorPrimary: theme.palette.primary.main
                    },
                    components: theme.components
                }, children: [_jsx(RouterProvider, { router: router }), _jsx(Toaster, {})] }) }) }));
};
export default App;
