import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../../styles';
import { ContentHeader } from './ContentHeader';
import MyDxfViewer from '@shared-app/ui/DxfViewer/MyDxfViewer';
import { CircularProgress, Typography } from '@mui/material';
import { InfoPanel } from './InfoPanel/InfoPanel';
import { isDfxFetched, useSolutionStore } from '@entities/solution';
import { useTranslation } from 'react-i18next';
export function ContentBox({ visibleItem, setVisibleItem }) {
    var _a;
    const handleChange = (_, newValue) => {
        setVisibleItem(newValue);
    };
    const isDfxLoading = !isDfxFetched();
    const { projectSolution, dxfURL } = useSolutionStore();
    const { t } = useTranslation();
    return (_jsxs(S.ContentContainer, { children: [_jsx(S.DXFContent, { children: isDfxLoading ? (_jsxs(S.DXFContent, { children: [_jsx(CircularProgress, {}), _jsxs(Typography, { color: '#fff', children: [t('Loading'), "...."] })] })) : (_jsx(MyDxfViewer, { url: dxfURL, box_coordinates: (_a = projectSolution.solution) === null || _a === void 0 ? void 0 : _a.box_coordinates })) }), _jsxs(S.Content, { children: [_jsx(ContentHeader, { handleChange: handleChange, visibleItem: visibleItem }), _jsx(InfoPanel, { visibleItem: visibleItem })] })] }));
}
