import { useState } from 'react';
import { SolutionTypes } from '../types/SolutionTypes';
export function useGetSolution({ getImage, handleChangeSelectedId }) {
    const [selectedSolutionAndType, setselectedSolutionAndType] = useState({
        type: SolutionTypes.unknwon,
        value: { name: '', uuid: '', image: '' }
    });
    const handleSetDefaultView = (data) => {
        const defaultLayout = data.results.layouts[0];
        handleChangeSelectedId(defaultLayout.uuid);
        handleSelect({ type: SolutionTypes.layout, value: defaultLayout });
        getImage(defaultLayout.image);
    };
    const handleSelect = (val) => {
        setselectedSolutionAndType(val);
    };
    return {
        handleSelect,
        handleSetDefaultView,
        selectedSolutionAndType
    };
}
