import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack, Box, Typography } from '@mui/material';
import { useMetricSystem } from '@shared-app/hooks';
import { InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
export function InputField({ paramItem, handleChange, isSubmitting }) {
    const { t } = useTranslation();
    const { min, max, label, value, labelImperial } = paramItem;
    const { isImperial } = useMetricSystem();
    return (_jsxs(Box, { display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', children: [_jsxs(Stack, { children: [_jsx(Typography, { fontSize: 14, color: '#191919', lineHeight: '19px', children: t(isImperial ? labelImperial || label : label) }), _jsxs(Typography, { fontSize: 12, color: '#4C4C4C', lineHeight: '22px', children: [t('from'), " ", min, " ", t('to'), " ", max] })] }), _jsx(InputNumber, { disabled: isSubmitting, placeholder: t('not set'), type: "number", value: value, min: min, max: max, onChange: (value) => handleChange(value) })] }));
}
