import { SolutionView } from '@shared-app/lib/types';
export function getTopViewLayouts(data) {
    const layouts = data.results.layouts;
    return layouts.map(getOnlyTopViewLayouts);
}
function getOnlyTopViewLayouts(layout) {
    const updatedViewports = layout.viewports.map(viewport => {
        const topSolutionMetadata = viewport.solutions_metadata.filter((metadata) => metadata.type === SolutionView.top);
        const topSolutionUuids = new Set(topSolutionMetadata.map(metadata => metadata.uuid));
        return Object.assign(Object.assign({}, viewport), { solutions: viewport.solutions.filter(solutionUuid => topSolutionUuids.has(solutionUuid)), solutions_metadata: topSolutionMetadata });
    });
    return Object.assign(Object.assign({}, layout), { viewports: updatedViewports });
}
