import { jsx as _jsx } from "react/jsx-runtime";
import { Stack, Grid } from '@mui/material';
import { InputField } from './InputField';
import { useNewSolution } from '@estimation/entities/estimationSolution';
export function NewSolutionInputs() {
    const { inputValues, handleInputValueChange, isSubmitting } = useNewSolution();
    return (_jsx(Stack, { width: '100%', children: _jsx(Grid, { spacing: 3, direction: 'row', container: true, justifyContent: 'space-between', children: inputValues === null || inputValues === void 0 ? void 0 : inputValues.map((paramItem, index) => (_jsx(Grid, { item: true, xs: 5, children: _jsx(InputField, { paramItem: paramItem, handleChange: value => {
                        handleInputValueChange(index, paramItem.name, value);
                    }, isSubmitting: isSubmitting }) }, paramItem.name))) }) }));
}
