import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from '@mui/material';
import { FormContainer } from '@shared-app/ui';
import { useFilterOptions } from '../../../../../Estimation/entities/estimationSolution/lib/hooks/useFilterOptions';
import { useTranslation } from 'react-i18next';
import { Header } from './Header';
import { ProjectAccordian } from './ProjectAccordian';
import { AdditionalFilters } from './AdditionalFilters';
import { Footer } from './Footer';
export function FilterDataset() {
    const { category, filterValues, filteredCount, handleFilterChange, handleInputChange, handleOrganizationalChange, handleResetAll, handleResetFilterOption, handleYearChange, organization, resetCounter, years, yearOptions, organizationalOptions, filterOptions } = useFilterOptions();
    const { t } = useTranslation();
    return (_jsxs(FormContainer, { name: t('Filter dataset'), children: [_jsx(Header, { handleOrganizationalChange: handleOrganizationalChange, handleResetAll: handleResetAll, handleYearChange: handleYearChange, organization: organization, organizationalOptions: organizationalOptions, yearOptions: yearOptions, years: years }), _jsx(Stack, { width: '50%', children: _jsx(ProjectAccordian, { category: category, filterOptions: filterOptions, resetCounter: resetCounter }) }), _jsx(AdditionalFilters, { filterOptions: filterOptions, filterValues: filterValues, handleFilterChange: handleFilterChange, handleInputChange: handleInputChange, handleResetFilterOption: handleResetFilterOption }), _jsx(Footer, { filteredCount: filteredCount })] }));
}
