import { Box, styled } from '@mui/material';
export const Container = styled(Box) `
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Logo = styled(Box) `
  svg {
    width: 26rem;
    height: 26rem;
  }
`;
