import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TableCell, TableHead, TableRow } from '@mui/material';
import { BOQEnum } from '@shared-app/lib/types/BOQ';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@shared/ui';
import { InfoOutlined } from '@shared-app/ui/icons';
import styles from './styles.module.scss';
export function BOQTableHeader() {
    const { t } = useTranslation();
    const headCells = [
        { id: BOQEnum.art_no, label: t('Article Number') },
        { id: BOQEnum.art_descript_eng, label: t('Article Description') },
        {
            id: BOQEnum.count,
            label: (_jsxs("div", { className: styles.label, children: [t('Number of Elements'), _jsx(Tooltip, { title: t("Represents the number of individual blocks seen in the solution's drawing, without considering factors. This number cannot be modified by the user."), children: _jsx(InfoOutlined, { className: styles.icon }) })] }))
        },
        {
            id: BOQEnum.units,
            label: (_jsxs("div", { className: styles.label, children: [t('Quantity'), _jsx(Tooltip, { title: t("Counts the factors of each individual element seen in the solution's drawing. This field can be edited by the user"), children: _jsx(InfoOutlined, { className: styles.icon }) })] }))
        },
        { id: BOQEnum.weight, label: t('Unit Weight (kg)') },
        { id: BOQEnum.total_weight, label: t('Total Weight (kg)') }
    ];
    return (_jsx(TableHead, { children: _jsx(TableRow, { sx: { paddingLeft: '16px' }, children: headCells.map((headCell, idx) => {
                return (_jsx(TableCell, { className: styles.tableHeaderCell, children: headCell.label }, headCell.id));
            }) }) }));
}
