import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { InfoOutlined } from '@shared-app/ui/icons';
import palette from '@theme/palette';
import { Text } from '@shared-app/ui';
import { MetaDialog } from '@shared-app/ui';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export function ProjectHeader({ data, handleToggle, isTopOnlyModeChecked }) {
    const [metaModalOpen, setMetaModelOpen] = useState(false);
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsx(Box, { display: 'flex', flexDirection: { sx: 'row' }, justifyContent: 'space-between', alignItems: 'center', height: 80, borderBottom: 1, borderColor: '#E0E0E0', padding: 2, children: _jsxs(Box, { children: [_jsx(Text, { label: t('Project assets'), variant: "h6", sx: { color: palette.primary.text } }), _jsxs(Box, { display: 'flex', alignItems: 'center', justifyContent: 'space-between', children: [_jsx(Text, { label: data.drawing_file_name, variant: "subtitle2", sx: { color: palette.secondary.text } }), _jsx(InfoOutlined, { sx: { color: palette.primary.main, ml: 2 }, onClick: () => setMetaModelOpen(true) })] })] }) }), _jsx(MetaDialog, { data: data, metaOpen: metaModalOpen, setMetaOpen: setMetaModelOpen }), _jsx("div", { className: styles.root, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: isTopOnlyModeChecked, onChange: handleToggle }), label: t('Show only Top View'), classes: {
                        label: styles.label
                    } }) })] }));
}
