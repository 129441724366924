import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from '@entities/auth';
import { LoadingPagePlaceholder } from '@shared/ui';
export function ProtectedLayoutWrapper({ children }) {
    const { user } = useAuth();
    if (!user) {
        return _jsx(LoadingPagePlaceholder, {});
    }
    return children;
}
