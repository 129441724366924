import { createSlice } from '@reduxjs/toolkit';
import { parameters } from '@shared-app/lib/constants';
export const initialState = {
    url: '',
    filterOptions: undefined,
    selectedFilterOptions: undefined,
    filteredDataCount: undefined,
    projects: [],
    breadCrum: [],
    validationBreadcrum: '',
    solutionFilterInitialValue: Object.assign({}, parameters.map(param => ({ [param.name]: '' }))),
    solutionFieldItems: parameters,
    checkboxState: {},
    estimationData: { solutions: [], prediction: undefined, boms: [], plot_url: '' }
};
export const contentSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        setUrl: (state, action) => {
            state.url = action.payload;
        },
        setProjects: (state, action) => {
            state.projects = action.payload;
        },
        setFilterOption: (state, action) => {
            state.filterOptions = action.payload;
        },
        setSelectedFilterOptions: (state, action) => {
            state.selectedFilterOptions = Object.assign(Object.assign({}, state.selectedFilterOptions), action.payload);
        },
        setFilteredDataCount: (state, action) => {
            state.filteredDataCount = action.payload;
        },
        setCheckboxState: (state, action) => {
            state.checkboxState = action.payload;
        },
        setEstimationData: (state, action) => {
            state.estimationData = action.payload;
        },
        setSolutionFieldItems: (state, action) => {
            state.solutionFieldItems = [...action.payload];
        },
        setSolutionFilterInitialValue: (state, action) => {
            state.solutionFilterInitialValue = Object.assign(Object.assign({}, state.solutionFilterInitialValue), action.payload);
        },
        setValidationBreadcrum: (state, action) => {
            state.validationBreadcrum = action.payload;
        },
        setBreadCrum: (state, action) => {
            state.breadCrum = action.payload;
        }
    }
});
export const { setUrl, setFilterOption, setBreadCrum, setValidationBreadcrum, setProjects, setSolutionFieldItems, setEstimationData, setCheckboxState, setFilteredDataCount, setSolutionFilterInitialValue, setSelectedFilterOptions } = contentSlice.actions;
export default contentSlice.reducer;
