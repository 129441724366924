import Http from './http';
export const getDataset = (payload, isImperial = false) => {
    return Http.post(`/estimator/dataset?imperial=${isImperial}`, payload);
};
export const getEstimator = (payload, isImperial = false) => {
    return Http.post(`/estimator/estimate?imperial=${isImperial}`, payload);
};
export const getDwgData = (uuid) => {
    return Http.get(`/projects/${uuid}/files/raw.dwg`, {}, {}, { responseType: 'blob' });
};
export const getImage = (url) => {
    return Http.get(url, {}, {}, { responseType: 'blob' });
};
export const getDFX = (url) => {
    return Http.get(url, {}, {}, { responseType: 'blob' });
};
