import { localStorageKey, metricEnum } from '@shared-app/lib/constants';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
const getInitialState = () => ({
    currentMetricSystem: localStorage.getItem(localStorageKey) || metricEnum.si,
    isImperial: localStorage.getItem(localStorageKey) === metricEnum.imperial
});
export const useMetricSystem = create()(devtools((set, _) => (Object.assign(Object.assign({}, getInitialState()), { reset: () => { }, changeMetricSystem: (system) => {
        localStorage.setItem(localStorageKey, system);
        set({ currentMetricSystem: system, isImperial: system === metricEnum.imperial });
    } }))));
