import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './en/translation.json';
import translationDe from './de/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    debug: process.env.NODE_ENV === 'development',
    resources: {
        en: {
            translation: translationEn
        },
        de: {
            translation: translationDe
        }
    },
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
        escapeValue: false
    }
});
