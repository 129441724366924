import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppDispatch } from '@app/hooks';
import { setSelectedFilterOptions } from '@app/slice/contentSlice';
import { ExpandMore } from '@shared-app/ui/icons';
import React, { useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Checkbox, Chip, Stack } from '@mui/material';
import { materialCheckboxStyle } from '../MultiSelect';
import palette from '@theme/palette';
const transform = (data, parent) => {
    return data.map(item => {
        const node = {
            name: item.name,
            checked: true,
            indeterminate: false,
            childrenNodes: [],
            parent: parent
        };
        if (item.children.length > 0) {
            node.childrenNodes = transform(item.children, node);
        }
        return node;
    });
};
const updateAncestors = (node) => {
    if (!node.parent) {
        return;
    }
    const parent = node.parent;
    const allChecked = parent.childrenNodes.every(child => child.checked);
    const noneChecked = parent.childrenNodes.every(child => !child.checked && !child.indeterminate);
    const someChecked = parent.childrenNodes.some(child => child.checked || child.indeterminate);
    if (allChecked) {
        parent.checked = true;
        parent.indeterminate = false;
    }
    else if (noneChecked) {
        parent.checked = false;
        parent.indeterminate = false;
    }
    else if (someChecked) {
        parent.checked = false;
        parent.indeterminate = true;
    }
    updateAncestors(parent);
};
const toggleDescendants = (node, checked) => {
    node.checked = checked;
    node.indeterminate = false;
    node.childrenNodes.forEach(child => toggleDescendants(child, checked));
};
const findNode = (nodes, name) => {
    for (const node of nodes) {
        if (node.name === name) {
            return node;
        }
        const found = findNode(node.childrenNodes, name);
        if (found) {
            return found;
        }
    }
    return undefined;
};
export const NestedMultiSelectAccordion = ({ data, resetCounter }) => {
    const initialNodes = transform(data, null);
    const [nodes, setNodes] = useState(initialNodes);
    const dispatch = useAppDispatch();
    useEffect(() => {
        setNodes(transform(data, null));
    }, [data, resetCounter]);
    const handleBoxChecked = (e) => {
        e.stopPropagation();
        const checked = e.currentTarget.checked;
        const label = e.currentTarget.value;
        const node = findNode(nodes, label);
        const filterSelectedItems = (data) => {
            return data
                .map(node => {
                if (node.childrenNodes.length > 0) {
                    node.childrenNodes = filterSelectedItems(node.childrenNodes);
                }
                return node.checked || node.indeterminate || node.childrenNodes.length > 0 ? node : null;
            })
                .filter(node => node !== null);
        };
        const removeIsExtraField = (data) => {
            return data.map(node => {
                const newNode = {
                    name: node.name,
                    children: node.childrenNodes.length > 0 ? removeIsExtraField(node.childrenNodes) : []
                };
                return newNode;
            });
        };
        if (node) {
            node.checked = checked;
            node.indeterminate = false;
            toggleDescendants(node, checked);
            updateAncestors(node);
            setNodes(cloneDeep(nodes));
            const filteredData = filterSelectedItems(cloneDeep(nodes));
            const cleanedData = removeIsExtraField(filteredData);
            dispatch(setSelectedFilterOptions({ project_type_filters: cleanedData }));
        }
    };
    return _jsx(NestedCheckboxHelper, { nodes: nodes, onBoxChecked: handleBoxChecked });
};
const NestedCheckboxHelper = ({ nodes, onBoxChecked }) => {
    const getTotalCountOfDeepestChildren = (item) => {
        if (!item.childrenNodes || item.childrenNodes.length === 0) {
            return 1;
        }
        let totalCount = 0;
        item.childrenNodes.forEach(child => {
            totalCount += getTotalCountOfDeepestChildren(child);
        });
        return totalCount;
    };
    const getSelectedItemCount = (item) => {
        if (!item.childrenNodes || item.childrenNodes.length === 0) {
            return item.checked ? 1 : 0;
        }
        let selectedCount = 0;
        item.childrenNodes.forEach(child => {
            selectedCount += getSelectedItemCount(child);
        });
        return item.checked ? selectedCount : selectedCount;
    };
    return (_jsx(_Fragment, { children: nodes.map(node => {
            const totalItems = getTotalCountOfDeepestChildren(node);
            const selectedItems = getSelectedItemCount(node);
            return (_jsxs(Accordion, { style: { border: 'none', boxShadow: 'none' }, children: [_jsx(AccordionSummary, { expandIcon: _jsxs(Stack, { flexDirection: 'row', alignItems: 'center', children: [_jsx(Stack, { flexDirection: 'row', alignItems: 'center', onClick: e => e.stopPropagation(), children: node.parent !== null && (_jsx(Checkbox, { sx: materialCheckboxStyle, name: node.name, value: node.name, checked: node.checked, indeterminate: node.indeterminate, onChange: onBoxChecked })) }), node.childrenNodes && node.childrenNodes.length > 0 && _jsx(ExpandMore, {})] }), "aria-controls": "panel1a-content", id: "panel1a-header", sx: {
                            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                                transform: 'rotate(0deg)'
                            },
                            '&.MuiAccordionSummary-root.Mui-expanded': {
                                background: palette.neutral[98]
                            }
                        }, children: _jsxs(Stack, { flexDirection: 'row', alignItems: 'center', children: [node.name, node.parent === null &&
                                    selectedItems === totalItems &&
                                    totalItems > 0 &&
                                    node.childrenNodes &&
                                    node.childrenNodes.length > 0 && (_jsx(Chip, { sx: { marginLeft: 5 }, label: 'All', variant: "outlined" })), node.parent === null &&
                                    selectedItems !== totalItems &&
                                    node.childrenNodes &&
                                    node.childrenNodes.length > 0 && (_jsx(Chip, { sx: { marginLeft: 5 }, label: `${selectedItems} from ${totalItems}`, variant: "outlined" }))] }) }), _jsx(AccordionDetails, { children: node.childrenNodes.length > 0 && (_jsx(NestedCheckboxHelper, { nodes: node.childrenNodes, onBoxChecked: onBoxChecked })) })] }, node.name));
        }) }));
};
