import styled from "styled-components";
export const Container = styled.div `
height: calc((100vh - 80px));
  display: flex;
  flex-direction: column;
`;
export const FlexRow = styled.div `
display:flex;
flex-direction: row`;
export const ContentContainer = styled.div `
display:flex;
height: calc((100vh - 160px));
flex-direction: row`;
export const ToggleContainer = styled.div `
border: 1px solid #E0E0E0;
display:flex;
height: 98px;
flex-direction: row;
padding: 22px;
justify-content: space-between`;
export const Controls = styled.div `
position: absolute;
display: flex;
flex-direction: column;
top:40%;
left:93%;
z-index:1
`;
export const Content = styled.div `
  flex: 1;
  height: calc((100vh - 160px));
`;
export const RightContent = styled.div `
  flex: 1;
  height: calc((100vh - 258px));
  display:flex;
`;
export const DXFContent = styled.div `
  flex: 1;
  background: black;
  height: 100%;
  // height: calc((100% - 80px));
  align-items: center;
  display: flex;
  justify-content: center
`;
export const HeaderContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100;
  height: 80px;
  padding:16px;
  border: 1px solid #E0E0E0;
`;
export const ResultImage = styled.div `
  height: calc((100vh - 500px));
  max-height: calc((100vh - 502px));
  margin:40px;
  width: 95%;
  position:relative;
  // max-width: 100%;
`;
