import styled from 'styled-components';
export const Controls = styled.div `
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50;
  left: 95%;
  // right:0;
  z-index: 1;
`;
