var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Popover } from 'antd';
import * as S from '../styles';
import Logo from '@shared-app/assets/icons/logo.svg';
import LogoutIcon from '@shared-app/assets/icons/logout.svg';
import { useAuth } from '@entities/auth';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@shared-app/lib/constants';
import ArrowDownIcon from '@shared-app/assets/icons/arrow-down.svg';
import { useTranslation } from 'react-i18next';
import { LanguageSwitcher } from './LanguageSwitcher';
import { MetricSwitcher } from '@estimation/features/MetricSwitcher';
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type
    };
}
export function Header({ baseUrl, title, breadCrumProject, breadCrumSolution, onBreadClick }) {
    const { logout, user } = useAuth();
    const navigate = useNavigate();
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { t } = useTranslation();
    const items = [getItem(t('Logout'), 'logout', _jsx(LogoutIcon, {}))];
    const handleProceed = () => {
        navigate(ROUTES.VALIDATION.INDEX);
    };
    const handleLogout = () => __awaiter(this, void 0, void 0, function* () {
        setIsLoggingOut(true);
        yield logout();
        setIsLoggingOut(false);
    });
    const handleMenuItemClick = (info) => __awaiter(this, void 0, void 0, function* () {
        switch (info.key) {
            case 'logout': {
                yield handleLogout();
                break;
            }
        }
    });
    return (_jsxs(S.Header, { children: [_jsxs(S.LeftContent, { children: [_jsxs(S.Brand, { to: baseUrl, children: [_jsx(Logo, {}), " Take Off ", _jsx(S.Title, { children: title })] }), window.location.pathname.startsWith('/validation/projects/') && (_jsxs(S.BreadCrum, { children: [_jsx(S.BreadCrumParagraph, { onClick: handleProceed, children: t('Projects') }), _jsx(S.BreadCrumDivider, { children: "\u00A0 / \u00A0" }), _jsx(S.BreadCrumParagraph, { onClick: onBreadClick, children: breadCrumProject }), breadCrumSolution && _jsx(S.BreadCrumDivider, { children: "\u00A0 / \u00A0" }), _jsx(S.BreadCrumParagraph, { children: breadCrumSolution })] }))] }), _jsxs(S.RightContent, { children: [title === 'Estimation' && _jsx(MetricSwitcher, {}), _jsx(LanguageSwitcher, {}), _jsx(Popover, { arrow: false, trigger: ['click'], placement: "bottomRight", content: _jsx(S.Profile, { children: _jsx(S.ProfileMenu, { onClick: handleMenuItemClick, items: items, disabled: isLoggingOut }) }), children: _jsxs(S.Trigger, { type: "text", children: [_jsxs(S.Avatar, { children: [" ", user && user.email && user.email[0].toUpperCase()] }), _jsx(ArrowDownIcon, {})] }) })] })] }));
}
