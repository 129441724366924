import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from '../../../styles';
import { useSolutionStore } from '@entities/solution';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import apis from '@shared-app/lib/apis';
import { AlertDialog, ImagePreview } from '@shared-app/ui';
import { Box } from '@mui/material';
import { DownLoadFooter } from './DownloadFooter';
import { Parameters } from './Parameters';
import { BOQTab } from './BOQTab/BOQTab';
import { VisibleItem } from '../../../lib/types/VisibleItem';
import palette from '@theme/palette';
export function InfoPanel({ visibleItem }) {
    const { projectSolution, handleParameterReset, parameters } = useSolutionStore();
    const [solutionImage, setSolutionImage] = useState('');
    const [resetOpen, setResetOpen] = useState(false);
    const isFieldDisabled = false;
    const { t } = useTranslation();
    useEffect(() => {
        if (projectSolution) {
            getImage(projectSolution.solution.image);
        }
    }, [projectSolution]);
    //Add Soluton Image
    const getImage = (url) => {
        apis.dataset
            .getImage(url)
            .then(res => {
            const imageURL = URL.createObjectURL(res);
            setSolutionImage(imageURL);
        })
            .catch(e => console.log('ee', e));
    };
    const handleParameterResetAll = () => {
        handleParameterReset();
        setResetOpen(false);
    };
    const openResetModal = () => {
        setResetOpen(true);
    };
    return (_jsxs(S.RightContent, { children: [_jsx(AlertDialog, { open: resetOpen, handleClose: () => setResetOpen(false), setOpen: setResetOpen, title: t('Reset'), details: t('Are you sure you want to continue? Both the parameters and the Bills Of Quantities will be reset together.'), buttonText: t('Reset'), onClick: handleParameterResetAll }), visibleItem === VisibleItem.BOM ? (_jsx(_Fragment, { children: projectSolution && (_jsx(BOQTab, { handleResetAll: openResetModal, isDisabled: isFieldDisabled })) })) : (_jsxs(Box, { display: 'flex', width: '100%', flexDirection: 'column', bgcolor: palette.grey[100], justifyContent: 'space-between', children: [visibleItem === VisibleItem.Image && solutionImage && (_jsx(S.ResultImage, { children: _jsx(ImagePreview, { image: solutionImage }) })), visibleItem === VisibleItem.Parameter && parameters.length > 0 && (_jsx(Parameters, { handleResetAll: openResetModal, isFieldDisabled: isFieldDisabled })), _jsx(DownLoadFooter, { solutionImage: solutionImage, visibleItem: visibleItem })] }))] }));
}
