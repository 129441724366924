var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Modal, TextField, IconButton } from '@mui/material';
import palette from '@theme/palette';
import { Button, Text } from '@shared-app/ui';
import { AddCircleOutlined } from '@shared-app/ui/icons';
import { ArticleDataSelection } from './AsyncSelect';
import { useState } from 'react';
import { BOQEnum } from '@shared-app/lib/types/BOQ';
import { ToastService } from '@shared/lib/services/toast.service';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ParametersEnum, useSolutionStore } from '@entities/solution';
import { SolutionView } from '@shared-app/lib/types';
import { Alert } from '@shared-app/ui/Alert/Alert';
import { QuantityFormulaDesc } from '@pages/Validation/Validate/lib/constants';
import styles from '../styles.module.scss';
export function AddRow({ isDisabled }) {
    const [showAddNew, setShowAddNew] = useState(false);
    const [newArticle, setNewArticle] = useState({
        Quantity: undefined,
        QuantityFormula: undefined
    });
    const { bom, updateBom, parameters, projectSolution } = useSolutionStore();
    const getSearchParameters = () => {
        var _a, _b;
        if (projectSolution.solution.view === SolutionView.top) {
            const wallLength = ((_a = parameters.find(item => item.name === ParametersEnum.length)) === null || _a === void 0 ? void 0 : _a.value) || 0;
            const wallHeight = ((_b = parameters.find(item => item.name === ParametersEnum.height)) === null || _b === void 0 ? void 0 : _b.value) || 0;
            return [Boolean(wallHeight) && Boolean(wallLength), wallHeight, wallLength];
        }
        return [true, undefined, undefined];
    };
    const [isSearchEnabled, wallHeight, wallLength] = getSearchParameters();
    const { t } = useTranslation();
    useEffect(() => {
        if (!showAddNew) {
            setNewArticle({ Quantity: undefined, QuantityFormula: undefined });
        }
    }, [showAddNew]);
    const handleAddNewRow = () => __awaiter(this, void 0, void 0, function* () {
        try {
            const newRow = {
                [BOQEnum.art_descript]: newArticle.DescriptionGer,
                [BOQEnum.art_no]: newArticle.ArticleNumber,
                [BOQEnum.art_descript_eng]: newArticle.DescriptionEng,
                [BOQEnum.units]: parseInt(newArticle.Quantity),
                [BOQEnum.weight]: newArticle.Weight,
                [BOQEnum.total_weight]: newArticle.Weight * newArticle.Quantity,
                [BOQEnum.count]: parseInt(newArticle.Quantity)
            };
            updateBom([...bom, newRow]);
            setShowAddNew(false);
            ToastService.success(t('Successfully Added Row'));
        }
        catch (e) {
            console.log('error', e);
        }
    });
    const addNewRow = () => {
        setShowAddNew(true);
    };
    const checkIfExistingArticle = () => {
        if (!newArticle) {
            return false;
        }
        return (bom.findIndex(tableItem => tableItem[BOQEnum.art_no] === newArticle.ArticleNumber) !== -1);
    };
    const isExistingArticle = checkIfExistingArticle();
    const isAddEnabled = Boolean(newArticle === null || newArticle === void 0 ? void 0 : newArticle.ArticleNumber) &&
        Number(newArticle.Quantity || 0) > 0 &&
        !isExistingArticle;
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: () => (isDisabled ? null : addNewRow()), className: styles.plusButton, children: _jsx(AddCircleOutlined, {}) }), _jsx(Modal, { onClose: () => setShowAddNew(false), open: showAddNew, sx: { maxWidth: '50%', width: '50%', top: '45%', left: '30%' }, children: _jsxs(Box, { bgcolor: palette.common.white, p: 2, children: [_jsx(Text, { label: t('Please fill these fields in order to add a row: '), variant: "h6" }), _jsxs(Box, { display: 'flex', width: '100%', gap: 4, my: 6, alignItems: 'flex-start', style: { position: 'relative' }, children: [_jsx(Box, { flex: 2, style: { position: 'relative' }, children: _jsx(ArticleDataSelection, { onSelectionChange: (e) => {
                                            setNewArticle(Object.assign(Object.assign({}, e), { Quantity: (e === null || e === void 0 ? void 0 : e.Quantity) > 0 ? e === null || e === void 0 ? void 0 : e.Quantity : undefined }));
                                        }, isError: isExistingArticle, length: wallLength, height: wallHeight }) }), _jsx(Box, { flex: 1, children: _jsx(TextField, { variant: "standard", fullWidth: true, type: "number", onKeyPress: event => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }, placeholder: t('Quantity'), value: newArticle.Quantity, onChange: e => setNewArticle(prev => (Object.assign(Object.assign({}, prev), { Quantity: e.target.value }))), sx: {
                                            '& .MuiInput-input': {
                                                padding: '5px 0',
                                                marginTop: '15px'
                                            }
                                        } }) })] }), (newArticle === null || newArticle === void 0 ? void 0 : newArticle.QuantityFormula) && (_jsx(Alert, { severity: "info", sx: { marginBottom: 2 }, children: t(QuantityFormulaDesc[newArticle === null || newArticle === void 0 ? void 0 : newArticle.QuantityFormula]) })), !isSearchEnabled && (_jsx(Alert, { severity: "warning", sx: { marginBottom: 2 }, children: t('One of Wall Length or Average Height is missing.') })), _jsxs(Box, { display: 'flex', width: '100%', justifyContent: 'flex-end', children: [_jsx(Button, { sx: { mr: 3 }, onClick: () => setShowAddNew(false), variant: "outlined", children: t('Cancel') }), _jsx(Button, { onClick: () => handleAddNewRow(), variant: "contained", autoFocus: true, disabled: !isAddEnabled, children: t('Add') })] })] }) })] }));
}
