import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography, Box, Button, Select, MenuItem, CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { transformBillData, getCSV } from '@shared-app/utils/';
import { saveAs } from 'file-saver';
import { MoveToInboxOutlined } from '@shared-app/ui/icons';
import { useMetricSystem } from '@shared-app/hooks';
export function BOQTableHeader({ billData, estimationData, handleSelectSolution, selectedSolution }) {
    const { t } = useTranslation();
    const [isCVSDownloading, setIsCVSDownloading] = useState(false);
    const { isImperial } = useMetricSystem();
    const handleDownloadCSV = () => {
        var _a;
        setIsCVSDownloading(true);
        const transformedBillData = billData.map(item => transformBillData(item, isImperial));
        const csvContent = getCSV(transformedBillData);
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        let fileName = (_a = estimationData === null || estimationData === void 0 ? void 0 : estimationData.solutions[selectedSolution]) === null || _a === void 0 ? void 0 : _a.drawing_file_name.replace('.dwg', '.csv');
        saveAs(blob, fileName);
        setIsCVSDownloading(false);
    };
    const handleChange = (event) => {
        handleSelectSolution(event.target.value);
    };
    return (_jsxs(Box, { my: 5, alignItems: 'center', display: 'flex', flexDirection: 'row', width: '70%', justifyContent: 'space-between', children: [_jsx(Typography, { fontSize: 28, fontWeight: 600, children: t('Bill of Quantities (BoQ)') }), _jsxs(Box, { display: 'flex', width: '50%', justifyContent: 'flex-end', flexDirection: 'row', children: [_jsxs(Button, { startIcon: !isCVSDownloading ? (_jsx(MoveToInboxOutlined, {})) : (_jsx(CircularProgress, { color: "inherit", size: 20 })), disabled: isCVSDownloading, variant: "outlined", onClick: handleDownloadCSV, sx: {
                            marginRight: 5,
                            width: '33%',
                            border: 2,
                            borderColor: '#808080',
                            textTransform: 'capitalize'
                        }, children: [' ', t('Download BoQ CSV')] }), _jsx(Select, { style: { width: '33%' }, labelId: "demo-simple-select-label", id: "demo-simple-select", value: selectedSolution, onChange: handleChange, children: estimationData.solutions.map((_item, index) => {
                            return (_jsxs(MenuItem, { value: index, children: [t('Solution'), " ", index + 1] }));
                        }) })] })] }));
}
